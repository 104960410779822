import React from 'react';
import { PATH } from '@constants/path';
import { GoodsItemIF } from 'interface/goodsInterface';
import { Link, useNavigate } from 'react-router-dom';
import { calculatePercentage, getConnectStatus, getDateFormat, regPrice, typeByText } from '@utils/util';
import PLImage from '@components/common/PLImage';

interface ListingProductProps {
  data: GoodsItemIF;
  path: string;
}

const ListingProduct = ({ data, path }: ListingProductProps) => {
  const { routePath, status } = getConnectStatus(path);
  const navigate = useNavigate();

  function getTknPercent() {
    if (data.tknCurprcseValue === 0) {
      return <em className="d">{data.tknCurprcseValue}%</em>;
    } else if (data.tknCurprcse === 'up') {
      return <em className="upIndi">{data.tknCurprcseValue}%</em>;
    } else {
      return <em className="downIndi">{data.tknCurprcseValue}%</em>;
    }
  }

  return (
    <>
      <div
        className="product_investment_list listed_product"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`${routePath.goods_detail}/${data.goodsSn}`, { state: { appGoodsListType: 'S' } })}
      >
        {/* <div className="slideBox box01"><span>모집예정</span></div>  */}

        <div className="product_thumb swiper-container" id="productGallery01">
          <div className="swiper-wrapper">
            <div className="product_thumb_list swiper-slide">
              <PLImage imgSrc={status === 'web' ? data.webThumb : data.appThumb} alt="" />
            </div>
          </div>
        </div>
        <div className="product_detail">
          {/* <Link to={`${routePath.goods_detail}/${data.goodsSn}`}> */}
          <div className="product_detail_info01">
            <div className="product_info01">
              <p className="product_category">상장</p>
              <h4 className="product_title">{data.goodsNm}</h4>
              <p className="product_address">{data.goodsAdres}</p>
            </div>
            <div className="product_info02">
              <p>
                <span>{regPrice(data.tknPc)}원</span>
                {getTknPercent()}
              </p>
            </div>
          </div>

          <div className="product_detail_info02">
            <div className="product_info03">
              <ul>
                <li>
                  <dl>
                    <dt>상장일</dt>
                    <dd>{typeByText('Date', data.lston)}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>감정가</dt>
                    <dd>
                      <span className="appraisedTxt">{regPrice(data.invtinfoApprslamount)}</span>
                      <span className="unit_product">원</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>공모가</dt>
                    <dd>
                      {regPrice(data.pssrpc)}
                      <span className="unit_product">원</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>총 TO 수량</dt>
                    <dd>
                      {regPrice(data.pssrpqy)}
                      <span className="unit_product">TO</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>공모가/감정가</dt>
                    <dd>
                      {calculatePercentage(Number(data.pssrpc), Number(data.invtinfoApprslamount))}
                      <span className="unit_product">%</span>
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default ListingProduct;
