import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PATH, WEB_PATH } from '@constants/path';

import AppMain from '@pages/AppMain';
import WebMain from '@pages/WebMain';

import AppLayout from '@components/layout/app/AppLayout';
import WebLayout from '@components/layout/web/WebLayout';
import WebFullAreaLayout from '@components/layout/web/WebFullAreaLayout';

import EventList from '@pages/event/EventList';
import EventDetail from '@pages/event/EventDetail';
import NoticeList from '@pages/notice/NoticeList';
import NoticeDetail from '@pages/notice/NoticeDetail';
import StateDetail from '@pages/state/StateDetail';
import StateList from '@pages/state/StateList';
import Story from '@pages/story/Story';
import GoodsList from '@pages/goods/GoodsList';

import Query from '@pages/user/Query';
import UserInfo from '@pages/user/UserInfo';
import NewsList from '@pages/news/NewsList';
import AnnoDetail from '@pages/anno/AnnoDetail';
import AppGoodsDetail from '@pages/goods/app/AppGoodsDetail';

import AppSplash from '@pages/app_static/Splash';
import AppInvestGuide from '@pages/app_static/InvestGuide';
import AppServiceInfo from '@pages/app_static/ServiceInfo';
import AppNplGuide from '@pages/app_static/NplGuide';
import AppGuideModal from '@pages/app_static/GuideModal';
import AppMyInvest from '@pages/app_static/MyInvest';
import AppFaq from '@pages/app_static/Faq';
import AppStoGuide from '@pages/app_static/StoGuide';
import AppMainSplash from '@pages/app_splash/SplashMain';

import PrepareService from '@components/common/PrepareService';
import GoodsDetail from '@pages/goods/GoodsDetail';
import AnnoList from '@pages/anno/AnnoList';
import CorporateInvestment from '@pages/web_static/CorporateInvestment';
import PlutosIntroduction from '@pages/web_static/PlutosIntroduction';
import StoGuide from '@pages/web_static/StoGuide';
import Faq from '@pages/web_static/Faq';
import NplGuide from '@pages/web_static/NplGuide';
import { Helmet } from 'react-helmet';
import '@assets/css/web/content.css';
import NotFound from '@pages/error/NotFound';

import Terms from '@pages/user/Terms';
import AppProvider from '@contexts/AppProvider';
import AppSplashLayout from '@components/layout/app/AppSplashLayout';
import AppPlutosIntroduction from '@pages/app_static/PlutosIntroduction';

// add
import { ENV } from '@constants/envPath';
import MokStdRequest from './MokReactIndex';
import MokStdRedirect from './MokReactRedirect';
import { useUserProfile } from '@contexts/GlobalProvider';
import { decodeJwtToUserProfileObj } from '@utils/util';

function App() {
  const path = useLocation();
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  const [userProfile, setUserProfile] = useUserProfile();

  useEffect(() => {
    const jwt = localStorage.getItem('token');
    if (jwt) setUserProfile(decodeJwtToUserProfileObj(jwt));
  }, []);

  return (
    <>
      {/* <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href={
            getConnectStatus(path.pathname).status === 'web'
              ? '/assets/css/web/content.css'
              : '/assets/css/mobile/content.css'
          }
        />
      </Helmet> */}
      {/* <Suspense fallback={<Loader />}> */}
      {/* {isCssLoaded ? ( */}
      <Helmet>
        {ENV.PROFILE === 'prod' ? (
          <script src="https://cert.mobile-ok.com/resources/js/index.js"></script>
        ) : (
          <script src="https://scert.mobile-ok.com/resources/js/index.js"></script>
        )}
      </Helmet>
      <Routes>
        <Route path={PATH.splash} element={<AppSplash />}></Route>
        <Route path={PATH.guide} element={<AppGuideModal />}></Route>
        {/* <Route element={<WebFullLayoutComponent />}> */}
        <Route element={<WebFullAreaLayout />}>
          <Route path={WEB_PATH.main} element={<WebMain />}></Route>
          <Route path={`${WEB_PATH.goods_detail}/:id`} element={<GoodsDetail />}></Route>
        </Route>

        {/* <Route element={<WebLayoutComponent />}> */}
        <Route element={<AppSplashLayout />}>
          <Route path={PATH.app_main_splash} element={<AppMainSplash type="" />}></Route>
        </Route>
        <Route element={<WebLayout />} errorElement={<NotFound />}>
          <Route path={WEB_PATH.anno} element={<AnnoList />}></Route>
          <Route path={`${WEB_PATH.anno_detail}/:id`} element={<AnnoDetail />}></Route>
          <Route path={WEB_PATH.notice} element={<NoticeList />}></Route>
          <Route path={`${WEB_PATH.notice_detail}/:id`} element={<NoticeDetail />}></Route>
          <Route path={WEB_PATH.news} element={<NewsList />}></Route>
          <Route path={WEB_PATH.event} element={<EventList />}></Route>
          <Route path={`${WEB_PATH.event_detail}/:id`} element={<EventDetail />}></Route>
          <Route path={WEB_PATH.goods} element={<GoodsList />}></Route>
          <Route path={WEB_PATH.corporation_investment} element={<CorporateInvestment />}></Route>
          <Route path={WEB_PATH.sto_guide} element={<StoGuide />}></Route>
          <Route path={WEB_PATH.invest_guide} element={<PrepareService />}></Route>
          <Route path={WEB_PATH.faq} element={<Faq />}></Route>
          <Route path={WEB_PATH.npl_guide} element={<NplGuide />}></Route>
          <Route
            path={WEB_PATH.npl_investment}
            element={
              <div className="sectionCon">
                <PrepareService />
              </div>
            }
          ></Route>
          <Route path={WEB_PATH.plutos_introduction} element={<PlutosIntroduction />}></Route>
          <Route path={WEB_PATH.privacy} element={<Terms name="privacy" />} />
          <Route path={WEB_PATH.clause} element={<Terms name="service" />} />
          {/* <Route path="/*" element={<WebRoutes />} /> */}
        </Route>

        {/* <Route element={<AppLayoutComponent />}> */}
        <Route
          element={
            <AppProvider>
              <AppLayout />
            </AppProvider>
          }
        >
          <Route path={PATH.main} element={<AppMain />}></Route>
          <Route path={PATH.event} element={<EventList />}></Route>
          <Route path={`${PATH.event_detail}/:id`} element={<EventDetail />}></Route>
          <Route path={PATH.notice} element={<NoticeList />}></Route>
          <Route path={`${PATH.notice_detail}/:id`} element={<NoticeDetail />}></Route>
          <Route path={PATH.state} element={<StateList />}></Route>
          <Route path={`${PATH.state_detail}/:id`} element={<StateDetail />}></Route>
          <Route path={PATH.goods} element={<GoodsList />}></Route>
          <Route path={`${PATH.goods_detail}/:id`} element={<AppGoodsDetail />}></Route>
          <Route path={`${PATH.anno_detail}/:id`} element={<AnnoDetail />}></Route>
          <Route path={PATH.news} element={<NewsList />}></Route>
          <Route path={PATH.story} element={<Story />}></Route>
          <Route path={PATH.query} element={<Query />}></Route>
          <Route path={`${PATH.terms}/:name`} element={<Terms />}></Route>
          <Route path={PATH.user_info} element={<UserInfo />}></Route>

          <Route path={PATH.invest_guide} element={<AppInvestGuide />}></Route>
          <Route path={PATH.faq} element={<AppFaq />}></Route>
          <Route path={PATH.my_invest} element={<AppMyInvest />}></Route>
          <Route path={PATH.npl_guide} element={<AppNplGuide />}></Route>
          <Route path={PATH.service_info} element={<AppServiceInfo />}></Route>
          <Route path={PATH.sto_guide} element={<AppStoGuide />}></Route>
          <Route path={PATH.plutos_introduction} element={<AppPlutosIntroduction />} />
          {/* <Route path="/app/*" element={<AppRoutes />} /> */}
        </Route>
        <Route path={'/*'} element={<NotFound />}></Route>

        {/* PASS Authentication */}
        <Route path="/mok/request" element={<MokStdRequest />} />
        <Route path="/mok/redirect" element={<MokStdRedirect type="" />} />
      </Routes>
      {/* ) : null} */}
      {/* </Suspense> */}
    </>
  );
}

export default App;
