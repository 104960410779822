import React, { useEffect, useRef, useState } from 'react';
import { SwiperOptions } from 'swiper';
import { Pagination, Autoplay, Navigation, Scrollbar } from 'swiper';
import { Swiper } from 'swiper/react';
import TweenMax, { Linear, Power4 } from 'gsap';
import $ from 'jquery';
import { mainContent } from '@utils/jqueryUtil';

interface MainGoodsSwiperProps {
  // data: Array<any>;
  // id: string;
  swiperClassName: string;
  children: React.ReactNode;
}

const MainGoodsSwiper = ({ swiperClassName, children }: MainGoodsSwiperProps) => {
  // const swiperProps: SwiperOptions = {
  //   slidesPerView: 4,
  //   spaceBetween: 0,
  //   navigation: {
  //     nextEl: '.sec_two .swiper-button-next',
  //     prevEl: '.sec_two .swiper-button-prev',
  //   },
  //   pagination: {
  //     el: '.sec_two .swiper-pagination',
  //     type: 'fraction',
  //     // clickable: true,
  //   },
  //   breakpoints: {
  //     500: {
  //       speed: 450,
  //       slidesPerView: 1,
  //       spaceBetween: 0,
  //     },
  //     768: {
  //       speed: 450,
  //       slidesPerView: 2,
  //       spaceBetween: 0,
  //     },
  //     1024: {
  //       speed: 450,
  //       slidesPerView: 3,
  //       spaceBetween: 0,
  //     },
  //     1800: {
  //       speed: 900,
  //       slidesPerView: 4,
  //       spaceBetween: 0,
  //     },
  //   },
  // };

  // const [width, setWidth] = useState(window.innerWidth);

  // const handleResize = () => {
  //   setWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   // mainContent();
  //   if (myElementRef.current) {
  //     // setTimeout(() => {
  //     mainContent();
  //     // }, 1000);
  //   }
  // }, []);

  return (
    <Swiper
      id={swiperClassName}
      // slidesPerView={4}
      // spaceBetween={0}
      navigation={{
        nextEl: '.sec_two .swiper-button-next',
        prevEl: '.sec_two .swiper-button-prev',
      }}
      pagination={{
        el: '.sec_two .swiper-pagination',
        type: 'fraction',
        // clickable: true,
      }}
      breakpoints={{
        500: {
          speed: 450,
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          speed: 450,
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          speed: 450,
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1800: {
          speed: 900,
          slidesPerView: 4,
          spaceBetween: 0,
        },
      }}
      modules={[Navigation, Pagination, Scrollbar, Autoplay]}
      className={`${swiperClassName} swiper-container swiper-container-horizontal`}
      onInit={function () {
        if (window.innerWidth <= 1024) {
          $('.sec_two .sales_bg .bg').each(function (q) {
            $('.sec_two .sales_bg .bg').eq(0).css('opacity', '1');
          });
        }
      }}
      onSlideChangeTransitionStart={function (swiper) {
        $('.sec_two .sales_bg .bg').each(function () {
          TweenMax.to($('.sec_two .sales_bg .bg'), 0.5, { opacity: 0 });
          TweenMax.to($('.sec_two .sales_bg .bg').eq(swiper.realIndex), 0.5, { opacity: 1 });
        });
      }}
    >
      {children}
    </Swiper>
  );
};

export default MainGoodsSwiper;
