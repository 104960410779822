import { WEB_PATH } from '@constants/path';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 * Footer 회사정보
 * @returns
 */
interface WebFooterProps {
  isMain?: boolean;
}
const WebFooter = ({ isMain }: WebFooterProps) => {
  const path = useLocation().pathname;
  return (
    <div id={isMain ? 'mainFooter' : ''} className="mainFooter section">
      <div className="WrapFooterInfo">
        <div className="copyrightArea">
          <div className="copyright01">
            <p>(주)플루토스파트너스</p>
          </div>
          <div className="copyright02">
            <p>대표 정상기 &nbsp;&nbsp;&nbsp;&nbsp;개인정보관리책임자 임지훈</p>
            <p>사업자등록번호: 361-86-03018 TEL: 02-555-1873 FAX: 02-555-1874</p>
            <p>서울시 강남구 봉은사로26길12, 3층</p>
            <p>© 2023 PLUTOS. All rights reserved.</p>
          </div>
          <div>
            <div className="copyright02">
              {path === WEB_PATH.main ? (
                <>
                  <a href={WEB_PATH.privacy} style={{ marginRight: '15px' }}>
                    개인정보처리방침
                  </a>
                  <a href={WEB_PATH.clause}>서비스이용약관</a>
                </>
              ) : (
                <>
                  <Link to={WEB_PATH.privacy} style={{ marginRight: '15px' }}>
                    개인정보처리방침
                  </Link>
                  <Link to={WEB_PATH.clause}>서비스이용약관</Link>
                </>
              )}
            </div>
          </div>
          <div className="copyright03">
            <p>구매원금과 수익을 보장하지 않으며, 손실에 대한 책임은 모두 구매자에게 있습니다.</p>
            <p>
              조각구매 대상이 되는 상품과 그 상품을 등록하는 개별 판매자에 대한 충분한 검수와 검증에 대한 책임은 당사에
              있습니다.
            </p>
            <p>
              전자상거래 등에서의 소비자보호에 관한 법률(전자상거래법)에 따라 계약을 체결한 소비자는 구매 후 7일 이내
              청약을 철회할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebFooter;
