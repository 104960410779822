export const faqList: Array<{
  title: string;
  value: string;
  faqArr: Array<any>;
}> = [
  {
    title: '플랫폼 관련',
    value: 'platform',
    faqArr: [
      {
        question: '플루토스는 어떤 서비스를 제공하나요?',
        answer: `플루토스는 국내 최초 부동산 NPL 투자 전문 플랫폼으로 “NPL투자의 대중화”를 실현합니다. 그 동안 NPL투자는 장점이 많은데 비하여 높은 진입장벽과 전문성의 요구로 기관 및 전문투자가들의 고유 투자 영역이었습니다. 플루토스는 이러한 비대칭적인 NPL 투자시장을 블록체인 기술을 활용한 토큰증권 발행(STO)으로 혁신하여 안전하면서도 누구나 쉽고 편리하게 투자 참여하도록 서비스합니다. 또한, 축적된 부동산개발 노하우를 바탕으로 Value add 제공하여 자산가치의 적극적인 상승이 실현되도록 합니다. 즉, 플루토스는 NPL투자의 토탈 서비스를 제공합니다.
\n•${' '}누구나: 전문지식이 없어도, 투 자금이 많지 않아도 누구나 NPL 자산에 투자 가능합니다.
\n•${' '}간편하게: 복잡한 절차 없이 쇼핑하듯 원하는 상품에 투자하고, 주투자자의 상품은 전문업체가 운영,관리하여 수익을 창출합니다.
\n•${' '}언제든지: 블록체인 기술을 활용하여 NPL 자산을 토큰증권화 하고, 매입한 토큰증권은 장외시장에서 주식처럼 거래하여 언제든지 현금화 및 시세차익 실현 가능합니다.`,
      },
      {
        question: 'NPL이란 무엇인가요',
        answer: `NPL(Non Performing Loan)이란 금융기관이 빌려준 돈을 회수할 가능성이 없거나 어렵게 된 채권으로, 기능을 하지 못하는 여신을 뜻 합니다. 통상 채권의 상환이 3개월 이상 연체되면 금융기관은 이 채권을 ‘고정＇등급 이하로 분류하고 시장에 매각합니다. 투자자는 NPL을 매입하고 다음의 방법으로 수익 실현합니다.
\n•${' '}NPL자체로써 재매각
\n•${' '}경,공매 절차에 따른 채권배당
\n•${' '}직접 낙찰받아 소유권 확보(유입) 후 정상가 재매각
\n•${' '}유입 후 리노베이션, 임대차 개선 등 value add 재매각`,
      },
      {
        question: 'NPL투자의 장점은 무엇인가요?',
        answer: `부실이라는 부정적인 이미지와는 달리 민,관,투자자 모두가 만족할 수 있는 장점이 많은 투자 상품입니다.
\n•${' '}금융기관은 빠른 부실처리로 자산건전성 확보(당국의 BIS, 부실채권비율 준수)
\n•${' '}부동산 시장 정상화에 기여
\n•${' '}부실 해결 위해 공적자금의 투입보다 시장 친화적인 해결책 제공
\n•${' '}투자자는 상대적으로 안전하면서 높은 수익 실현 가능`,
      },
      {
        question: '기존의 부동산 조각투자 플랫폼과 어떤 차이점이 있나요?',
        answer: `기초자산이 다릅니다. 기존 부동산 조각투자의 경우, 시장에서 정상가격에 거래되고 있는 부동산(물권) 자산을 대상으로 하지만, 플루토스는 NPL(채권)을 기초자산으로 하며 이를 활용하여 경,공매 낙찰받아 최종적으로 부동산 자산을 취득합니다. 즉, 채권과 물권의 하이브리드 투자입니다.
\n•${' '}채권 상태로 배당 청산하거나, 직접 경매 낙찰받아 부동산 재매각으로 청산하는 등 출구 전략이 다양함
\n•${' '}기초자산이 중소 빌딩에 국한되는 것이 아니라 아파트, 공장, 숙박업소 등 자산 유형이 다양함
\n•${' '}경매를 통한 취득으로 조달가격이 저렴함. 결과적으로 수익률의 상승으로 연결됨
\n•${' '}가장 큰 장점으로 부실채권이기에 불황일수록 수익이 좋음`,
      },
      {
        question: 'ST/STO란 무엇인가요?',
        answer: `토큰증권(Security Token)이란 분산원장 기술을 활용해 자본시장법상 증권을 디지털화 한 것으로 부동산, 미술품, 저작권 등 다양한 자산에 대한 소액 투자를 가능하게 하는 기본 개념입니다. STO(Security Token Offering)는 토큰증권을 발행해 자금을 조달하는 것을 말합니다. 주식의 IPO, 가상화폐의 ICO와 상응하는 개념입니다.
\n•${' '}블록체인 및 스마트 계약 기술을 활용하여 탈중앙화, 분산화
\n•${' '}거래내역이 투명하며 실물 증서가 없더라도 신뢰, 안정성 보장
\n•${' '}언제든지 거래 가능하며 비용 감소
\n•${' '}실물자산을 기초자산으로 발행하며, 기초 자산에 대해 운영수익, 처분수익 등 수취 가능
\n•${' '}금융당국의 규제하에 있으며, 증권으로써 자본시장법과 각종 법률 등 투자자의 법적 권리 보호 가능`,
      },
      {
        question: '어떤 방법으로 투자할 수 있나요?',
        answer: `플루토스 모바일 앱을 통해 소액으로도 부동산 NPL상품에 투자할 수 있습니다. 모바일 앱을 다운로드 받으시고 회원가입을 통해 투자가 가능합니다. 청약 공모를 통해 토큰증권을 소유하게 되거나 장외시장에 상장된 상품의 토큰증권을 주식처럼 쉽게 사고 팔 수 있습니다. `,
      },
      {
        question: 'TO란 무엇인가요?',
        answer: `NPL을 처분신탁하고 발행된 수익증권을 토큰증권(ST)으로 쪼개어 공모하는데, 쪼개어진 수익증권의 최소 단위를 TO라고 합니다. 수익증권을 소액으로 간편하게 청약, 매매하기 위한 거래 수단입니다.`,
      },
      {
        question: 'TO에 대한 소유권은 법적으로 인정되나요?',
        answer: `TO는 발행과 동시에 한국예탁결제원에 전자등록되며, TO를 매입한 개개인에 대한 기록은 증권사인 계좌관리기관의 고객계좌부에 기록/보관됩니다. 전자증권법에 따라 TO소유자는 수익증권에 대한 권리를 적법하게 보유하는 것으로 인정받을 수 있습니다.`,
      },
      {
        question: '서비스는 누구나 이용이 가능한가요?',
        answer: `플루토스의 서비스는 만19세 이상 성인(내국인)이면 누구나 가입하여 모바일 앱을 통해 부동산에 투자할 수 있습니다.`,
      },
      {
        question: 'NPL상품은 원금 보장이 가능한가요?투자한 부동산에 문제가 생기면 어떻게 되는 건가요?',
        answer: `NPL을 비롯하여 대부분의 금융투자 상품은 원금 손실 발생 가능성이 있으며, 손실은 투자자에게 귀속됩니다. 다만, 본 상품은 정상적인 부동산 상품에서 추가적인 수익을 기대하는 것이 아니라, 이미 부실이 난 상품에서 정상가액을 회복해 가는 과정이므로 안전 마진층이 두터우며 불황일수록 강하다는 장점이 있습니다.`,
      },
      {
        question: '예치금/투자상품은 어떻게 관리되나요?',
        answer: `플루토스 서비스 이용을 위해서는 계좌관리기관인 NH투자증권 계좌를 개설해야 합니다. 제휴 계좌에 입금된 현금은 투자회원 본인의 예수금 계좌 입금으로 분류되어 플루토스의 고유재산과는 분리됩니다. 따라서 플루토스는 투자자의 예수금, 투자금, 배당금, 상환금에 대한 임의 출금이 불가능합니다. 투자하신 상품 역시 신탁회사가 안전하게 보관 및 관리하며 상품에 따라서는 자산관리전문업체에 위탁됩니다. 즉, 자산의 처분 및 출금을 위하여는 신탁사와 증권사의 동의가 모두 필요한 구조로써 플루토스와는 절연되며 안심하고 투자하실 수 있습니다.`,
      },
      {
        question: '건물에 화재 또는 파손이 발생하는 경우 어떻게 되나요?',
        answer: `플루토스 공모 상품은 화재보험 또는 재산보험에 가입되어 있습니다. 보험 계약상 보험사고가 발생한 경우 신탁회사는 보험회사에 통보하고 보험금을 청구하여 하자를 수선합니다.`,
      },
    ],
  },
  {
    title: '회원계정 및 보안',
    value: 'member',
    faqArr: [
      {
        question: '회원가입시 본인인증이 꼭 필요한가요?',
        answer: `네 회원가입시 휴대폰 번호를 통한 본인인증이 반드시 필요합니다.`,
      },
      {
        question: '휴대폰 명의가 중요한가요?',
        answer: `플루토스는 본인 명의의 휴대폰을 통해 인증을 진행합니다. 본인 명의의 휴대폰이 아닌경우 서비스 사용에 제한이 될 수 있으니 꼭 본인명의의 휴대폰 사용을 부탁드립니다.`,
      },
      {
        question: '간편비밀번호를 잃어버렸습니다.',
        answer: `간편번호를 잊으셨을 경우 휴대폰 본인인증을 통해 간편번호를 변경할 수 있습니다. 재 인증 후 다시한번 간편비밀번호를 설정해 주세요.`,
      },
      {
        question: '회원탈퇴는 어떻게 되나요?',
        answer: `고객센터로 문의 부탁드립니다.`,
      },
    ],
  },
  {
    title: '입금과 출금',
    value: 'deposit',
    faqArr: [
      {
        question: '입금과 출금 질문이 있습니다.',
        answer: `플루토스의 투자서비스는 조만간 오픈 될 예정에 있습니다. 입금과 출금에 관련된 문의는 서비스 오픈 후 자세히 알려드리겠습니다.`,
      },
    ],
  },
  {
    title: '공모 및 청약',
    value: 'collusion',
    faqArr: [
      {
        question: '공모 및 청약절차는 어떻게 되나요?',
        answer: `부동산에 대한 공모/청약은 모바일 앱을 통해 확인이 가능합니다. 청약가능 일자와 투자상품의 상세정보를 제공하오니 정보를 보시고 청약일에 청약하시면 됩니다.`,
      },
      {
        question: '청약 수량은 원하는데로 받을 수 있나요?',
        answer: `청약한 고객님의 신청 순서에 따라 배정이 됩니다. 따라서 청약한 수량을 100% 받지 못하는 경우가 발생할 수 있습니다.`,
      },
      {
        question: '청약취소는 가능한가요?',
        answer: `네, 청약신청 기간에 취소는 가능합니다. 다만 조기 완판되어 청약이 완료가 된 경우 청약취소를 할 수 없으니 투자에 유의해 주세요.`,
      },
    ],
  },
  {
    title: '매수와 매도',
    value: 'buysell',
    faqArr: [
      {
        question: '매수와 매도 질문이 있습니다.',
        answer: `플루토스의 투자서비스는 조만간 오픈 될 예정에 있습니다. 매수와 매도 관련된 문의는 서비스 오픈 후 자세히 알려드리겠습니다.`,
      },
    ],
  },
  {
    title: '수익 및 배당',
    value: 'dividend',
    faqArr: [
      {
        question: '부실이 난 상품인데 어떻게 수익이 발생하나요?',
        answer: `부실이 난 상품이지만 적절한 담보가 있기에 환가처분이 가능합니다. NPL은 채권 자체로써 재매각하거나, 경,공매 절차에 따른 채권 배당을 받거나, 직접 낙찰받아 소유권 확보 후 정상화하여 임대수익 및 매각차익을 실현합니다. 투자자는 보유하고 있는 토큰증권 수량에 따라 수익을 배당을 받습니다.`,
      },
      {
        question: '수익은 누구나 받을 수 있나요?',
        answer: `ST를 보유하고 있는 고객은 임대수익 및 매각을 통한 수익에 대해 배당을 받을 수 있습니다. 배당에 대한 정보는 공시를 통해 확인이 가능합니다.   
        `,
      },
    ],
  },
  {
    title: '수익자 총회(매각투표)',
    value: 'beneficiary',
    faqArr: [
      {
        question: '수익자 총회란?',
        answer: `수익자 총회란 부동산의 지분을 가진 수익자들이 의사를 반영하는 시스템입니다. 전자투표를 이용하여 부동산 매각등에 관한 투표를 통해 의사를 반영할 수 있습니다.
        `,
      },
      {
        question: '총회의 내용은 어떤건가요?',
        answer: `부동산에 대한 결정이 필요할때 투표를 실시하게 됩니다. 투표의 내용은 부동산의 매각, 처분시점 조정, 수탁자 해임 및 변경등이 있습니다.
        `,
      },
      {
        question: '여러번 투표가능한가요?',
        answer: `투표는 여러번 할 수 없습니다. 투표권은 ST 보유수량에 따라 의결권의 수량이 정해집니다. 투표 전 신중한 투자가 필요합니다.
        `,
      },
    ],
  },
];
