import React, { useState } from 'react';
import ImageSwiper from '@components/swiper/ImageSwiper';
import { getConnectStatus, getDateFormat, regPrice } from '@utils/util';
import { GoodsDetailIF } from 'interface/goodsInterface';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import '@assets/css/common/ck-content.css';
import { Waypoint } from 'react-waypoint';
import WaypointToAnimate from '@components/common/WaypointToAnimate';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import KaKaoMap from '@components/common/KaKaoMap';
import EditorViewer from '@components/common/EditorViewer';
import CommonModal from '@components/modal/CommonModal';

interface GoodsItemProps {
  data: GoodsDetailIF;
}

/**
 * 상품 상세보기 - 정보 메뉴안에 들어갈 컴포넌트
 * @param param0
 * @returns
 */
/* js파일 동작오류로 인해 className - add-animation 주석처리 참고 , Editor viewr 미적용  */
const GoodsItem = ({ data }: GoodsItemProps) => {
  const { info, webTopImages, appTopImages, attachFiles } = data;
  const path = useLocation().pathname;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { routePath, status } = getConnectStatus(path);

  const dottedStyle: any = status === 'web' ? { border: '2px dotted #d6001f' } : {};

  const topImages = status === 'web' ? webTopImages : appTopImages;

  const [activeAccordion, setActiveAccordion] = useState<{
    userInfo: boolean;
    declaration: boolean;
    files: boolean;
    [props: string]: any;
  }>({
    userInfo: false,
    declaration: false,
    files: false,
  });

  function handleActiveAccordion(name: string) {
    setActiveAccordion({ ...activeAccordion, [name]: !activeAccordion[name] });
  }

  function checkConnectEditor(params: string) {
    let res = true;
    if (status === 'app' && !params) {
      res = false;
    }

    return res;
  }

  function EditorForm({ editorData }: { editorData: string }) {
    if (status === 'app') {
      return <div className="valueAddCon">{typeByText('Editor', editorData)}</div>;
    } else {
      return (
        <>
          <div style={{ minHeight: 80 }}>
            <div className="valueAddCon">{typeByText('Editor', editorData)}</div>
          </div>
          <div
            style={{
              background: 'rgba(255,255,255,0.97)',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            {/* <div className="appDownWrap"> */}
            <div className="appDownCon">
              <h4
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                앱을 통해 회원가입을 하시면 확인이 가능합니다.
              </h4>
            </div>
          </div>
          {/* </div> */}
        </>
      );
    }
  }

  /**
   * 컬럼 type 별 문구 및 정규식처리 함수
   * @param type
   * @param value
   * @returns
   */
  function typeByText(
    type: 'Price' | 'Area' | 'Percent' | 'Common' | 'Count' | 'Editor' | 'Date',
    value: string | number,
  ) {
    let res: any = '-';
    if (value) {
      switch (type) {
        case 'Date':
          res = getDateFormat(value, 'YYYY.MM.DD');
          break;
        case 'Price':
          res = `${regPrice(value)} 원`;
          break;
        case 'Count':
          res = `${regPrice(value)}`;
          break;
        case 'Area':
          res = `${regPrice(value)} ㎡`;
          break;
        case 'Percent':
          res = `${value} %`;
          break;
        case 'Editor':
          res = <EditorViewer data={String(value)} />;
          break;
        default:
          res = `${value}`;
          break;
      }
    } else {
      if (type === 'Editor') {
        res = <div></div>;
      }
    }

    return res;
  }

  return (
    <>
      <div className="product_investment_detail">
        <div className="productCon">
          <div className="propertyInfo">
            <ul>
              <li>
                <dl>
                  <dt>공모가</dt>
                  <dd>{typeByText('Price', info!.pssrpc)}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>공모수량</dt>
                  <dd>
                    {typeByText('Count', info!.pssrpqy)}
                    {info!.pssrpqy ? ' TO' : ''}
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>TO 상장일</dt>
                  <dd>{typeByText('Date', info!.lston)}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>배당주기</dt>
                  <dd>{typeByText('Common', info!.alotcycle)}</dd>
                </dl>
              </li>
              {info!.sttusSe === '매각' ? (
                <>
                  <li>
                    <dl>
                      <dt>매각가</dt>
                      <dd>{typeByText('Price', info!.saleinfoSalepc)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>매각일</dt>
                      <dd>{typeByText('Date', info!.saleinfoSaleon)}</dd>
                    </dl>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>

        {Boolean(topImages?.length) && (
          <div className="productCon productCon_type01">
            <ImageSwiper imgList={topImages!.map((i) => i.imageUrl)} />
          </div>
        )}

        <div className="productCon">
          <div className="propertyInfo">
            <h3 className="title_sub">
              <span>투자정보</span>
            </h3>
            {/* <ul className="add-animation"> */}

            <WaypointToAnimate id={'goods0'}>
              <div className="add-animation animation-0" id="goods0">
                <ul className="">
                  <li>
                    <dl>
                      <dt>투자대상</dt>
                      <dd>{typeByText('Common', info!.goodsNm)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>위치</dt>
                      <dd>{typeByText('Common', `${info!.goodsAdres}, ${info!.goodsDetailAdres}`)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기초자산</dt>
                      <dd>{typeByText('Common', info!.invtinfoBsisassets)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>대출잔액</dt>
                      <dd>{typeByText('Price', info!.invtinfoLonblce)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>현재 채권액</dt>
                      <dd>{typeByText('Price', info!.invtinfoNowCditam)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>설정액</dt>
                      <dd>{typeByText('Price', info!.invtinfoDtrmnam)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>감정가액</dt>
                      <dd>{typeByText('Price', info!.invtinfoApprslamount)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>경매사건</dt>
                      <dd>{typeByText('Common', info!.invtinfoAucincdnt)}</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </WaypointToAnimate>
          </div>
        </div>

        {info!.invtPointCn && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>투자포인트</span>
            </h3>

            {/* <div className="investmentPoint add-animation"> */}

            <WaypointToAnimate id={'goods1'}>
              <div className="investmentPointList add-animation" id="goods1">
                <div className="valueAddCon">{typeByText('Editor', info!.invtPointCn)}</div>
              </div>
            </WaypointToAnimate>
            {/* </div> */}
          </div>
        )}
        <WaypointToAnimate id={'goods2'}>
          {/* <div className="productCon"> */}
          <div className="investmentWrap add-animation animation-1" id="goods2" style={{ marginTop: 20 }}>
            {/* <div className="investmentInfoList add-animation animation-1"> */}
            <div className="investmentInfoList">
              <div className="propertyInfo propertyInfo2">
                <h3 className="title_sub">
                  <span>토지정보</span>
                </h3>
                <ul>
                  <li>
                    <dl>
                      <dt>지목</dt>
                      <dd>{typeByText('Common', info!.ladinfoLndcgr)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>용도지역</dt>
                      <dd>{typeByText('Common', info!.ladinfoSpfc)}</dd>
                    </dl>
                  </li>
                  {/* <li>
                    <dl>
                      <dt>기초자산</dt>
                      <dd>{typeByText('Common', info!.ladinfoBsisassets)}</dd>
                    </dl>
                  </li> */}
                  <li>
                    <dl>
                      <dt>토지면적(대지권)</dt>
                      <dd>{typeByText('Area', info!.ladinfoPlotvlmAr)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공시지가</dt>
                      <dd>
                        {typeByText('Count', info!.ladinfoOlnlp)}
                        {info!.ladinfoOlnlp ? ' 원/㎡' : ''}
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="investmentInfoList add-animation animation-2"> */}
            <div className="investmentInfoList add-animation animation-2" id="goods2">
              <div className="propertyInfo propertyInfo2">
                <h3 className="title_sub">
                  <span>건물정보</span>
                </h3>
                <ul>
                  <li>
                    <dl>
                      <dt>규모</dt>
                      <dd>{typeByText('Common', info!.buldinfoScale)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>주용도</dt>
                      <dd>{typeByText('Common', info!.buldinfoWeekprpos)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>구조</dt>
                      <dd>{typeByText('Common', info!.buldinfoStrct)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>건축면적(전용면적)</dt>
                      <dd>{typeByText('Area', info!.buldinfoBildngarCntrct)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>연면적(계약면적)</dt>
                      <dd>{typeByText('Area', info!.buldinfoBildngarPrvuse)}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>준공일</dt>
                      <dd>{typeByText('Date', info!.buldinfoCompeton)}</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="investmentInfoList add-animation animation-3"> */}
            {Boolean(info!.curprcinfoUpperavrgpc && info!.curprcinfoGnrlavrgpc && info!.curprcinfoLwprtavrgpc) && (
              <div className="investmentInfoList add-animation animation-3" id="goods2">
                <div className="propertyInfo propertyInfo2">
                  <h3 className="title_sub">
                    <span>시세정보</span>
                  </h3>
                  <ul>
                    <li>
                      <dl>
                        <dt>상위평균가</dt>
                        <dd>{typeByText('Price', info!.curprcinfoUpperavrgpc)}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>일반평균가</dt>
                        <dd>{typeByText('Price', info!.curprcinfoGnrlavrgpc)}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>하위평균가</dt>
                        <dd>{typeByText('Price', info!.curprcinfoLwprtavrgpc)}</dd>
                      </dl>
                    </li>
                    {/* <li>
                      <dl>
                        <dt>건축면적(전용면적)</dt>
                        <dd>{typeByText('Area', info!.buldinfoBildngarCntrct)}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>연면적(계약면적)</dt>
                        <dd>{typeByText('Area', info!.buldinfoBildngarPrvuse)}</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>준공일</dt>
                        <dd>{typeByText('Date', info!.buldinfoCompeton)}</dd>
                      </dl>
                    </li> */}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
        </WaypointToAnimate>
        {checkConnectEditor(info!.curprcinfoCn) && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>시세정보</span>
            </h3>

            {/* <div className="valueAddWrap">
          <div className="valueAddCon">{typeByText('Editor', info!.scsbidpcrtinfoCn)}</div>
        </div> */}

            <WaypointToAnimate id={'goods3'}>
              <div className="investmentInfoList add-animation animation-1" id="goods3" style={dottedStyle}>
                <EditorForm editorData={info!.curprcinfoCn} />
              </div>
            </WaypointToAnimate>
          </div>
        )}
        {/* 
        낙찰가율
        예상 배당액
        Value Add
        예상 수익율
      */}
        {checkConnectEditor(info!.scsbidpcrtinfoCn) && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>낙찰가율</span>
            </h3>

            {/* <div className="valueAddWrap">
              <div className="valueAddCon">{typeByText('Editor', info!.scsbidpcrtinfoCn)}</div>
              </div> */}

            <WaypointToAnimate id={'goods3'}>
              <div className="investmentInfoList add-animation animation-1" id="goods3" style={dottedStyle}>
                <EditorForm editorData={info!.scsbidpcrtinfoCn} />
              </div>
            </WaypointToAnimate>
          </div>
        )}

        {checkConnectEditor(info!.expectAlotamCn) && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>예상 배당액</span>
            </h3>
            {/* 
        <div className="valueAddWrap">
          <div className="valueAddCon">{typeByText('Editor', info!.expectAlotamCn)}</div>
        </div> */}
            <WaypointToAnimate id={'goods4'}>
              <div className="investmentInfoList add-animation animation-2" id="goods4" style={dottedStyle}>
                <EditorForm editorData={info!.expectAlotamCn} />
              </div>
            </WaypointToAnimate>
          </div>
        )}

        {checkConnectEditor(info!.valueAddCn) && info!.valueAddCn && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>Value Add</span>
            </h3>

            {/* <EditorForm editorData={info!.valueAddCn} /> */}

            <WaypointToAnimate id={'goods5'}>
              <div className="investmentInfoList add-animation animation-3" id="goods5">
                {/* <EditorForm editorData={info!.valueAddCn} /> */}
                <div className="valueAddCon">{typeByText('Editor', info!.valueAddCn)}</div>
              </div>
            </WaypointToAnimate>
          </div>
        )}

        {checkConnectEditor(info!.expectErnrtCn) && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>예상 수익율</span>
            </h3>

            {/* <div className="valueAddWrap">
          <div className="valueAddCon">{typeByText('Editor', info!.expectErnrtCn)}</div>
        </div> */}
            <WaypointToAnimate id={'goods6'}>
              <div className="investmentInfoList add-animation animation-4" id="goods6" style={dottedStyle}>
                <EditorForm editorData={info!.expectErnrtCn} />
              </div>
            </WaypointToAnimate>
          </div>
        )}

        {info!.goodsAdres && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>위치정보</span>
            </h3>

            {/* <div className="mapWrap add-animation"> */}
            <WaypointToAnimate id={'goods7'}>
              <div className="mapWrap add-animation animation-5" id="goods7">
                <p className="productLocation">{typeByText('Common', info!.goodsAdres)}</p>
                <div className="mapCon">
                  {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.842017669316!2d129.1662119763005!3d35.16056055839936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35688d69e9902563%3A0xc023625187b6d464!2z67aA7IKw6rSR7Jet7IucIO2VtOyatOuMgOq1rCDri6zrp57snbTquLggMzA!5e0!3m2!1sko!2skr!4v1688198272236!5m2!1sko!2skr"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}
                  <KaKaoMap address={info!.goodsAdres ? info!.goodsAdres : ''} />
                </div>
              </div>
            </WaypointToAnimate>
          </div>
        )}

        {info!.cfrDevlopinfoCn && (
          <div className="productCon">
            <h3 className="title_sub">
              <span>개발정보</span>
            </h3>

            {/* <div className="developWrap add-animation"> */}
            {/* <div className="developWrap">
          <div className="developList">{typeByText('Editor', info!.cfrDevlopinfoCn)}</div>
          
          <div className="developList">
            <div className="developTitle">
              <h4>토지/도시개발</h4>
              <a href="#" className="scrap">
                스크랩
              </a>
            </div>
            <div className="developImg">
              <img src="../resources/img_app/web/investment_img/inverst01.png" alt="" />
            </div>
            <dl className="developTxt">
              <dt>사당 이수 지구단위계획 재정비 수립</dt>
              <dd>서울특별시 동작구 사당동 1019-24</dd>
            </dl>
          </div>
        </div> */}

            <WaypointToAnimate id={'goods8'}>
              <div className="investmentInfoList add-animation animation-6" id="goods8">
                {' '}
                <div className="valueAddWrap">
                  <div className="valueAddCon">{typeByText('Editor', info!.cfrDevlopinfoCn)}</div>
                </div>{' '}
              </div>
            </WaypointToAnimate>
          </div>
        )}

        <div className="productCon">
          <div className="productEtcWrap">
            <div className={`productEtc accordion01 ${activeAccordion['userInfo'] ? 'active' : ''}`}>
              <div className="productEtcTitle accordionView" onClick={() => handleActiveAccordion('userInfo')}>
                <h3>임차인 정보</h3>
                <p className="accordionOpen">
                  <span className="ico ico_open">열린 메뉴</span>
                </p>
              </div>

              <div className="productEtcView accordionDetail">
                {info!.lesseeinfoCn ? (
                  <div className="valueAddWrap">
                    <div className="valueAddCon">{typeByText('Editor', info!.lesseeinfoCn)}</div>
                  </div>
                ) : (
                  <p style={{ fontSize: '1.4rem', textTransform: 'uppercase', textAlign: 'center' }}>
                    정보가 존재하지 않습니다.
                  </p>
                )}
              </div>
            </div>
            <div className={`productEtc accordion01 ${activeAccordion['declaration'] ? 'active' : ''}`}>
              <div className="productEtcTitle accordionView" onClick={() => handleActiveAccordion('declaration')}>
                <h3>권리신고</h3>
                <p className="accordionOpen">
                  <span className="ico ico_open">열린 메뉴</span>
                </p>
              </div>

              <div className="productEtcView accordionDetail">
                {info!.rightsttemntCn ? (
                  <div className="valueAddWrap">
                    <div className="valueAddCon">{typeByText('Editor', info!.rightsttemntCn)}</div>
                  </div>
                ) : (
                  <p style={{ fontSize: '1.4rem', textTransform: 'uppercase', textAlign: 'center' }}>
                    정보가 존재하지 않습니다.
                  </p>
                )}
              </div>
            </div>

            <div className={`productEtc accordion01 ${activeAccordion['files'] ? 'active' : ''}`}>
              <div className="productEtcTitle accordionView" onClick={() => handleActiveAccordion('files')}>
                <h3>투자관련 문서</h3>
                <p className="accordionOpen">
                  <span className="ico ico_open">열린 메뉴</span>
                </p>
              </div>

              <div className="productEtcView accordionDetail">
                {Boolean(attachFiles.length) ? (
                  <div className="fileDownWrap">
                    <ul>
                      {attachFiles.map((file, index) => (
                        <li key={`${index}`}>
                          <dl>
                            <dt>{file.filenm}</dt>
                            <dd>
                              <a href={file.flpth} download className="fileDownBtn" target="_blank" rel="noreferrer">
                                <span>내려받기</span>
                              </a>
                            </dd>
                          </dl>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p style={{ fontSize: '1.4rem', textTransform: 'uppercase', textAlign: 'center' }}>
                    문서가 존재하지 않습니다.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="productCon">
          <h3 className="title_sub01">
            <span>투자 유의사항</span>
          </h3>

          <div className="noticeInfo">
            <ul>
              <li>
                〮 본 투자상품은 부동산 담보채권을 기초자산으로 발행하는 수익증권에 대한 투자로써 부동산 NPL 간접투자에
                관한 것입니다.
              </li>
              <li>
                {` 〮TO는 NPL자산을 관리처분신탁하고 발행하는 수익증권에 1:1로 상응하여 발행되는 디지털자산${
                  getConnectStatus(path).status === 'app' ? ' ' : ''
                }증서로서 증권사의 중개로 거래 가능합니다.`}
              </li>
              <li>
                〮TO는 예금자보호법에 따른 예금자 보호 대상 금융상품이 아니며, 기타 어떠한 관계법령에서도 TO투자에 대한
                수익을 보장하거나 보전하지 않습니다.
              </li>
              <li>
                〮본 투자상품은 담보자산의 가격하락 등의 원인에 따라 원금손실(0~100%)이 발생할 수 있으며, 그 손실은
                투자자에게 귀속됩니다.
              </li>
              <li> 〮투자자는 투자 전 증권신고서, 투자설명서 및 약관을 반드시 확인해 주시기 바랍니다.</li>
            </ul>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <CommonModal
          onClose={() => {
            setIsModalVisible(false);
          }}
          title="앱 다운로드"
          content="test"
        />
      )}
      {/* isModalVisible<CommonModal title="test" content="test" isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} /> */}
    </>
  );
};

// const style: any = {
//   '@media (max-width: 425px)': {
//     display: 'flex !important',
//   },
// };

export default GoodsItem;
