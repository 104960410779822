import { UserInfoIF } from 'interface/UserInterface';
import { useState } from 'react';

interface SplashProps {
  onClick: (name: string, value: string) => void;
  userInfo: UserInfoIF;
  setUserInfo: any;
  type: string;
}

const AppSplashEmail = ({ onClick, userInfo, setUserInfo, type }: SplashProps) => {
  const [splashUserEmail, setSplashUserEmail] = useState('');

  function chkUserEmail() {
    // 이메일 체크
    var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!regExp.test(splashUserEmail)) {
      alert('유효하지 않은 이메일입니다.');
      return;
    } else {
      setUserInfo({
        ...userInfo,
        email: splashUserEmail,
      });
      onClick('splashIndex', 'signin_pwd');
    }
  }

  function GetBtnBottom() {
    let res = <></>;

    var regExp =
      /^(?=.{1,256}$)(?=.{1,64}@.{1,255}$)(([a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*)|("(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f])|([a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~\x20\x21\x23-\x5b\x5d-\x7e]))*"))@((\[((([0-9]{1,3}\.){3}[0-9]{1,3})|(([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.[a-zA-Z]{2,})))|(([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}))/;

    if (!regExp.test(splashUserEmail)) {
      res = (
        <button className="buttonType02" onClick={() => alert('올바른 이메일 형식을 입력해 주세요.')}>
          비밀번호 입력하기
        </button>
      );
    } else {
      res = (
        <button className="buttonType01" onClick={() => chkUserEmail()}>
          비밀번호 입력하기
        </button>
      );
    }

    return res;
  }

  return (
    <>
      <div className="startWrap">
        <h3 className="systemLogo">PLUTOS</h3>

        <div className="loginInputWrap">
          <div className="loginInputCon">
            <div className="loginInputConArea">
              <div className="form-group">
                <dl>
                  <dt>
                    <label className="required">이메일 주소를 입력해 주세요.</label>
                  </dt>
                  <dd>
                    <input
                      type="email"
                      className="form-control input-radius"
                      id="email"
                      name="email"
                      placeholder="이메일 주소를 입력해 주세요"
                      onChange={(e) => setSplashUserEmail(e.target.value)}
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="btnBottom">
        <GetBtnBottom />
      </div>
    </>
  );
};
export default AppSplashEmail;
