import Terms from '@pages/user/Terms';
import React from 'react';
import Modal from 'react-modal';

interface TermsModalProps {
  onClose: () => void;
  title: string;
  termsType: string;
  isCloseBtn?: boolean;
}
const TermsModal = ({ onClose, title, termsType, isCloseBtn }: TermsModalProps) => {
  const handleBackdropClick = (event: any) => {
    // backdrop 클릭 시 모달을 닫는 동작
    if (event.target.className === 'modal-backdrop') {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      overlayClassName={'modal-backdrop'}
      className={'modal inmodal fade in'}
      ariaHideApp={false}
      style={{
        /* backdrop 영역 */
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        /* content 영역 */
        content: {
          //   overflowX: 'hidden',
          // overflowY: 'scroll',
          display: 'block',
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content" style={{ height: '90vh' }}>
          <div className="modal-header" style={{ backgroundColor: '#d6001f' }}>
            <button type="button" className="close" title="닫기" onClick={onClose}>
              <span aria-hidden="true" className="ti-close"></span>
              <span className="sr-only">Close</span>
            </button>
            <h4 className="modal-title">{title}</h4>
          </div>

          <div className="modal-body" style={{ height: '90%', overflowY: 'auto' }}>
            <Terms name={termsType} />
          </div>

          <div className="modal-footer">
            {/* {!isCloseBtn && (
              <button className="btn btn-primary" onClick={onClose}>
                창닫기
              </button>
            )} */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default TermsModal;
