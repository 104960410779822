import PLImage from '@components/common/PLImage';
import { PATH, WEB_PATH } from '@constants/path';
import {
  calculatePercentage,
  getBeginEndDate,
  getConnectStatus,
  getDateFormat,
  regPrice,
  typeByText,
  getNowDate,
} from '@utils/util';
import { GoodsItemIF } from 'interface/goodsInterface';
import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface SubscriptionProductProps {
  data: GoodsItemIF;
  path: string;
}

const SubscriptionProduct = ({ data, path }: SubscriptionProductProps) => {
  const { routePath, status } = getConnectStatus(path);
  const navigate = useNavigate();

  function getSlideBox(subscrptpdBegin: string, subscrptpdEnd: string) {
    const nowDate = moment().format('YYYY-MM-DD');

    if (nowDate < subscrptpdBegin) {
      return (
        <div className="slideBox box01">
          <span>모집예정</span>
        </div>
      );
    } else if (nowDate > subscrptpdEnd) {
      return (
        <div className="slideBox box01">
          <span>모집종료</span>
        </div>
      );
    }
  }

  return (
    <div
      className="product_investment_list subscription_product"
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(`${routePath.goods_detail}/${data.goodsSn}`, { state: { appGoodsListType: 'C' } })}
    >
      {/* <>{getSlideBox(data.subscrptpdBegin, data.subscrptpdEnd)}</> */}

      <div className="product_thumb swiper-container" id="productGallery01">
        {getSlideBox(data.subscrptpdBegin, data.subscrptpdEnd)}
        <div className="swiper-wrapper">
          <div className="product_thumb_list swiper-slide">
            <PLImage imgSrc={status === 'web' ? data.webThumb : data.appThumb} alt="" />
          </div>
        </div>
      </div>
      <div className="product_detail">
        {/* <Link to={`${routePath.goods_detail}/${data.goodsSn}`}> */}
        <div className="product_detail_info01">
          <div className="product_info01">
            <p className="product_category">청약</p>
            <h4 className="product_title">{data.goodsNm}</h4>
            <p className="product_address">{data.goodsAdres}</p>
          </div>
          <div className="product_info02">
            <p>
              <span>
                <i className="ri-calendar-event-fill"></i> 청약기간
              </span>
              {getBeginEndDate(data.subscrptpdBegin, data.subscrptpdEnd, 'YYYY.MM.DD')}
            </p>
          </div>
        </div>

        <div className="product_detail_info02">
          <div className="product_info03">
            <ul>
              <li>
                <dl>
                  <dt>청약일</dt>
                  <dd>{typeByText('Date', data.subscrptpdBegin)}</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>감정가</dt>
                  <dd>
                    <span className="appraisedTxt">{regPrice(data.invtinfoApprslamount)}</span>
                    <span className="unit_product">원</span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>공모가</dt>
                  <dd>
                    {regPrice(data.pssrpc)}
                    <span className="unit_product">원</span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>총 TO 수량</dt>
                  <dd>
                    {regPrice(data.pssrpqy)}
                    <span className="unit_product">TO</span>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>공모가/감정가</dt>
                  <dd>
                    {calculatePercentage(Number(data.pssrpc), Number(data.invtinfoApprslamount))}
                    <span className="unit_product">%</span>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default SubscriptionProduct;
