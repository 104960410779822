import { Outlet } from 'react-router-dom';

const AppSplashLayout = () => {
  return (
    <>
      <div id="mobileWrap" className="mobileWrap">
        <Outlet />
      </div>
    </>
  );
};

export default AppSplashLayout;
