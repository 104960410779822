import WaypointToAnimate from '@components/common/WaypointToAnimate';
import React from 'react';

/**
 * npl 투자란 ?
 * @returns
 */
const NplGuide = () => {
  return (
    <div className="serviceWrap serviceBg">
      <div className="subConView">
        <h3 className="title_sub">
          <span>NPL투자 장단점</span>
        </h3>

        <div className="sectionCon">
          <div className="investmentWrap meritWrap">
            <WaypointToAnimate id={'animate1'}>
              <div className="investmentInfoList add-animation animation-1" id="animate1">
                <div className="propertyInfo">
                  <h3 className="title_sub">
                    <span>NPL투자 장점</span>
                  </h3>
                  <ul>
                    <li>
                      <dl>
                        <dt>수익성</dt>
                        <dd>
                          <div className="blt01">
                            <p>일반 경매보다 높은 수익실현 가능</p>
                            <p>채권 할인 매입 가능</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>안정성</dt>
                        <dd>
                          <div className="blt01">
                            <p>객관적인 감정가액 있으며 담보가치 변동성 작음</p>
                            <p>통상 LTV 70% 수준으로 손실 가능성 작음</p>
                            <p>법원 등을 통한 환가 진행으로 절차가 안정적이며 정확도 높음</p>
                            <p>권리분석을 통한 객관적 평가 가능</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>환금성</dt>
                        <dd>
                          <div className="blt01">
                            <p>경, 공매 진행기간은 통상 1년으로 투자기간 짧음</p>
                            <p>채권 자체로도 매각 가능함</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>절세효과</dt>
                        <dd>
                          <div className="blt01">
                            <p>채권자가 단순 배당 받는 경우, 배당소득세 비과세</p>
                            <p>
                              채권자가 직접 낙찰받은 후 재매각하는 경우, 재매각액이 낙찰가액을 넘지 않는다면 양도소득세
                              비과세
                            </p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>채권자 지위를 활용한 낙찰</dt>
                        <dd>
                          <div className="blt01">
                            <p>
                              불어난 연체이자로 인하여 일반 경매 입찰자보다 더 높은 가액의 입찰 가능하며, 낙찰 가능성
                              높음(유입)
                            </p>
                            <p>
                              타인 낙찰시, 부동산 취득은 불가능하지만 보유한 NPL 채권 전액을 배당 받을 수 있음
                              (방어입찰)
                            </p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </div>
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animate2'}>
              <div className="investmentInfoList add-animation animation-2" id="animate2">
                <div className="propertyInfo">
                  <h3 className="title_sub">
                    <span>NPL투자 단점</span>
                  </h3>
                  <ul>
                    <li>
                      <dl>
                        <dt>높은 진입장벽</dt>
                        <dd>
                          <div className="blt01">
                            <p>채권매입 자격제한</p>
                            <p>자본금 5억원 이상, 금융위 채권매입 및 추심업 등록, 대부 법인, 유동화전문회사 등</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>많은 투자금 필요</dt>
                        <dd>
                          <div className="blt01">
                            <p>
                              최근 정부 정책으로 근저당권에 대한 질권대출이 제한되면서 NPL 매입 자금에 대한 레버리지
                              사라짐
                            </p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>다양성의 부족</dt>
                        <dd>
                          <div className="blt01">
                            <p>일반 매매시장에 비하여 다양성 부족</p>
                            <p>원하는 조건의 물건이 출회되기까지 기다려야 할 수 있음</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>권리분석의 어려움</dt>
                        <dd>
                          <div className="blt01">
                            <p>부동산 지식에 더하여 경,공매 지식 필요</p>
                            <p>최우선 변제금, 체당금, 임금채권 등 초보 투자자의 접근 어려움</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>낙찰가액의 불확실성</dt>
                        <dd>
                          <div className="blt01">
                            <p>
                              약 1년후 낙찰가액을 예측하는 것으로, 부동산 경기와 정책 변화에 따른 탄력적 대응 어려움
                            </p>
                            <p>매입시점의 예상 수익이 낙찰시점의 수익과 다를 수 있음</p>
                            <p>시장흐름에 대한 안목 필요</p>
                          </div>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <div className="boxCon">PLUTOS 플랫폼을 통한 보완</div>
                    </li>
                  </ul>
                </div>
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NplGuide;
