import { WEB_PATH_NAME } from '@constants/path';
import { replacePath } from '@utils/util';
import React from 'react';
interface TitleProps {
  path: string;
}

/**
 * 페이지별 제목
 * @param param0
 * @returns
 */
const WebTitle = ({ path }: TitleProps) => {
  return (
    <div className="titleArea">
      <h3>{WEB_PATH_NAME[replacePath(path)].title}</h3>
      <p>{WEB_PATH_NAME[replacePath(path)].subTitle}</p>
    </div>
  );
};

export default WebTitle;
