import React, { useEffect } from 'react';
// import '@assets/css/mobile/content.css';
import AppHeader from '@components/header/app/AppHeader';
import AppFooter from '@components/footer/AppFooter';
import TopButton from '@components/common/TopButton';
import { Outlet, useLocation } from 'react-router-dom';
import { replacePath } from '@utils/util';
import { PATH } from '@constants/path';

const AppLayout = () => {
  const location = useLocation().pathname;
  const routePath = replacePath(location);

  function getClassName() {
    let res = 'mobileSection';

    if (!location.includes('anno_detail')) {
      if (routePath === PATH.main) {
        res = '';
      } else if (routePath === PATH.goods) {
        if (location.split('/')[3] !== undefined) {
          res = '';
        }
      }
    }
    return res;
  }
  return (
    <>
      <div id="mobileWrap" className="mobileWrap">
        <AppHeader />
        <div className="mobileContent">
          {/* <div className={routePath === PATH.main ? '' : 'mobileSection'}> */}
          <div className={getClassName()}>
            <Outlet />
          </div>
        </div>
        {!location.includes(PATH.goods_detail) && <AppFooter />}
      </div>
      {/* <TopButton /> */}
    </>
  );
};

export default AppLayout;
