import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StorySwiper from '@components/swiper/StorySwiper';
import PLImage from '@components/common/PLImage';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getDateFormat } from '@utils/util';
import { SwiperSlide } from 'swiper/react';
import ReactPlayer from '@components/common/ReactPlayer';

const Story = () => {
  const navigate = useNavigate();

  const [storyInfo, setStoryInfo] = useState<{ event: Array<any>; state: Array<any> }>({
    event: [],
    state: [],
  });

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.story);

      if (data.code === '200') {
        setStoryInfo({ ...data.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchList();
  }, []);
  return (
    <>
      <div className="sectionCon">
        <div className="storyCon">
          <h4>
            <Link to={PATH.plutos_introduction}>PLUTOS 소개</Link>
          </h4>
          <div className="storyGalleryWrap">
            <ReactPlayer isMobile={true} isControl={true} height={'100%'} />
          </div>
        </div>
        <div className="storyCon">
          <h4>
            <Link to={PATH.event}>이벤트</Link>
          </h4>
          {Boolean(storyInfo.event.length) ? (
            <div className="storyGalleryWrap">
              <StorySwiper swiperClassName="galleryCon" itemCount={storyInfo.event.length}>
                {storyInfo.event.map((info, index) => (
                  <SwiperSlide className="galleryConList swiper-slide" key={`${info.stncnSn}_${index}`}>
                    <Link to={`${PATH.event_detail}/${info.sntncSn}`}>
                      <div className="galleryThumb">
                        <PLImage imgSrc={info.appThumb} alt={'이벤트 이미지'} />
                      </div>
                      <dl>
                        <dt>{info.sntncSj}</dt>
                        <dd>
                          {getDateFormat(info.eventBgnde, 'YYYY-MM-DD')} ~{' '}
                          {getDateFormat(info.eventEndde, 'YYYY-MM-DD')}
                        </dd>
                      </dl>
                    </Link>
                  </SwiperSlide>
                ))}
              </StorySwiper>
            </div>
          ) : null}
        </div>
        <div className="storyCon">
          <h4>
            <Link to={PATH.invest_guide}>투자가이드</Link>
          </h4>
        </div>
        <div className="storyCon">
          <h4>
            <Link to={PATH.news}>보도자료</Link>
          </h4>
        </div>
        <div className="storyCon">
          <h4>
            <Link to={PATH.npl_guide}>NPL 소개</Link>
          </h4>
        </div>
        <div className="storyCon">
          <h4>
            <Link to={PATH.state}>부동산 시황</Link>
          </h4>
          {Boolean(storyInfo.state.length) ? (
            <div className="storyGalleryWrap">
              <StorySwiper swiperClassName="galleryCon" itemCount={storyInfo.state.length}>
                {storyInfo.state.map((info, index) => (
                  <SwiperSlide className="galleryConList swiper-slide" key={`${info.stncnSn}_${index}`}>
                    <Link to={`${PATH.state_detail}/${info.sntncSn}`}>
                      <div className="galleryThumb">
                        <PLImage imgSrc={info.appThumb} alt={'시장현황 이미지'} />
                      </div>
                      <dl>
                        <dt>{info.sntncSj}</dt>
                      </dl>
                    </Link>
                  </SwiperSlide>
                ))}
              </StorySwiper>
            </div>
          ) : null}
        </div>
        <div className="storyCon">
          <h4>
            <Link to={PATH.sto_guide}>토큰증권(ST)이란?</Link>
          </h4>
        </div>
      </div>
    </>
  );
};
export default Story;
