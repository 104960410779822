import { useLocation, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  TERMS_SERVICE,
  TERMS_PRIVACY,
  THIRD_PARTY_PRIVACY,
  PERSONAL_INFO,
  IDENTITY_VERIFICATION,
  MARKETING,
} from '@utils/termsContext';

interface TermsProps {
  name?: string;
}

const Terms = ({ name }: TermsProps) => {
  const params = useParams();

  console.log(params, '<params');

  function getText() {
    switch (name ? name : params.name) {
      case 'service':
        return parse(TERMS_SERVICE);
      case 'privacy':
        return parse(TERMS_PRIVACY);
      case 'third':
        return parse(THIRD_PARTY_PRIVACY);
      case 'personal':
        return parse(PERSONAL_INFO);
      case 'identity':
        return parse(IDENTITY_VERIFICATION);
      case 'marketing':
        return parse(MARKETING);
    }
  }

  return (
    <>
      <div className="viewTxt">
        <div className="noticeViewArea" style={{ padding: 0 }}>
          {getText()}
        </div>
      </div>
    </>
  );
};
export default Terms;
