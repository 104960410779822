const ServiceInfo = () => {
  return (
    <>
      <div className="sectionCon">
        <h3 className="systemLogo systemServiceLogo">플루토스</h3>
        <div className="systemServiceTxt">
          <p>국내 유일의 NLP 토큰 증권 </p>
          <p>투자 플랫폼 "PLUTOS"</p>
        </div>

        <div className="mobileCopyrightArea">
          <div className="copyright01">
            <p>주식회사 플루토스</p>
          </div>
          <div className="copyright02">
            {/* <p>사업자등록번호 : 000 00 0000</p>
            <p>통신판매업신고번호 : 2019-서울서초-2927</p>
            <p>대표이사 : 정상기</p>
            <p>주소 : 서울시 강남구 봉은사로 26길 12, 3층</p>
            <p>대표번호 : 070-0000-0000</p>
            <p>© 2023 PLUTOS. All rights reserved.</p> */}
            <p>대표 정상기 &nbsp;&nbsp;&nbsp;&nbsp;개인정보관리책임자 임지훈</p>
            <p>사업자등록번호: 361-86-03018 TEL: 02-555-1873 FAX: 02-555-1874</p>
            <p>서울시 강남구 봉은사로26길12, 3층</p>
            <p>© 2023 PLUTOS. All rights reserved.</p>
          </div>
          <div className="copyright03">
            <p>구매원금과 수익을 보장하지 않으며, 손실에 대한 책임은 모두 구매자에게 있습니다.</p>
            <p>
              조각구매 대상이 되는 상품과 그 상품을 등록하는 개별 판매자에 대한 충분한 검수와 검증에 대한 책임은 당사에
              있습니다.
            </p>
            <p>
              전자상거래 등에서의 소비자보호에 관한 법률(전자상거래법)에 따라 계약을 체결한 소비자는 구매 후 7일 이내
              청약을 철회할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ServiceInfo;
