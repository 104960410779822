import React from 'react';

/**
 * ' 서비스 준비중 ' 컴포넌트
 * @returns
 */
const PrepareService = () => {
  return (
    <div className="noticeOpen">
      <div className="noticeOpenWrap">
        <h3>해당 서비스는 현재 준비중으로 곧 오픈 예정입니다.</h3>
      </div>
    </div>
  );
};

export default PrepareService;
