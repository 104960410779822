import React, { useEffect, useState } from 'react';
import PLImage from '@components/common/PLImage';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { GoodsDetailIF } from 'interface/goodsInterface';
import GoodsItem from '@components/detail/goods/GoodsItem';
import { calculatePercentage, getBeginEndDate, regPrice } from '@utils/util';
import { WEB_PATH } from '@constants/path';

/**
 * 상품 상세보기
 * @returns
 */
const GoodsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [goodsInfo, setGoodsInfo] = useState<GoodsDetailIF>({
    info: undefined,
    attachFiles: [],
    webThumbnail: [],
    webTopImages: [],
  });

  function StatusByTitleInfo() {
    if (goodsInfo.info) {
      const {
        goodsNm,
        goodsAdres,
        subscrptpdBegin,
        subscrptpdEnd,
        tknPc,
        // pssrpc,
        // invtinfoApprslamount,
        tknCurprcse,
        tknCurprcseValue,
        saleinfoAccmlternrt,
      } = goodsInfo.info;

      switch (goodsInfo.info?.sttusSe) {
        case '청약':
          return (
            <>
              <p className="product_category subscription_product">청약</p>
              <div className="tit">{goodsNm}</div>
              {/* <p className="txt">{goodsAdres}</p> */}
              <div className="txtCont_info02">
                <p>
                  <span>청약기간</span> {getBeginEndDate(subscrptpdBegin, subscrptpdEnd, 'YY.MM.DD')}
                </p>
              </div>
            </>
          );

        case '상장':
          return (
            <>
              <p className="product_category listed_product">상장</p>
              <div className="tit">{goodsNm}</div>
              {/* <p className="txt">{goodsAdres}</p> */}
              <div className="txtCont_info02">
                <p className="listed_product01">
                  <span>{regPrice(tknPc)}원</span>
                  <em className={tknCurprcse === 'up' ? 'upIndi' : 'downIndi'}>{tknCurprcseValue}%</em>
                </p>
              </div>
            </>
          );
        case '매각':
          return (
            <>
              <p className="product_category sell_product">매각</p>
              <div className="tit">{goodsNm}</div>
              {/* <p className="txt">{goodsAdres}</p> */}
              <div className="txtCont_info02">
                <p>
                  <span>누적수익율</span>
                  {saleinfoAccmlternrt ? saleinfoAccmlternrt : '-'}%
                </p>
              </div>
            </>
          );
        default:
          return <></>;
      }
    }

    return <></>;
  }

  async function fetchDetailApi() {
    try {
      const { data } = await Axios.get(`${API.goods_detail}?detailKey=${id}`);
      if (data.code === '200' && data.data) {
        setGoodsInfo(data.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchDetailApi();
  }, []);

  return (
    <div className="contentWrap">
      <div className="subContent">
        <div className="topViewCont">
          <div className="img">
            <PLImage imgSrc={goodsInfo.webTopImages!.length ? goodsInfo.webTopImages![0].imageUrl : ''} alt="" />
          </div>
          <div className="txtCont">
            <StatusByTitleInfo />
          </div>
        </div>

        <div className="subConView">
          <div className="realContents">
            <div className="sectionCon">
              {goodsInfo.info ? <GoodsItem data={goodsInfo} /> : null}
              <div className="btnArea text-center">
                <button className="btn btn-primary btn-lg" onClick={() => navigate(WEB_PATH.goods)}>
                  목록
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="subConView">
          <div className="realContents">
            <div className="sectionCon"></div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GoodsDetail;
