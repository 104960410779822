import React from 'react';
import PLImage from '@components/common/PLImage';
import WaypointToAnimate from '@components/common/WaypointToAnimate';

/**
 * 플루토스 소개
 * @returns
 */
const PlutosIntroduction = () => {
  return (
    <>
      <div className="serviceWrap serviceBg">
        <div className="subConView">
          <h3 className="title_sub">
            <span>플루토스 서비스</span>
          </h3>

          <div className="sectionCon">
            <WaypointToAnimate id={'animate1'}>
              <div className="serviceImgWrap add-animation" id="animate1">
                <PLImage imgSrc={require('@assets/img_app/web/serviceImg01.png')} alt="" />
              </div>
            </WaypointToAnimate>

            <WaypointToAnimate id={'animate1'}>
              <ul className="serviceList add-animation" id="animate1">
                <li>
                  <span className="icon_01">
                    전문투자가들의 고유 영역이었던 NPL을 일반투자자들도 진입장벽 없이 누구나, 소액으로, 간편하게 투자
                    기회 제공
                  </span>
                </li>
                <li>
                  <span className="icon_02">
                    신탁사 및 증권사를 활용한 투자 구조로 NPL투자의 장점은 살리면서 안전성 강화
                  </span>
                </li>
                <li>
                  <span className="icon_03">
                    다년간 부동산개발 노하우를 바탕으로 부동산 자산의 Value Add 서비스 제공. 리노베이션, 임차인발굴,
                    시설관리 등 최유효이용 제시하여 수익률의 극대화를 실현함
                  </span>
                </li>
                <li>
                  <span className="icon_04">
                    투자상품 조달자, 운영자, 투자자가 모두 win-win하는 구조로 지속적인 성장 가능
                  </span>
                </li>
                <li>
                  <span className="icon_05">NPL 자산을 정상화시킴으로 부동산 시장 순환 및 안정화 기여</span>
                </li>
              </ul>
            </WaypointToAnimate>
          </div>
        </div>
      </div>

      <div className="serviceWrap">
        <div className="subConView">
          <h3 className="title_sub">
            <span>부동산 아웃렛</span>
          </h3>

          <div className="sectionCon">
            <div className="outletWrap">
              <WaypointToAnimate id={'animate2'}>
                <div className="outletList add-animation animation-1" id="animate2">
                  <div className="outletCon">
                    <h3>아파트</h3>
                    <ul className="outlet01">
                      <li>
                        <dl>
                          <dt>유형</dt>
                          <dd>아파트</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>감정가</dt>
                          <dd>
                            <span className="appraisedTxt">10억</span>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>공모가</dt>
                          <dd>8억 (20% 할인)</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </WaypointToAnimate>
              <WaypointToAnimate id={'animate2'}>
                <div className="outletList add-animation animation-2" id="animate2">
                  <div className="outletCon">
                    <h3>근린빌딩</h3>
                    <ul className="outlet02">
                      <li>
                        <dl>
                          <dt>유형</dt>
                          <dd>근린빌딩</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>감정가</dt>
                          <dd>
                            <span className="appraisedTxt">50억</span>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>공모가</dt>
                          <dd>35억 (30% 할인)</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </WaypointToAnimate>

              <WaypointToAnimate id={'animate2'}>
                <div className="outletList add-animation animation-3" id="animate2">
                  <div className="outletCon">
                    <h3>오피스텔</h3>
                    <ul className="outlet03">
                      <li>
                        <dl>
                          <dt>유형</dt>
                          <dd>오피스텔</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>감정가</dt>
                          <dd>
                            <span className="appraisedTxt">7억</span>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>공모가</dt>
                          <dd>5억 (28% 할인)</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </WaypointToAnimate>

              <WaypointToAnimate id={'animate2'}>
                <div className="outletList add-animation animation-4" id="animate2">
                  <div className="outletCon">
                    <h3>모텔</h3>
                    <ul className="outlet04">
                      <li>
                        <dl>
                          <dt>유형</dt>
                          <dd>모텔</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>감정가</dt>
                          <dd>
                            <span className="appraisedTxt">30억</span>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>공모가</dt>
                          <dd>20억 (33% 할인)</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </WaypointToAnimate>

              <WaypointToAnimate id={'animate3'}>
                <ul className="serviceList add-animation" id="animate3">
                  <li>
                    <span className="icon_06">
                      타 부동산 공모 플랫폼의 경우, 투자 상품이 중소형 상업용 빌딩에 국한되며 공모가액이 감정가액을
                      초과하므로 재매각 수익률이 낮아질 수 밖에 없음
                    </span>
                  </li>
                  <li>
                    <span className="icon_07">
                      플루토스는 다양한 부동산 상품의 할인된 소싱이 안정적으로 가능함. 기초자산의 취득이 NPL로부터
                      시작되기 때문
                    </span>
                  </li>
                  <li>
                    <span className="icon_08">
                      안정적 수익률 달성 가능한 아파트부터 높은 수익률 실현 가능한 공장, 숙박업소 등 투자 성향에 따른
                      다양한 포트폴리오 구성 가능
                    </span>
                  </li>
                </ul>
              </WaypointToAnimate>
            </div>
          </div>
        </div>
      </div>

      <div className="serviceWrap serviceBg">
        <div className="subConView">
          <h3 className="title_sub">
            <span>Value Add 서비스</span>
          </h3>
          <WaypointToAnimate id={'animate4'}>
            <p className="subTxt01 add-animation" id="animate4">
              플루토스는 다년간 부동산개발 노하우를 바탕으로 부동산 자산의 Value Add 서비스를 제공함.{' '}
              <span>리노베이션, 임차인발굴, 시설관리 등 최유효이용 제시하여 수익률의 극대화를 실현함</span>
            </p>
          </WaypointToAnimate>

          <div className="sectionCon">
            <WaypointToAnimate id={'animate4'}>
              <div className="serviceImgWrap add-animation" id="animate4">
                <PLImage imgSrc={require('@assets/img_app/web/serviceImg03.png')} alt="" />
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>

      <div className="serviceWrap">
        <div className="subConView">
          <h3 className="title_sub">
            <span>타 조각투자 상품 비교</span>
          </h3>

          <div className="sectionCon">
            <WaypointToAnimate id={'animate5'}>
              <div className="serviceTableWrap01 add-animation" id="animate5">
                <div className="table_type2">
                  <table className="">
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>기초상품</th>
                        <th>음원</th>
                        <th>미술품</th>
                        <th>중소 상업용 빌딩</th>
                        <th className="pointBg">NPL 부동산(PLUTOS)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>자산보유자</td>
                        <td>작곡가, 저작권협회 등</td>
                        <td>미술관, 개인 등</td>
                        <td>강남 건물주</td>
                        <td className="pointBg">NPL 유동화회사, 대부업체 등</td>
                      </tr>
                      <tr>
                        <td>소싱</td>
                        <td>쉬움</td>
                        <td>어려움</td>
                        <td>어려움</td>
                        <td className="pointBg">쉬움</td>
                      </tr>
                      <tr>
                        <td>매입가액</td>
                        <td>기준가격의 판단 어려움</td>
                        <td>비쌈</td>
                        <td>비쌈</td>
                        <td className="pointBg">
                          저렴함
                          <span className="smallTxt01">
                            (채권매입 단계에서부터 할인매입 가능, 채권자 지위를 활용한 저가 낙찰 가능)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>투자기간</td>
                        <td>
                          변동성 심함<span className="smallTxt01">(유행에 따른 영향)</span>
                        </td>
                        <td>장기</td>
                        <td>
                          2~3년<span className="smallTxt01">(장기 5년)</span>
                        </td>
                        <td className="pointBg">2년이내</td>
                      </tr>
                      <tr>
                        <td>회수수익</td>
                        <td>
                          변동성 심함<span className="smallTxt01">(유행에 따른 영향)</span>
                        </td>
                        <td>
                          보통<span className="smallTxt01">(매각차익 큰 편이지만, 유동 마진 높음)</span>
                        </td>
                        <td>낮음</td>
                        <td className="pointBg">
                          큼
                          <span className="smallTxt01">
                            (보수적으로 감정가 80%에 낙찰, value add 없이 100%에 재매각 하더라도 20% 수익발생)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>운영 및 매각 리스크</td>
                        <td>유행 곡 쏠림 현상, 재매각 구조가 아니므로 청산 애매함</td>
                        <td>도난, 위작, 파손 등 타 상품대비 사고 위험성 높음</td>
                        <td>
                          공실 발생시 운영수익 타격 큼. 금액이 커서 매수인 찾기가 쉽지 않음
                          <span className="smallTxt01">(특히 불황기 exit 어려움)</span>
                        </td>
                        <td className="pointBg">
                          아파트 비롯 상대적으로 작은 규모의 물건이 대부분으로 재매각 어렵지 않음. 매입원가 낮으므로
                          시장가액보다 할인 등 exit 방안 다양
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </WaypointToAnimate>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlutosIntroduction;
