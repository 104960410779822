import PLImage from '@components/common/PLImage';
import PageInfo from '@components/list/PageInfo';
import Pagination from '@components/list/Pagination';
import SearchForm from '@components/list/SearchForm';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getConnectStatus, getDateFormat } from '@utils/util';
import { now } from 'moment';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EventList = () => {
  const path = useLocation().pathname;
  const { routePath, status } = getConnectStatus(path);

  const [eventOption, setEventOption] = useState({
    searchType1: 'title',
    searchKeyword: '',
    pageSize: 6,
    page: 1,
  });

  const [eventInfo, setEventInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  function handleEventOption(name: string, value: string) {
    let copyEventOpt = { ...eventOption };
    if (name === 'searchKeyword') {
      copyEventOpt.page = 1;
    }

    setEventOption({
      ...copyEventOpt,
      [name]: value,
    });
  }

  function eventChk(endde: number) {
    if (Date.now() < endde) {
      return <div className="eventStatus eventIng">진행중</div>;
    } else {
      return <div className="eventStatus eventEnd">마감</div>;
    }
  }

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.event, { params: eventOption });

      if (data.code === '200') {
        setEventInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchList();
  }, [eventOption]);

  return (
    <>
      <div className="sectionCon">
        <SearchForm placeholder="이벤트명을 검색해 주세요" onClick={handleEventOption} />
        <PageInfo totalCount={eventInfo.totalCount} />
        <div className="board_list gallery_list">
          <ul className="list_ul">
            {Boolean(eventInfo.list.length) &&
              eventInfo.list.map((info, index) => (
                <li className="list_li" key={`${info.stncnSn}_${index}`}>
                  <Link className="item" to={`${routePath.event_detail}/${info.sntncSn}`}>
                    <span className="thumb">
                      <PLImage imgSrc={status === 'web' ? info.webThumb : info.appThumb} alt={'이벤트 이미지'} />
                    </span>
                    <div className="txt">
                      {eventChk(info.eventEndde)}
                      <strong className="title">{info.sntncSj}</strong>
                      <div className="etc_info">
                        <span className="date">
                          <em className="sr-only">기간</em>
                          <span className="num">
                            {getDateFormat(info.eventBgnde, 'YYYY-MM-DD')} ~{' '}
                            {getDateFormat(info.eventEndde, 'YYYY-MM-DD')}
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <Pagination
          page={eventOption.page}
          totalCount={eventInfo.totalCount}
          onClick={handleEventOption}
          pageSize={6}
        />
      </div>
    </>
  );
};

export default EventList;
