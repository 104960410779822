import { getDateFormat, regPrice } from '@utils/util';
import { GoodsItemIF } from 'interface/goodsInterface';
import React from 'react';

interface GoodsTitleProps {
  data: GoodsItemIF;
}

/**
 * 상품 상단정보
 * @param param0
 * @returns
 */
const GoodsTitle = ({ data }: GoodsTitleProps) => {
  function StatusByItem() {
    switch (data.sttusSe) {
      case '청약':
        return (
          <p>
            <span>청약기간</span>
            {getDateFormat(data.subscrptpdBegin, 'YY.MM.DD')} ~ {getDateFormat(data.subscrptpdEnd, 'YY.MM.DD')}
          </p>
        );

      case '상장':
        return (
          <p className="listed_product01">
            <span>{regPrice(data.tknPc)}원</span>
            <em className={data.tknCurprcse === 'up' ? 'upIndi' : 'downIndi'}>{data.tknCurprcseValue}%</em>
          </p>
        );
      case '매각':
        return (
          <p>
            <span>누적수익율</span>
            {data.saleinfoAccmlternrt}%
          </p>
        );
      default:
        return <></>;
    }
  }

  function statusByClassName() {
    let res = 'subscription_product';

    if (data.sttusSe === '상장') {
      res = 'listed_product';
    } else if (data.sttusSe === '매각') {
      res = 'sell_product';
    }

    return res;
  }

  return (
    <div className="txtCont">
      <p className={`product_category ${statusByClassName()}`}>{data.sttusSe}</p>
      <div className="tit">{data.goodsNm}</div>
      {/* <p className="txt">{data.goodsAdres}</p> */}
      <div className="txtCont_info02">
        <StatusByItem />
      </div>
    </div>
  );
};

export default GoodsTitle;
