import PrepareService from '@components/common/PrepareService';

const MyInvest = () => {
  return (
    <>
      <PrepareService />
    </>
  );
};
export default MyInvest;
