import PageInfo from '@components/list/PageInfo';
import Pagination from '@components/list/Pagination';
import SearchForm from '@components/list/SearchForm';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getValue } from '@testing-library/user-event/dist/utils';
import { getConnectStatus, getDateFormat } from '@utils/util';
import { GoodsItemIF } from 'interface/goodsInterface';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

interface AnnoListProps {
  goodsSn?: number;
}

interface AnnoItem {
  sntncSn: number;
  goodsSn: number;
  imprtncat: number;
  goodsNm: string;
  sntncSj: string;
  sntncCn: string;
  frstCreatDt: number;
  lastUpdtDt: number;
}

/**
 * 공시 리스트
 * @param param0 -
 * @returns
 */
const AnnoList = ({ goodsSn }: AnnoListProps) => {
  const path = useLocation().pathname;
  const { id } = useParams();

  const navigate = useNavigate();
  const { routePath, status } = getConnectStatus(path);
  const [searchGoodsNm, setSearchGoodsNm] = useState<string>();

  /* 0 - 배당 ,  1 - 발행 ,  2 - 청약 */
  const [annoOption, setAnnoOption] = useState({
    searchKeyword: goodsSn ? goodsSn : '',
    page: 1,
  });

  const [annoSearchGoodsOption, setAnnoSearchGoodsOption] = useState({
    searchKeyword: '',
  });

  const [annoGoodsOption, setAnnoGoodsOption] = useState({
    detailKey: id,
    searchType1: 0,
    page: 1,
  });

  function handleAnnoSearchGoodsOption(name: string, value: string) {
    setAnnoSearchGoodsOption({
      ...annoSearchGoodsOption,
      [name]: value,
    });
  }

  function handleAnnoOption(name: string, value: string) {
    let copyAnnoOpt = { ...annoOption };

    setAnnoOption({
      ...annoOption,
      [name]: value,
    });

    copyAnnoOpt.page = 1;
    setAnnoOption({
      ...copyAnnoOpt,
      [name]: value,
    });
  }

  function handleAnnoGoodsOption(name: string, value: string) {
    setAnnoGoodsOption({
      ...annoGoodsOption,
      [name]: value,
    });
  }

  const [annoInfo, setAnnoInfo] = useState<{ list: Array<AnnoItem>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  const [annoSearchGoodsInfo, setAnnoSearchGoodsInfo] = useState<{ list: Array<GoodsItemIF> }>({
    list: [],
  });

  const activeEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleAnnoSearchGoodsOption('searchKeyword', annoSearchGoodsOption.searchKeyword);
      fetchSearchGoodsList();
    }
  };

  function getImprtncat(imprtncat: number) {
    let res = '배당';
    if (imprtncat === 0) {
      res = '배당';
    } else if (imprtncat === 1) {
      res = '발행';
    } else {
      res = '청약';
    }
    return res;
  }

  function detailType() {
    if (annoOption.searchKeyword === '') {
      return 'all';
    } else if (path === '/anno') {
      return 'detail';
    } else {
      return 'appdetail';
    }
  }

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.anno, { params: annoOption });

      if (data.code === '200') {
        setAnnoInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAppList() {
    try {
      const { data } = await Axios.get(API.anno_goods, { params: annoGoodsOption });

      if (data.code === '200') {
        setAnnoInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSearchGoodsList() {
    try {
      const { data } = await Axios.get(API.anno_searchgoods, { params: annoSearchGoodsOption });

      if (data.code === '200') {
        setAnnoSearchGoodsInfo({
          list: data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (path === '/anno') {
      fetchList();
      fetchSearchGoodsList();
    }
  }, [annoOption]);

  useEffect(() => {
    if (path !== '/anno') {
      fetchAppList();
    }
  }, [annoGoodsOption]);

  return (
    <>
      <div className="sectionCon">
        {/* web , app 구분필요 */}
        {path === '/anno' ? (
          <>
            <div className="srch-area">
              <div className="form_area form_product_select">
                <div className="selects">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      handleAnnoOption('searchKeyword', e.target.value);
                      setSearchGoodsNm(e.target.options[e.target.selectedIndex].text);
                    }}
                  >
                    <option value="" selected>
                      투자상품 전체 ({annoSearchGoodsInfo.list.length})
                    </option>

                    {Boolean(annoSearchGoodsInfo.list.length) &&
                      annoSearchGoodsInfo.list.map((info, index) => (
                        <option value={info.goodsSn} key={index}>
                          {info.goodsNm}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="form_area form_input">
                <div className="input_txtArea">
                  <input
                    type="text"
                    name="searchText"
                    className="form-control"
                    placeholder="투자 상품을 검색하세요"
                    onChange={(e) => handleAnnoSearchGoodsOption('searchKeyword', e.target.value)}
                    onKeyDown={(e) => activeEnter(e)}
                  />
                  <button className="btn-srch" onClick={() => fetchSearchGoodsList()}></button>
                </div>
              </div>
            </div>

            <div className="list-info">
              <p className="txt">
                {annoOption.searchKeyword === '' ? (
                  <span className="selectTxt"> 투자상품 전체</span>
                ) : (
                  <span className="selectTxt"> {searchGoodsNm}</span>
                )}
              </p>
            </div>

            <div className="selectTab">
              <ul className="selectTabArea">
                <li className="active">
                  <a style={{ cursor: 'pointer' }}>
                    <dl>
                      <dt>공시</dt>
                      <dd>{annoInfo.totalCount}</dd>
                    </dl>
                  </a>
                </li>
                <li className="">
                  <a onClick={() => alert('서비스 준비 중입니다.')} style={{ cursor: 'pointer' }}>
                    <dl>
                      <dt>기준가</dt>
                      <dd>-</dd>
                    </dl>
                  </a>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <div className="srch-area">
            <div className="form_area form_input">
              <div className="selects">
                <select
                  className="form-control"
                  // value={annoGoodsOption.searchType1}
                  onChange={(e) => handleAnnoGoodsOption('searchType1', e.target.value)}
                >
                  <option value="0">배당</option>
                  <option value="1">발행</option>
                  <option value="2">청약</option>
                  <option value="3">기타</option>
                </select>
              </div>
            </div>
          </div>
        )}

        <PageInfo totalCount={annoInfo.totalCount} />

        <div className="board_list">
          <ul className="list_ul">
            {annoInfo.list.map((anno, index) => (
              <li className="list_li" key={index}>
                <Link
                  className="item type0"
                  to={`${routePath.anno_detail}/${anno.sntncSn}`}
                  state={{ sttus: detailType(), state: id }}
                >
                  <div className="txt">
                    <strong className="title">{anno.sntncSj}</strong>
                    <div className="etc_info">
                      <span className="product">
                        <em className="sr-only">상품</em>
                        <span>{anno.goodsNm}</span>
                      </span>
                      <span className="category">
                        <em className="sr-only">분류</em>
                        <span>{getImprtncat(anno.imprtncat)}</span>
                      </span>
                      <span className="date">
                        <em className="sr-only">작성일</em>
                        <span>{getDateFormat(anno.frstCreatDt, 'YYYY.MM.DD')}</span>
                      </span>
                    </div>
                  </div>
                  <div className="btn_wrap">
                    <i className="icon_more">
                      <span className="sr-only">상세보기</span>
                    </i>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Pagination page={annoOption.page} totalCount={annoInfo.totalCount} onClick={handleAnnoOption} pageSize={10} />
      </div>
    </>
  );
};
export default AnnoList;
