import React from 'react';
import WebPlutosIntroduction from '@pages/web_static/PlutosIntroduction';

const PlutosIntroduction = () => {
  return (
    <>
      <div className="sectionCon">
        <div className="cont_box_wrap">
          <h3 className="sec_tit">NPL부동산 투자의 대중화</h3>

          <div className="cont_box">
            <ul>
              <li className="serviceIco01">
                <dl>
                  <dt>Easy NPL</dt>
                  <dd>
                    높은 진입장벽으로 전문투자가들의 영역이었던 NPL을 누구나, 전문지식이 없어도, 투자금이 적어도 NPL
                    자산에 투자 기회 제공!
                  </dd>
                </dl>
              </li>
              <li className="serviceIco02">
                <dl>
                  <dt>Easy Investment</dt>
                  <dd>
                    복잡한 절차 없이 쇼핑하듯 원하는 상품에 투자하고, 주식처럼 편리한 거래!{' '}
                    <p>전문업체가 운영, 관리하여 수익 창출</p>
                  </dd>
                </dl>
              </li>
              <li className="serviceIco03">
                <dl>
                  <dt>Safe & Smart</dt>
                  <dd>
                    블록체인 분산원장 기술을 활용하여 보다 더 안전하고 투명하게 운영!{' '}
                    <p>시간과 장소에 구애 받지 않고 스마트한 투자 라이프</p>
                  </dd>
                </dl>
              </li>
              <li className="serviceIco04">
                <dl>
                  <dt>Good Together</dt>
                  <dd>
                    플루토스는 투자상품 조달자, 운영자, 투자자가 모두 함께 만족할 수 있는 투자구조를 통해 굳건하면서
                    지속적인 성장이 가능
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
        <br></br>
      </div>
      <WebPlutosIntroduction />
    </>
  );
};

export default PlutosIntroduction;
