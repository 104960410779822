import axios, { AxiosInstance } from 'axios';
import { ENV } from '@constants/envPath';

export const Axios: AxiosInstance = axios.create({
  baseURL: ENV.API_URL,
});

export const AxiosInterCeptorRequest = Axios.interceptors.request.use((config) => {
  if (!config.headers!.Authorization) {
    config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return config;
});
