import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Analytics() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scriptWcslog = document.createElement('script');
    scriptWcslog.src = '//wcs.naver.net/wcslog.js';
    scriptWcslog.async = true;

    const scriptWcsFunction = document.createElement('script');

    let path = pathname.split('/')[1];
    if (path === 'app') {
      scriptWcsFunction.text = `
      if(!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "1cd8c37de23fdd0";
      if(window.wcs) {
      wcs_do();
      }
     `;
    } else {
      scriptWcsFunction.text = `
      if(!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "198a54b83e72970";
      if(window.wcs) {
      wcs_do();
      }
       `;
    }

    scriptWcsFunction.async = true;

    document.body.appendChild(scriptWcslog);
    document.body.appendChild(scriptWcsFunction);
    return () => {
      document.body.removeChild(scriptWcslog);
      document.body.removeChild(scriptWcsFunction);
    };
  }, [pathname]);

  return null;
}
