import WaypointToAnimate from '@components/common/WaypointToAnimate';

const StoGuide = () => {
  return (
    <>
      <div className="sectionCon">
        <div className="productCon01">
          <h4 className="subTxt01">
            분산원장 기술을 활용해 자본시장법상 증권을 디지털화한 것을 의미합니다.
            <span>블록체인 기술을 활용해 토큰 형태로 발행되는 증권으로 디지털 자산의 장점을 함께 가지며 </span>
            <span>부동산, 미술품, 저작권 등 다양한 자산에 대한 소액 투자를 가능하게 하는 기본 개념</span>
          </h4>
          <ul className="serviceList">
            <li>
              <span className="icon_09">블록체인 및 스마트 계약 기술을 활용하여 탈중앙화, 분산화</span>
            </li>
            <li>
              <span className="icon_10">거래내역이 투명하며 실물 증서가 없더라도 신뢰, 안정성 보장</span>
            </li>
            <li>
              <span className="icon_11">언제든지 거래 가능하며 비용 감소</span>
            </li>
            <li>
              <span className="icon_12">주식, 채권, 수익증권, 투자계약증권 등 모든 형태의 증권 토큰화 가능</span>
            </li>
            <li>
              <span className="icon_13">
                실물자산을 기초자산으로 발행, 해당 기초 자산에 대해 운영수익, 처분수익 등 수취 가능
              </span>
            </li>
            <li>
              <span className="icon_14">
                금융당국의 규제하에 있으며, 증권으로써 자본시장법과 각종 법률 등 투자자의 법적 권리 보호 가능
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="sectionCon">
        <h3 className="title_sub">
          <span>STO(Security Token Offering)</span>
        </h3>

        <WaypointToAnimate id={'animate1'}>
          <div className="serviceTableWrap add-animation" id="animate1">
            <div className="table_type2">
              <table className="">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>IPO</th>
                    <th className="pointBg">STO</th>
                    <th>ICO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>투자대상</td>
                    <td>실제 비즈니스 모델</td>
                    <td className="pointBg">실제 비즈니스 모델 또는 실물자산</td>
                    <td>블록체인 기반 프로젝트 및 아이디어</td>
                  </tr>
                  <tr>
                    <td>투자자금 형태</td>
                    <td>법정통화</td>
                    <td className="pointBg">법정통화 및 가상화폐</td>
                    <td>가상화폐</td>
                  </tr>
                  <tr>
                    <td>런칭 프로세스</td>
                    <td>IPO전 많은 의무 이행, 금융당국 승인필요</td>
                    <td className="pointBg">증권법 및 관련법 준수, 금융당국 승인필요</td>
                    <td>금융당국 승인 및 규정 부재</td>
                  </tr>
                  <tr>
                    <td>발행인</td>
                    <td>명확</td>
                    <td className="pointBg">명확</td>
                    <td>불명확</td>
                  </tr>
                  <tr>
                    <td>비용</td>
                    <td>높음</td>
                    <td className="pointBg">낮음</td>
                    <td>매우 낮음</td>
                  </tr>
                  <tr>
                    <td>법적보호</td>
                    <td>적용</td>
                    <td className="pointBg">적용</td>
                    <td>미적용</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </WaypointToAnimate>

        <WaypointToAnimate id={'animate1'}>
          <ul className="serviceList add-animation" id="animate1">
            <li>
              <span className="icon_15">
                주식의 IPO(Initial Public Offering), 가상화폐의 ICO(Initial Coin Offering)와 상응하는 개념
              </span>
            </li>
            <li>
              <span className="icon_16">IPO보다 자금 모집이 수월한 동시에 ICO와 달리 법적 보호 가능함</span>
            </li>
            <li>
              <span className="icon_17">
                플루토스는 기초자산인 NPL 수익증권의 확보를 위하여 STO 진행함. 투자자는 청약을 통하여 해당 STO에 참여할
                수 있으며, 이후 증권사의 중개로써 거래 가능함
              </span>
            </li>
          </ul>
        </WaypointToAnimate>
      </div>
    </>
  );
};
export default StoGuide;
