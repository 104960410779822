import React, { useEffect, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { calculatePercentage, getConnectStatus, getDateFormat, hyphenToComma, regPrice } from '@utils/util';
import MainContentSwiper from '@components/swiper/MainContentSwiper';
import WebFooter from '@components/footer/WebFooter';
import MainGoodsSwiper from '@components/swiper/MainGoodsSwiper';
import { mainContent } from '@utils/jqueryUtil';
import ReactPlayer from '@components/common/ReactPlayer';

interface MainInfo {
  event: Array<any>;
  goods: Array<any>;
  news: Array<any>;
}
/* 메인페이지 */
const WebMain = () => {
  const myElementRef = useRef(null);

  const path = useLocation().pathname;
  const { routePath, status } = getConnectStatus(path);
  const [mainInfo, setMainInfo] = useState<MainInfo>({
    event: [],
    goods: [],
    news: [],
  });

  async function fetchMain() {
    try {
      const { data } = await Axios.get(API.web_main);
      if (data.code === '200' && data.data) {
        setMainInfo({
          ...data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchMain();

    /* loadExternalJS 에서 main.js 로드시 적용안되는 이슈때문에 effect 에 추가 */
    const scriptJquery2 = document.createElement('script');
    scriptJquery2.src = '/js/web/main.js';
    scriptJquery2.async = true;

    const handleJSLoaded = () => {
      setTimeout(() => {
        mainContent();
      }, 1000);
    };

    scriptJquery2.addEventListener('load', handleJSLoaded);
    // document.body.appendChild(scriptJquery1);
    document.body.appendChild(scriptJquery2);

    // if (myElementRef.current) {
    // setTimeout(() => {
    //   mainContent();
    // }, 2000);
    // }

    return () => {
      // document.body.removeChild(scriptJquery1);
      document.body.removeChild(scriptJquery2);
      scriptJquery2.removeEventListener('load', handleJSLoaded);
    };
  }, []);

  return (
    <div id="content">
      <div id="fullpageArea">
        <section className="section sec_one" style={{ display: 'table' }}>
          <div className="sec_one_left swiper-container">
            <div className="txt_wrap">
              <p className="slogan motion">
                국내 유일의 <span>NPL 전문 토큰 증권</span> <span>투자 플랫폼 PLUTOS</span>
              </p>
              <div className="txt_img motion">
                누구나, 소액으로 국내 다양한 부동산에 투자하고 <span>나만의 투자 포트폴리오를 만들어 보세요!</span>
              </div>
              <div className="txt_down motion">APP DOWNLOAD</div>

              <div className="a_img">
                {/* <a href="#" className="appStore" onClick={() => alert('서비스 준비 중입니다.')}>
                  <span>App Store</span>
                </a> */}
                <a
                  href="https://play.google.com/store/apps/details?id=kr.co.plutos.PlutospartnersAPP&hl=ko-KR"
                  className="googlePlay"
                  target="__blank"
                  // onClick={() => alert('서비스 준비 중입니다.')}
                >
                  <span>Google Play</span>
                </a>
              </div>
            </div>
            <div className="visual_wrap swiper-wrapper">
              <div className="visual swiper-slide">
                <div
                  className="bg_area"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual01.png')}) 0 center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>

                <div
                  className="m_bgArea"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual01_m.png')}) center center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
              <div className="visual swiper-slide">
                <div
                  className="bg_area"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual02.png')}) 0 center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div
                  className="m_bgArea"
                  style={{
                    background: `url(${require('@assets/img_app/web/mainVisual02_m.png')}) center center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
            </div>

            <div className="s_new_area">
              <div className="rolling_area">
                {Boolean(mainInfo.event.length) &&
                  mainInfo.event.map((item, index) => (
                    <a
                      href={`${PATH.event_detail}/${item.sntncSn}`}
                      className="roll_div"
                      style={{ top: index ? '100%' : '0' }}
                      key={item.sntncSn}
                    >
                      <p className="txt1">
                        <span>이벤트</span>
                      </p>
                      <p className="txt2"> {item.sntncSj}</p>
                    </a>
                  ))}
              </div>

              <div className="controller">
                <p className="bar">
                  <span></span>
                </p>
                <a href="javascript:">
                  <span className="stop"></span>
                  <span className="play"></span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section sec_three" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain">What is PLUTOS?</h3>
            <div className="investmentCon">
              <h4>NPL부동산 투자의 대중화</h4>
              <ul>
                <li className="investment01">
                  <dl>
                    <dt>누구나</dt>
                    <dd>#전문지식이 없어도 #투자금액이 적어도</dd>
                  </dl>
                </li>
                <li className="investment02">
                  <dl>
                    <dt>안전하고</dt>
                    <dd>#블록체인 #철통보안</dd>
                  </dl>
                </li>
                <li className="investment03">
                  <dl>
                    <dt>간편하게</dt>
                    <dd>#언제든지 #심플하게 #쇼핑하듯</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section sec_three" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain">플루투스 소개 영상</h3>
            <div className="investmentCon">
              <ReactPlayer isControl={true} height={'100%'} />
            </div>
          </div>
        </section>

        <section className="section sec_two" style={{ display: 'table' }} ref={myElementRef}>
          <div className="sales_bg">
            <div
              className="bg_default"
              style={{
                background: `center bottom no-repeat`,
                backgroundSize: 'cover',
                backgroundImage: `url(${require('@assets/img_app/web/investment_img/inverst_default.png')}) `,
              }}
            ></div>
            {Boolean(mainInfo.goods.length) &&
              mainInfo.goods.map((item) => (
                <div
                  key={item.goodsSn}
                  className="bg"
                  style={{
                    background: `center center no-repeat`,
                    backgroundSize: 'cover',
                    backgroundImage: `url(${item.webThumb}) `,
                  }}
                ></div>
              ))}
          </div>

          {Boolean(mainInfo.goods.length) && (
            <MainGoodsSwiper swiperClassName="sales_list_wrap">
              {mainInfo.goods.map((item) => (
                <SwiperSlide className="sales" key={item.goodsSn}>
                  <div className="dimd_bg"></div>
                  <div className="txt_area" style={{ height: '100%' }}>
                    <div className="info_text1">
                      <p className="txt1">
                        <span>{item.sttusSe}</span>
                      </p>
                      <p className="txt2">{item.goodsNm}</p>
                      <p className="txt3">{item.goodsAdres}</p>
                    </div>

                    <div className="info_text2">
                      <ul className="before">
                        <li>
                          <p className="tit">상장일</p>
                          <p className="txt">
                            <span>{hyphenToComma(item.lston)}</span>
                          </p>
                        </li>
                        <li>
                          <p className="tit">감정가</p>
                          <p className="txt">
                            <span>{regPrice(item.invtinfoApprslamount)}</span>
                          </p>
                        </li>
                        <li>
                          <p className="tit">공모가</p>
                          <p className="txt">
                            <span>{regPrice(item.pssrpc)}</span>
                          </p>
                        </li>
                        <li>
                          <p className="tit">총 TO 수량</p>
                          <p className="txt">
                            <span>{regPrice(item.pssrpqy)} TO</span>
                          </p>
                        </li>
                        <li>
                          <p className="tit">공모가/감정가</p>
                          <p className="txt">
                            <span>{calculatePercentage(Number(item.pssrpc), Number(item.invtinfoApprslamount))}</span>
                          </p>
                        </li>
                      </ul>

                      <div className="bts">
                        <a href={`${routePath.goods_detail}/${item.goodsSn}`} className="mainViewBtn">
                          <span>자세히 보기</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </MainGoodsSwiper>
          )}

          {/* <div className="swiper-container">
            <div className="swiper-wrapper">
              {Boolean(mainInfo.goods.length) &&
                mainInfo.goods.map((item) => (
                  <div className="sales swiper-slide" key={item.goodsSn}>
                    <div className="dimd_bg"></div>
                    <div className="txt_area">
                      <div className="info_text1">
                        <p className="txt1">
                          <span>{item.sttusSe}</span>
                        </p>
                        <p className="txt2">{item.goodsNm}</p>
                        <p className="txt3">{item.goodsAdres}</p>
                      </div>

                      <div className="info_text2">
                        <ul className="before">
                          <li>
                            <p className="tit">상장일</p>
                            <p className="txt">
                              <span>{hyphenToComma(item.lston)}</span>
                            </p>
                          </li>
                          <li>
                            <p className="tit">감정가</p>
                            <p className="txt">
                              <span>{regPrice(item.invtinfoApprslamount)}</span>
                            </p>
                          </li>
                          <li>
                            <p className="tit">공모가</p>
                            <p className="txt">
                              <span>{regPrice(item.pssrpc)}</span>
                            </p>
                          </li>
                          <li>
                            <p className="tit">총 TO 수량</p>
                            <p className="txt">
                              <span>{regPrice(item.pssrpqy)} TO</span>
                            </p>
                          </li>
                          <li>
                            <p className="tit">공모가/감정가</p>
                            <p className="txt">
                              <span>{calculatePercentage(Number(item.pssrpc), Number(item.invtinfoApprslamount))}</span>
                            </p>
                          </li>
                        </ul>

                        <div className="bts">
                          <a href={`${routePath.goods_detail}/${item.goodsSn}`} className="mainViewBtn">
                            <span>자세히 보기</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div> */}
        </section>
        <section className="section sec_four" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain text-left">플루토스 서비스</h3>
            <MainContentSwiper swiperClassName="serviceWrap">
              <SwiperSlide className="servicelist swiper-slide">
                <div className="serviceCon">
                  <div className="serviceTxt">
                    <h3>01</h3>
                    <h2>Easy NPL</h2>
                    <p>
                      높은 진입장벽으로 인하여 전문투자가들의 영역이었던 NPL을 누구나, 전문지식이 없어도, 투자금이 많지
                      않아도 NPL 자산에 투자 기회를 제공
                    </p>
                  </div>
                  <div className="serviceImg">
                    <div className="service01"></div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="servicelist swiper-slide">
                <div className="serviceCon">
                  <div className="serviceTxt">
                    <h3>02</h3>
                    <h2>Easy Investment</h2>
                    <p>
                      복잡한 절차 없이 쇼핑하듯 편리하게 거래할 수 있으며 투자자의 부동산은 전문업체가 운영, 관리하여
                      수익 창출
                    </p>
                  </div>
                  <div className="serviceImg">
                    <div className="service02"></div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="servicelist swiper-slide">
                <div className="serviceCon">
                  <div className="serviceTxt">
                    <h3>03</h3>
                    <h2>Safe & Smart</h2>
                    <p>블록체인 분산원장 기술을 활용하여 안전하고 시간과 장소에 구애 받지 않는 스마트한 투자 라이프!</p>
                  </div>
                  <div className="serviceImg">
                    <div className="service03"></div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="servicelist swiper-slide">
                <div className="serviceCon">
                  <div className="serviceTxt">
                    <h3>04</h3>
                    <h2>Good Together</h2>
                    <p>
                      플루토스는 투자상품 조달자, 운영자, 투자자가 모두 함께 만족할 수 있는 투자구조를 통해 굳건하면서
                      지속적인 성장 가능
                    </p>
                  </div>
                  <div className="serviceImg">
                    <div className="service04"></div>
                  </div>
                </div>
              </SwiperSlide>
            </MainContentSwiper>
          </div>
          {/* <div className="serviceWrap swiper-container" id="serviceWrap"> */}
          {/* </div> */}
        </section>

        <section className="section sec_five bg_gray" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            <h3 className="titleMain text-left">미디어가 전하는 PLUTOS</h3>
            {Boolean(mainInfo.news.length) && (
              <MainContentSwiper swiperClassName="pressCon" isScroll={true}>
                {mainInfo.news.map((item) => (
                  <SwiperSlide className="pressList swiper-slide" key={item.sntncSn}>
                    <a href={item.linkUrl} target="__blank">
                      <div className="pressListCon">
                        <h5>보도자료</h5>
                        <h3>{item.sntncSj}</h3>
                        <p className="pressTxt"></p>
                        <p className="pressDate">{getDateFormat(item.frstCreatDt, 'YYYY-MM-DD')}</p>
                      </div>
                    </a>
                  </SwiperSlide>
                ))}
              </MainContentSwiper>
            )}

            {/* <div className="pressCon swiper-container" id="pressCon">
              <div className="swiper-wrapper">
                {Boolean(mainInfo.news.length) &&
                  
              </div>
              <div className="swiper-scrollbar"></div>
            </div> */}
          </div>
        </section>

        <section className="section sec_six" style={{ display: 'table' }}>
          <div className="mainSectionCon">
            {/* <h3 className="titleMain text-left">PARTNERS</h3> */}
            <h3 className="titleMain text-center">PARTNERS</h3>
            <div className="partnerWrap">
              <div className="partnerlistDiv">
                <ul>
                  {/* <p className="partnerTitle">신탁기관</p> */}
                  <li style={{ width: 265 }}>
                    <a href="http://www.shinyoungret.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-1-1.png')} alt="shinyoungret" />
                    </a>
                  </li>
                  <li style={{ width: 265 }}>
                    <a href="https://www.nhqv.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-2-1.png')} alt="nhqv" />
                    </a>
                  </li>
                  <li style={{ width: 265 }}>
                    <a href="https://www.parametacorp.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-2-2.png')} alt="parametacorp" />
                    </a>
                  </li>
                  <li style={{ width: 265 }}>
                    <a
                      href="javascript:"
                      onClick={() => alert('페이지 준비 중입니다.')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={require('@assets/img_app/web/partner/partner-3-3.png')} alt="partner" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="partnerlistDiv">
                <ul>
                  {/* <p className="partnerTitle">계좌관리기관 및 ST발행</p> */}
                  {/* <li>
                    <a href="https://www.ebestsec.co.kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-2-3.png')} alt="ebestsec" />
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.wefunding.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-3-2.png')} alt="wefunding" />
                    </a>
                  </li>
                  <li>
                    <a href="https://v2.cocktailfunding.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-3-1.png')} alt="cocktailfunding" />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.yfund.co.kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-3-4.png')} alt="yfund" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.ipartnersam.co.kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-3-5.png')} alt="ipartnersam" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="partnerlistDiv">
                <ul>{/* <p className="partnerTitle">Deal Sourcing</p> */}</ul>
              </div>
              <div className="partnerlistDiv">
                <ul>
                  {/* <p className="partnerTitle">투자 및 전략</p> */}
                  <li>
                    <a
                      href="javascript:"
                      onClick={() => alert('페이지 준비 중입니다.')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={require('@assets/img_app/web/partner/partner-6-1.png')} alt="partner" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.korfin.kr/kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-6-2.png')} alt="korfin" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.dongguk.edu/main" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-6-3.png')} alt="dongguk" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:"
                      onClick={() => alert('페이지 준비 중입니다.')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={require('@assets/img_app/web/partner/partner-6-4.png')} alt="partner" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="partnerlistDiv">
                <ul>
                  {/* <p className="partnerTitle">감정평가</p> */}
                  <li>
                    <a href="http://isamchang.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-5-2.png')} alt="isamchang" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.jaa.co.kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-5-1.png')} alt="jaa" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.isangcpa.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-4-2.png')} alt="isangcpa" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.mjlaw.co.kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-4-1.png')} alt="mjlaw" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="partnerlistDiv">
                <ul>
                  <li>
                    <a
                      href="javascript:"
                      onClick={() => alert('페이지 준비 중입니다.')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={require('@assets/img_app/web/partner/partner-4-3.png')} alt="partner" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.knypat.com/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-7-1.png')} alt="knypat" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.yeilac.co.kr/main.php/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-8-3.png')} alt="socsoft" />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.socsoft.co.kr/" target="_blank" rel="noreferrer">
                      <img src={require('@assets/img_app/web/partner/partner-8-2.png')} alt="socsoft" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <WebFooter isMain={true} />
      </div>
    </div>
  );
};

export default WebMain;
