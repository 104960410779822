import { createContext, useContext, useEffect, useState } from 'react';

interface GlobalContextType {
  userProfile: UserProfile | undefined;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | undefined>>;
}

export interface UserProfile {
  userName: string;
  userPhone: string;
  joinDate: number;
}

const GlobalContext = createContext<GlobalContextType | null>(null);

const GlobalProvider = ({ children }: { children: JSX.Element }) => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  return <GlobalContext.Provider value={{ userProfile, setUserProfile }}>{children}</GlobalContext.Provider>;
};

function useUserProfile(): [UserProfile | undefined, React.Dispatch<React.SetStateAction<UserProfile | undefined>>] {
  const context = useContext(GlobalContext);
  const { userProfile, setUserProfile } = context!;
  return [userProfile, setUserProfile];
}

export { GlobalProvider, useUserProfile };
