export const API = {
  web_main: '/plutos/web',
  app_main: '/plutos/app',

  story: '/plutos/app/story',

  userinfo: '/plutos/app/userinfo',

  notice: '/plutos/board/notice',
  notice_detail: '/plutos/board/notice/detail',

  news: '/plutos/board/news',
  news_detail: '/plutos/board/news/detail',

  state: '/plutos/board/state',
  state_detail: '/plutos/board/state/detail',

  anno: '/plutos/board/anno',
  anno_goods: '/plutos/goods/anno',
  anno_detail: '/plutos/board/anno/detail',
  anno_searchgoods: '/plutos/board/anno/goods',

  event: '/plutos/board/event',
  event_detail: '/plutos/board/event/detail',

  goods: '/plutos/goods/info',
  goods_detail: '/plutos/goods/detail',

  query: '/plutos/app/query',
  add_query: '/plutos/app/query/add',
  delete_query: '/plutos/app/query/delete',

  splash: '/plutos/app/main/splash',

  withdraw: '/plutos/app/withdraw',
};
