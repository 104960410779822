import { ENV } from '@constants/envPath';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

interface KaKaoMapProps {
  address: string;
}

const KaKaoMap = ({ address }: KaKaoMapProps) => {
  const [mapData, setMapData] = useState({
    x: 0,
    y: 0,
    name: '',
  });

  async function fetchMapApi() {
    try {
      const { status, data } = await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${address}`, {
        headers: { Authorization: `KakaoAK ${ENV.KAKAO_REST_API_KEY}` },
      });
      if (status === 200 && data) {
        if (data?.documents.length) {
          setMapData({
            x: data.documents[0].x,
            y: data.documents[0].y,
            name: data.documents[0].address_name,
          });
        }
      }
    } catch (error) {
      console.log('error');
    }
  }

  useEffect(() => {
    fetchMapApi();
  }, [address]);

  return (
    <>
      {mapData.x && mapData.y ? (
        <Map
          center={{
            // 지도의 중심좌표
            lng: mapData.x,
            lat: mapData.y,
            // lat: 33.5563,
            // lng: 126.79581,
          }}
          style={{
            // 지도의 크기
            width: '100%',
            height: '450px',
          }}
          level={3} // 지도의 확대 레벨
        >
          <MapMarker position={{ lng: mapData.x, lat: mapData.y }} />
        </Map>
      ) : (
        <div>주소가 잘못되었습니다.</div>
      )}
    </>
  );
};

export default KaKaoMap;
