import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation, Scrollbar } from 'swiper';
import PLImage from '@components/common/PLImage';

interface MainContentSwiperSwiperProps {
  // data: Array<any>;
  // id: string;
  swiperClassName: string;
  children: React.ReactNode;
  isScroll?: boolean;
}

const MainContentSwiper = ({ swiperClassName, children, isScroll }: MainContentSwiperSwiperProps) => {
  const serviceWrapSwiper = {
    loop: swiperClassName === 'splashContent' ? true : false,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  const pressConSwiper = {
    // slidesPerView: 3,
    // spaceBetween: 10,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  const swiperProps = isScroll ? { ...pressConSwiper } : { ...serviceWrapSwiper };

  return (
    <Swiper
      id={swiperClassName}
      grabCursor={true}
      modules={[Navigation, Pagination, Autoplay, Scrollbar]}
      {...swiperProps}
      className={`${swiperClassName} swiper-container swiper-container-horizontal`}
    >
      {children}
      <div className={isScroll ? 'swiper-scrollbar' : 'swiper-pagination'}></div>
    </Swiper>
  );
};

export default MainContentSwiper;
