import PageInfo from '@components/list/PageInfo';
import Pagination from '@components/list/Pagination';
import SearchForm from '@components/list/SearchForm';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getConnectStatus, getDateFormat } from '@utils/util';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NoticeList = () => {
  const path = useLocation().pathname;
  const { routePath, status } = getConnectStatus(path);
  const [noticeOption, setNoticeOption] = useState({
    searchType1: 'title',
    searchKeyword: '',
    page: 1,
  });

  const [noticeInfo, setNoticeInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  function handleNoticeOption(name: string, value: string) {
    let copyNoticeOpt = { ...noticeOption };
    if (name === 'searchKeyword') {
      copyNoticeOpt.page = 1;
    }

    setNoticeOption({
      ...copyNoticeOpt,
      [name]: value,
    });
  }

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.notice, { params: noticeOption });

      if (data.code === '200') {
        let arr: any = [...data.data.imtList, ...data.data.list];
        setNoticeInfo({
          list: arr,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchList();
  }, [noticeOption]);

  return (
    <>
      <div className="sectionCon">
        <SearchForm placeholder="제목을 입력해 주세요" onClick={handleNoticeOption} />

        <PageInfo totalCount={noticeInfo.totalCount} />

        <div className="board_list">
          <ul className="list_ul">
            {Boolean(noticeInfo.list.length) &&
              noticeInfo.list.map((info) => (
                <li className="list_li" key={info.sntncSn} style={{ backgroundColor: info.imprtncat ? '' : '#fcf8f8' }}>
                  <Link className="item type0" to={`${routePath.notice_detail}/${info.sntncSn}`}>
                    <div className="txt">
                      <strong className="title">{info.sntncSj}</strong>
                      <div className="etc_info">
                        <span className="date">
                          <em className="sr-only">작성일</em>
                          <span>{getDateFormat(info.frstCreatDt, 'YYYY-MM-DD')}</span>
                        </span>
                        {/* <span className="hit">
                              <em className="sr-only">조회수</em>
                              <i className="ri-eye-line" aria-hidden="true"></i>
                              <span>0</span>
                            </span> */}
                        <span className="hit">
                          <em className="sr-only">작성자</em>
                          <i className="ri-edit-line" aria-hidden="true"></i>
                          <span>관리자</span>
                        </span>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <i className="icon_more">
                        <span className="sr-only">상세보기</span>
                      </i>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Pagination
        page={noticeOption.page}
        totalCount={noticeInfo.totalCount}
        onClick={handleNoticeOption}
        pageSize={10}
      />
    </>
  );
};
export default NoticeList;
