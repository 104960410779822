import { PATH } from '@constants/path';
import { useUserProfile } from '@contexts/GlobalProvider';
import { MESSAGESTATE, sendRnMessage } from '@service/rnService';
import { confirmAlert } from '@utils/util';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { Axios } from '@service/axios';
import { API } from '@service/api';

interface UserInfoInterface {
  smsRecptnAt: string;
  emailRecptnAt: string;
}
interface ApiOptionInterface {
  userPhone: string | undefined;
  type: string;
  yn: string;
}

const UserInfo = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useUserProfile();
  const [apiOption, setApiOption] = useState<ApiOptionInterface>({
    userPhone: '',
    type: 'getUserInfo',
    yn: '',
  });
  // const [userRecptn, setUserRecptn] = useState<UserInfoInterface>({
  //   smsRecptnAt: '',
  //   emailRecptnAt: '',
  // });

  const menuList = [
    { name: 'notice', path: PATH.notice, title: '공지사항', option: 'Link' },
    // { name: 'news', path: PATH.news, title: '보도자료', option: 'Link' },
    { name: 'faq', path: PATH.faq, title: 'FAQ', option: 'Link' },
    { name: 'query', path: PATH.query, title: '1:1문의', option: 'Link' },
    { name: 'smsRecptnAt', path: '', title: '알림설정(SMS)', option: 'CheckBox' },
    { name: 'emailRecptnAt', path: '', title: '알림설정(E-mail)', option: 'CheckBox' },
    { name: 'terms', path: `${PATH.terms}/service`, title: '이용약관동의 내역', option: 'Link' },
    { name: 'terms', path: `${PATH.terms}/privacy`, title: '개인정보이용 처리방침', option: 'Link' },
    { name: 'terms', path: `${PATH.terms}/personal`, title: '개인정보 수집이용 및 활용동의', option: 'Link' },
    { name: 'terms', path: `${PATH.terms}/third`, title: '개인정보 제 3자 제공 동의', option: 'Link' },
    { name: 'terms', path: `${PATH.terms}/marketing`, title: '마케팅정보 수집 및 활용동의', option: 'Link' },
    { name: 'terms', path: `${PATH.terms}/identity`, title: '본인확인 서비스 이용약관', option: 'Link' },
    { name: 'terms', path: PATH.plutos_introduction, title: '플루토스 소개', option: 'Link' },
    { name: 'service_info', path: PATH.service_info, title: '서비스 정보', option: 'Link' },
    { name: 'withdraw', path: '', title: '회원탈퇴', option: 'Member' },
    { name: 'logout', path: '', title: '로그아웃', option: 'Member' },
  ];

  const [checkSettings, setCheckSettings] = useState<{
    smsRecptnAt: string;
    emailRecptnAt: string;
    [props: string]: any;
  }>({
    smsRecptnAt: '',
    emailRecptnAt: '',
  });

  function changeLoginState(type: string) {
    function logout() {
      localStorage.removeItem('token');
      setUserProfile(undefined);
      sendRnMessage(MESSAGESTATE.LOGOUT);
      sendRnMessage(MESSAGESTATE.LASTPAGE);
      window.location.replace(PATH.app_main_splash);
    }
    async function onWithdrawConfirm() {
      try {
        const res = await Axios.post(API.withdraw);
        if (res.data.code === '200') {
          alert('회원탈퇴를 완료하였습니다.');
          logout();
        } else {
          throw new Error();
        }
      } catch (error) {
        alert('회원탈퇴를 실패하였습니다.');
      }
    }
    function onLogoutConfirm() {
      logout();
    }
    if (type === 'withdraw') {
      confirmAlert('회원탈퇴를 하시겠습니까?', onWithdrawConfirm, () => {});
    }
    if (type === 'logout') {
      confirmAlert('로그아웃을 하시겠습니까?', onLogoutConfirm, () => {});
    }
  }

  function changeUserRecptn(type: string, yn: string) {
    if (yn === 'Y') {
      if (window.confirm(type === 'smsRecptnAt' ? 'SMS 알림을 해제하시겠습니까?' : 'E-mail 알림을 해제하시겠습니까?')) {
        yn = 'N';
      } else {
        return;
      }
    } else {
      if (window.confirm(type === 'smsRecptnAt' ? 'SMS 알림을 받으시겠습니까?' : 'E-mail 알림을 받으시겠습니까?')) {
        yn = 'Y';
      } else {
        return;
      }
    }

    setApiOption({
      ...apiOption,
      type: type,
      yn: yn,
    });

    setCheckSettings({
      ...checkSettings,
      [type]: yn,
    });
  }

  async function apiUserRecptn() {
    try {
      const res = await Axios.post(API.userinfo, apiOption, { params: apiOption });

      if (res.data.code === '200') {
        if (apiOption.type === 'getUserInfo') {
          setCheckSettings({
            smsRecptnAt: res.data.result.smsRecptnAt,
            emailRecptnAt: res.data.result.emailRecptnAt,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (apiOption.userPhone) {
      apiUserRecptn();
    }
  }, [apiOption]);

  useEffect(() => {
    setApiOption({ ...apiOption, userPhone: userProfile?.userPhone });
  }, [userProfile]);

  return (
    <>
      <div className="sectionCon">
        <div className="myInfo">
          <dl>
            <dt>
              <span>{userProfile?.userName}</span>님 환영합니다.
            </dt>
            <dd>{`가입일 ${Moment(userProfile?.joinDate).format('YYYY. MM. DD')}`}</dd>
          </dl>
        </div>

        <div className="moreMenu">
          <ul>
            {menuList.map((menu) => (
              <li>
                <dl>
                  {menu.option === 'CheckBox' ? (
                    <>
                      <dt>
                        <a>{menu.title}</a>
                      </dt>

                      <dd>
                        <input
                          type="checkbox"
                          id="toggle"
                          hidden
                          checked={checkSettings[menu.name] === 'Y' ? true : false}
                        />
                        <label
                          htmlFor="toggle"
                          className="toggleSwitch"
                          onClick={() => changeUserRecptn(menu.name, checkSettings[menu.name])}
                        >
                          <span className="toggleButton"></span>
                        </label>
                        {/* <input
                          type="checkbox"
                          className="js-switch"
                          checked={checkSettings[menu.name]}
                          onChange={() =>
                            setCheckSettings({ ...checkSettings, [menu.name]: !checkSettings[menu.name] })
                          }
                        /> */}
                      </dd>
                    </>
                  ) : menu.option === 'Member' ? (
                    <dt style={{ cursor: 'pointer' }}>
                      <a
                        onClick={() => {
                          changeLoginState(menu.name);
                        }}
                      >
                        {menu.title}
                      </a>
                    </dt>
                  ) : (
                    <dt>
                      <Link to={menu.path}>{menu.title}</Link>
                    </dt>
                  )}
                </dl>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
export default UserInfo;

// <!-- Switchery   http://abpetkov.github.io/switchery/ -->
// <script src="../resources/js/common/plugins/switchery/switchery.js"></script>
//  <script>
// 	$(document).ready(function(){
// 		var elem = document.querySelector('.js-switch');
// 		var switchery = new Switchery(elem, { color: '#d6001f' });
// 	});
// </script>
