export const TERMS_SERVICE = `
<p style="text-align:center;">
    <h3 style="text-align:center">제1 장 총칙</h3>
</p>
<p>
    <span style="color:black; font-weight:bold">제1조 (목적)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">본 약관은 신탁계약을 통해 발행된 신탁 수익증권의 공모 및 유통 등을 위하여 주식회사 플루토스파트너스(이하 “회사”라 한다)가 플루토스 플랫폼을 통하여 제공하는 모든 서비스(이하 “서비스”라 한다)를 이용함에 있어 회사와 회원 간의 권리∙의무 및 책임사항에 관한 사항을 규정함을 목적으로 합니다.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제2조 (용어의 정의)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다. 이 약관에서 사용하는 용어 중 이 조항에서 정하지 아니한 것은 관계법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 거래관행에 따릅니다.</span>
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">&nbsp;&nbsp;&nbsp;</span>
</p>
<ol>
    <li>
        회사: 온라인 및 모바일을 통해 본 서비스를 제공하는 사업자를 의미<br>
        &nbsp;
    </li>
    <li>
        모바일 앱: 이용자의 스마트폰에 설치하여 플루토스 플랫폼을 통하여 제공되는 서비스를 이용할 수 있는 플루토스 플랫폼 모바일 어플리케이션을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        회원: 일반회원과 투자회원을 회원이라고 합니다.. 회원과 비회원을 이용자라고 합니다.<br>
        &nbsp;
    </li>
    <li>
        일반회원: 본 약관을 승인하고 회원가입을 하여 회사가 모바일 앱을 통해 제공하는 정보제공 서비스를 이용할 수 있는 자를 말합니다.<br>
        &nbsp;
    </li>
    <li>
        투자회원: 일반회원인 자 중에서 TO 거래를 위한 제휴계좌를 개설하고 회사에 정상적으로 등록하여 회사가 제공하는 TO 거래 관련 서비스를 이용할 수 있는 자를 말합니다.<br>
        &nbsp;
    </li>
    <li>
        수익증권: 신탁회사가 체결한 담보부대출채권관리처분신탁계약 또는 부동산관리처분신탁계약에 기초하여 「주식ㆍ사채 등의 전자등록에 관한 법률」(이하 “전자증권법”)에 따라 한국예탁결제원의 전자등록 시스템을 이용하여 전자적으로 발행된 신탁 수익증권을 의미합니다.<br>
        &nbsp;
    </li>
    <li>
        토큰증권(TO): 블록체인 기술을 활용해 수익증권을 디지털화 한 것으로, 신탁계약에 따라 발생한 수익증권을 회사가 제공하는 플랫폼을 통하여 거래하기 위한 수단을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        TO 거래 관련 서비스: 투자회원이 전자적 장치를 통하여 회사가 제공하는 플루토스 플랫폼에 접속하여 이용할 수 있는 TO 거래 서비스(청약, 매수, 매도, 시세 등 정보검색 관련 서비스 등) 및 TO 보유로 인한 수익증권의 권리 행사 등을 위해 회사가 제공하는 제반 서비스를 말합니다.<br>
        &nbsp;
    </li>
    <li>
        정보제공 서비스: 회원이 전자적 장치를 통하여 회사가 제공하는 플랫폼에 접속하여 TO와 관련하여 게시된 정보를 제공받거나 이용할 수 있는 서비스를 말합니다.<br>
        &nbsp;
    </li>
    <li>
        청약: 공모하는 TO에 대해 투자회원으로부터 매수의 공모신청을 받고, 회사가 사전에 안내한 기준대로 TO를 입고하는 것을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        PIN: 본인 명의 확인이 완료된 스마트폰을 통해 회원이 직접 지정한 복수의 숫자가 조합된 개인 식별 번호(Personal Identification Number)를 말합니다.<br>
        &nbsp;
    </li>
    <li>
        매도자: TO를 매도하기 위해 해당 TO를 적법한 매매 플랫폼을 통해 매도 주문을 제출하여 해당 TO를 매도한 투자회원을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        매수자: TO를 매수하기 위해 해당 TO를 적법한 매매 플랫폼을 통해 매수 주문을 제출하여 해당 TO를 매수한 투자회원을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        수익증권 보관기관: TO와 관련하여 신탁계약에 따라 발행되는 수익증권을 실물 발행없이 전자증권법에 따라 전자적 장부인 전자등록계좌에 등록하는 별도의 기관을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        신탁회사: 회사와 특정한 제휴 및 협약을 맺고 신탁 수익증권 발행과 관련한 신탁계약, 증권발행, 자산관리, 자산운영 등에 관한 역할과 책임을 맡아 수행하는 금융기관을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        계좌관리기관: 회사와 특정한 제휴 및 협약을 맺고 신탁 수익증권 거래와 관련한 제휴계좌 제공, 매매에 따른 대체결제, 고객계좌부 관리 등을 수행하는 전자증권법 상의 금융기관을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        제휴계좌: TO 거래 관련 서비스를 이용하기 위해 회원이 계좌관리기관에 개설하는 회원 본인 명의의 TO 거래 전용 계좌를 말합니다.<br>
        &nbsp;
    </li>
    <li>
        투자한도: 해당 회원의 투자자 등급으로 정해진 투자한도금액을 말합니다.<br>
        &nbsp;
    </li>
    <li>
        플루토스 플랫폼: 회사가 구축, 운영 및 관리하는 TO 전용 발행 플랫폼을 의미합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제3조 (약관의 명시, 효력 및 개정)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        본 약관은 이용자가 회원으로 가입하면서 동의함으로써 효력이 발생합니다. 회사는 본 약관의 내용을 전자적 장치(해당 전자적 장치에 게시하기 어려울 경우에는 이용자가 접근하기 용이한 전자적 장치)를 통하여 이용자에게 게시합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다. 본 약관을 개정할 경우에는 회사는 변경사항을 시행일자 7일 전부터 전자적 장치(해당 전자적 장치에 게시하기 어려울 경우에는 이용자가 접근하기 용이한 전자적 장치)에 게시하여 이용자에게 통지합니다, 다만, 변경되는 약관의 내용이 중요하거나 회원에게 불리한 내용일 경우에는 그 시행일자로부터 30일 이상의 유예기간을 두고 공지합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 동 조에 따라 개정약관을 회원에게 통지하면서 ‘적용일자 전일까지 약관 개정에 이의를 제기하지 아니하는 경우 약관 개정에 동의한 것으로 본다.’라는 취지의 내용을 고지하거나 통지합니다. 또한, 회원이 동 기한 내에 명시적으로 거부의 의사표시를 하지 아니한 경우에는 약관 개정에 동의한 것으로 봅니다.<br>
        &nbsp;
    </li>
    <li>
        회원이 전항에 따른 기한 내에 개정 약관의 적용에 동의하지 않는다는 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없습니다. 다만, 이 경우 회사는 해당 회원에 대하여 이용계약을 해지할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제4조 (약관의 해석)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사와 회원 사이에 개별적으로 합의한 사항이 본 약관에 정한 사항과 다를 때에는, 그 합의 사항을 본 약관에 우선하여 적용합니다.<br>
        &nbsp;
    </li>
    <li>
        본 약관의 내용 중 관련 법령의 강행규정과 배치되는 부분이 있는 경우, 그 부분에 한하여 본 약관의 해당 규정은 무효로 합니다.<br>
        &nbsp;
    </li>
    <li>
        본 약관에 명시적으로 규정되지 않은 사항에 대하여 당사자 간에 미리 합의된 사항이 아닌 경우에는 관련 법령과 신탁계약 및 상관례에 따릅니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제2장 서비스 이용 신청 및 승낙 (회원가입 및 탈퇴)</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제5조 (회원 서비스의 종류)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">회사는 신탁계약을 통해 발행되는 수익증권이 플루토스 플랫폼에서 판매 될 수 있도록 디지털 증권화하고 해당 디지털 증권의 판매 관련 서비스 등을 투자회원에게 제공합니다. 회사가 제공하는 구체적인 서비스의 내용은 다음 각 호와 같습니다.</span><br>
    &nbsp;
</p>
<ol>
    <li>
        신규 공모 TO에 대한 청약<br>
        &nbsp;
    </li>
    <li>
        TO의 매매거래를 위한 적법한 매매 플랫폼 연결<br>
        &nbsp;
    </li>
    <li>
        회원의 잔고 내역, 주문 내역, 체결 내역 등 거래 관련 정보의 제공<br>
        &nbsp;
    </li>
    <li>
        TO 기준가격, 신탁자산의 운용 및 관리 관련 공시 정보 등 TO 투자 관련 정보의 제공<br>
        &nbsp;
    </li>
    <li>
        투자회원의 TO 보유자로서의 권리 행사 처리<br>
        &nbsp;
    </li>
    <li>
        투자회원의 수익금(운용수익 및 처분수익 포함)의 배분<br>
        &nbsp;
    </li>
    <li>
        그 밖에 회원의 서비스 이용과 관련하여 필요하다고 회사가 판단하는 제반 서비스<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제6조 (이용계약의 성립)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        이용자는 회사가 제공하는 가입 양식에 따라 회원정보를 기입하고 본인인증을 완료한 후 본 약관(별지 포함)에 동의함으로써 회원가입을 신청합니다.<br>
        &nbsp;
    </li>
    <li>
        회원가입은 회사의 승낙이 회원에게 도달된 시점에 완료됩니다.<br>
        &nbsp;
    </li>
    <li>
        회원가입이 완료됨으로써 서비스 이용계약이 성립되고, 이용신청자는 일반회원으로 등록됩니다.<br>
        &nbsp;
    </li>
    <li>
        일반회원 중, 계좌관리기관에 제휴계좌를 개설하고 회사에 정상적으로 등록한 회원은 투자회원으로 등록됩니다.<br>
        &nbsp;
    </li>
    <li>
        가입양식에 기재된 회원 정보는 회원 본인의 실명을 원칙으로 하며, 만약 사실과 다른 정보 또는 거짓정보를 기입함으로써 실명이 아니거나 타인의 정보를 도용하여 허위로 가입한 회원은 법적인 보호를 받을 수 없고, 이에 따른 민사∙형사상의 책임은 가입한 회원이 부담합니다.<br>
        &nbsp;
    </li>
    <li>
        만 19세 미만의 자는 회원으로 가입할 수 없으며, 이용계약의 당사자가 될 수 없습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 이용자가 제공한 정보가 사실과 일치하는지를 확인하기 위하여 관련 법령에 의하여 허용된 범위에서 실명확인 대행기관에게 실명확인 또는 본인인증을 요청할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원에게 회사의 서비스에 대한 정보를 전자우편(E-mail), 우편, 전화, 모바일 메신저, 인터넷 메신저, SNS 등 그 밖에 전자적 장치를 통한 방법으로 제공할 수 있습니다. 다만, 회원이 거부의사를 표명한 경우 회사는 회사의 서비스에 대한 정보를 제공하지 아니합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제7조 (이용신청의 승낙)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 다음 각 호의 하나에 해당하는 경우 회원가입의 승낙을 유보 또는 제한할 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                서비스 제공 설비에 여유가 없는 경우<br>
                &nbsp;
            </li>
            <li>
                서비스 제공을 위한 기술적 부분에 문제가 있다고 판단되는 경우<br>
                &nbsp;
            </li>
            <li>
                기타 회사의 사정상 이용승낙이 곤란한 경우<br>
                &nbsp;
            </li>
        </ol>
    </li>
    <li>
        제1항에도 불구하고 회사는 다음 각 호의 하나에 해당하는 이용신청에 대하여는 그 승낙을 거절할 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                이용자의 본인 확인이 안 되거나 타인의 명의를 이용한 것이 확인된 경우<br>
                &nbsp;
            </li>
            <li>
                이용신청 시 필요 내용을 허위로 기재하여 신청한 경우<br>
                &nbsp;
            </li>
            <li>
                사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우<br>
                &nbsp;
            </li>
            <li>
                본 약관에 의하여 이용계약이 해지된 사실이 있는 경우<br>
                &nbsp;
            </li>
            <li>
                기타 회사가 정한 이용 신청 요건을 갖추지 못한 경우<br>
                &nbsp;
            </li>
        </ol>
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제8조 (이용계약의 중지 및 해지)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원이 본 약관에 따른 이용계약을 해지하고자 할 때에는 회원 본인이 회사에 해지 신청을 하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        1년 동안 로그인 기록이 없는 회원은 휴면회원으로 전환되며, 회사는 휴면회원으로 변경 시 SMS, 이메일 등 회원이 사전에 동의한 전자적 방법으로 해당 회원에게 공지합니다.<br>
        &nbsp;
    </li>
    <li>
        휴면회원의 재로그인 시 휴면회원으로 전환되기 이전의 회원등급으로 원복이 됩니다.<br>
        &nbsp;
    </li>
    <li>
        회원이 직접 이용계약을 해지하고자 하는 경우에는 회원의 계정 및 제휴계좌에 TO 잔고 및 청약증거금, 매매증거금, 수익증권에 대한 권리내역 및 예수금 잔액이 없는 상태에서만 해지 신청이&nbsp;<br>
        가능하며, 그렇지 않을 경우 회사는 회원의 이용계약 해지 신청을 반려할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회원의 이용계약 해지 신청에 따라 이용계약이 종료된다 하더라도 회원 명의의 계좌관리기관 제휴계좌는 폐쇄되지 않습니다. 제휴계좌의 폐쇄를 원하는 회원은 계좌관리기관의 온라인 또는 오프라인 영업창구를 통해서 별도의 계좌 폐쇄 신청을 하여야 합니다. 마찬가지로 계좌관리기관의 제휴계좌를 먼저 폐쇄한다 하더라도 회원의 이용계약이 종료되는 것은 아닙니다.<br>
        &nbsp;
    </li>
    <li>
        탈퇴한 회원의 전자금융거래기록 (거래의 종류, 금액, 거래 계좌, 거래와 관련한 전자장치의 접속기록 등)은 백업하여 5년간 보관합니다.<br>
        &nbsp;
    </li>
    <li>
        탈퇴한 회원은 재가입이 가능하지만, 서비스 탈퇴 전의 정보는 모두 삭제되어 재가입을 하더라도 모바일 앱에서 이전 이용 내역은 확인이 불가합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원이 다음 각 호의 어느 하나에 해당하는 행위를 하였을 경우 일정 기간 서비스 이용을 제한할 수 있으며, 필요한 경우 시정을 요구할 수 있습니다. 회사의 시정 요구에도 불구하고 회원이 14일 이내에 시정하지 않거나 2회 이상 동일한 위반행위를 하는 경우 회사는 이용계약을 해지할 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                타인의 명의를 도용한 경우<br>
                &nbsp;
            </li>
            <li>
                서비스 운영을 고의로 방해한 경우<br>
                &nbsp;
            </li>
            <li>
                회원이 본인명의 확인이 완료된 스마트폰이 아닌 다른 스마트폰을 통해 서비스를 이용하는 경우<br>
                &nbsp;
            </li>
            <li>
                회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스를 이용한 경우<br>
                &nbsp;
            </li>
            <li>
                타인의 명예를 손상시키거나 중대한 불이익을 주는 행위를 한 경우<br>
                &nbsp;
            </li>
            <li>
                서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나, 광고성 정보를 전송하는 경우<br>
                &nbsp;
            </li>
            <li>
                정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우<br>
                &nbsp;
            </li>
            <li>
                회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통 시키거나 상업적으로 이용하는 경우<br>
                &nbsp;
            </li>
            <li>
                원이 회사의 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우<br>
                &nbsp;
            </li>
            <li>
                회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우<br>
                &nbsp;
            </li>
            <li>
                본 약관을 위반함으로써 회원의 이용계약을 유지하기 어려운 경우<br>
                &nbsp;
            </li>
            <li>
                제3자에 대한 개인정보를 동의없이 수집, 저장, 공개, 이용을 하는 경우<br>
                &nbsp;
            </li>
            <li>
                회사의 서비스를 통하여 얻은 정보로 TO 직거래를 유도하는 경우<br>
                &nbsp;
            </li>
            <li>
                회사의 서비스 제공에 반드시 필요한 절차를 이행하지 아니하는 경우<br>
                &nbsp;
            </li>
            <li>
                회원이 관계 법령 및 그 밖에 회사가 사전에 안내한 회사의 정책에 위배되는 행위를 한 경우<br>
                &nbsp;
            </li>
        </ol>
    </li>
    <li>
        회사는 특정 회원에 대한 이용을 제한하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 해당 회원 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정되는 경우에는 우선 서비스 이용을 중지한 후 통지할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        전항에 따라 서비스 이용중지를 통지 받은 회원 또는 그 대리인은 이의가 있을 경우 이의 신청을 할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 이용중지 기간 중에 그 이용중지 사유가 해소된 경우에는 이용중지 조치를 즉시 해제합니다.<br>
        &nbsp;
    </li>
    <li>
        회사가 이용계약을 해지한 경우에는 회원등록을 말소합니다. 회사는 이 경우 회원에게 이를 통지하고, 필요한 경우 회원등록 말소 전에 소명 기회를 부여할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제9조 (회원정보의 변경)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원은 개인정보 수정화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명 확인 관련 사항은 수정이 불가능할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회원은 회원정보의 변동이 있는 경우 지체없이 회사에 통지하고 관련 정보를 변경 등록하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회원이 전항에 따른 변경 등록을 하지 않아 발생한 불이익 또는 손해에 대하여 회사는 회사의 책임 있는 사유가 아닌 경우에는 책임을 지지 않습니다.
    </li>
</ol>
<p style="text-align:center;">
    <br>
    &nbsp;
</p>
<p style="text-align:center;">
    <h3 style="text-align:center">제 3 장 회원의 의무</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제10조 (접근매체 발급 및 등록)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">회사는 전자금융거래를 위하여 접근매체를 선정하여 사용 및 관리하고 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다.</span><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제11조 (인증수단 관리에 대한 회원의 의무)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원의 스마트폰 및 PC등의 전자적 장치에 저장된 모든 인증수단(PIN을 포함)에 관한 관리책임은 원칙적으로 회원에게 있으며, 회원은 인증수단을 타인에게 양도 또는 대여할 수 없습니다. 회원이 인증수단을 유출, 양도, 대여하여 발생한 손실이나 손해에 대하여 회사는 책임을 지지 않습니다.<br>
        &nbsp;
    </li>
    <li>
        회원은 서비스에 대한 접근수단의 도난, 분실, 위조 또는 변조의 사실을 알았거나 이용절차상 비밀을 요하는 사항이 누설되었음을 알았을 때에는 즉시 회사에 그 사실을 통지하고 회사의 안내에 따라야 합니다.<br>
        &nbsp;
    </li>
    <li>
        전항의 경우 해당 회원이 회사에 그 사실을 즉시 통지하지 않거나, 통지한 후 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제12조 (추가 인증수단 관리에 대한 회원의 의무)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 PIN을 대체하는 인증방법으로 지문, 페이스 아이디 등을 추가로 제공할 수 있으며, 대체 인증방법을 선택 혹은 이용할 수 있는 모바일 등의 단말기에서 사용할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 이용회원의 거래내역과 회사에서 정한 기준에 따라 비밀번호를 포함하여 인증 과정을 생략하거나 비밀번호 이외의 추가 인증을 요구할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        비밀번호 관리에 관한 일체의 책임은 이용회원 본인에게 있습니다. 자신의 비밀번호를 제3자에게 대여 또는 양도하거나 이와 유사한 행위를 하여서는 아니 되며, 비밀번호의 관리를 소홀히 하여 발생하는 모든 책임은 이용회원 본인에게 있습니다.<br>
        &nbsp;
    </li>
    <li>
        이용회원은 자신의 비밀번호가 부정하게 사용된 경우에 즉시 자신의 비밀번호를 바꾸고 그 사실을 회사에 통보하여야 합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제13조 (정보의 제공)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">회사는 서비스 이용에 필요한 다음 각 호의 서비스 정보에 대해서 전자우편(E-mail), 우편, 전화, 모바일 메신저, 인터넷 메신저, SNS 등 그 밖에 전자적 장치를 통한 방법으로 회원에게 제공할 수 있으며, 회원은 원하지 않을 경우 가입신청 메뉴와 회원정보 수정 메뉴에서 수신거부를 설정할 수 있습니다.</span><br>
    &nbsp;
</p>
<ol>
    <li>
        이벤트 및 프로모션 관련 서비스 정보<br>
        &nbsp;
    </li>
    <li>
        그 밖에 회사가 회원에게 제공하는 서비스 정보<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제4장 서비스 이용</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제14조 (이용 시간)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        서비스 이용 시간은 제5조의 서비스의 유형에 따라 회사가 별도로 정하는 규정에 의거 달리 정할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        서비스 운영상의 필요성으로 회사가 정한 이용 시간 중에 서비스가 일시 중지될 수 있으며, 금융기관 등 외부업체와 연계하여 제공하는 서비스의 경우 해당 외부업체의 사정에 따라 서비스 중 일부 또는 전부가 중지될 수 있습니다. 서비스 중지가 발생하는 경우 그 내용을 홈페이지 또는 모바일 앱을 통하여 공지합니다.<br>
        &nbsp;
    </li>
    <li>
        로그인 후 일정 시간동안 사용이 없는 경우 자동 로그아웃되며, 재로그인 시 서비스 이용이 가능합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제15조 (수수료 및 보수)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 투자회원의 청약시 TO 매입 서비스 제공의 대가로 투자회원에게 소정의 수수료를 부과하거나 수수료 금액을 변경할 수 있습니다. 회사는 수수료 금액, 종류, 산정기준, 징수방법 등을 회원이 사전에 알 수 있도록 공시하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 수수료 금액 등이 변경된 경우 사전에 본 약관에서 정하는 절차에 따라 플루토스 플랫폼을 통해 공지하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 마케팅 등의 목적으로 특정 투자회원을 대상으로 플루토스 플랫폼에서 투자금 지원, TO의 거래수수료 면제 등의 이벤트를 실시할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제16조 (제휴계좌의 개설 및 사용)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원은 TO 청약 및 매매를 위하여 회사가 지정한 계좌관리기관에 본인 명의로 된 제휴계좌를 개설하여야 하며, 제휴계좌의 예수금은 회사의 고유재산과는 분리되어 관리됩니다.<br>
        &nbsp;
    </li>
    <li>
        투자회원은 본인 제휴계좌의 예수금 중 인출가능 현금의 범위 내에서 언제든지 인출할 수 있습니다. 또한, 계좌관리기관의 온라인 또는 오프라인 영업 창구를 통해서도 해당 자금의 인출을 신청할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제17조 (비실명계좌에 대한 해지)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원은 「금융실명거래 및 비밀보장에 관한 법률」에 따라 청약 또는 매매거래의 주문을 실명으로 제출하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 제1항에 위반하여 개설된 계좌에 대하여는 투자회원에게 사전통보 없이 회사가 청약 또는 매매거래계약을 해지하거나 거래정지 등의 조치를 취할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제18조 (서비스의 중지)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 다음 각호의 하나에 해당하는 경우 서비스 제공을 중지할 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                서비스용 전산설비의 보수 등 공사로 인하여 중지가 부득이한 경우<br>
                &nbsp;
            </li>
            <li>
                전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br>
                &nbsp;
            </li>
            <li>
                회사가 직접 제공하는 서비스가 아닌 제휴업체 등 제3자를 이용하여 제공하는 서비스의 경우 해당 제3자가 서비스를 중지했을 경우<br>
                &nbsp;
            </li>
            <li>
                기타 불가항력에 해당하는 사유가 발생한 경우<br>
                &nbsp;
            </li>
        </ol>
    </li>
    <li>
        회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 운용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        제1항 내지 제2항에 따라 서비스가 중지되어 발생한 회원의 손해에 대하여 회사는 면책권한을 가집니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 다음 각 호의 어느 하나에 해당하는 종목의 매매거래를 정지할 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                매매거래가 폭주하여 신속하게 매매거래를 성립시킬 수 없다고 인정되는 종목<br>
                &nbsp;
            </li>
            <li>
                매매거래의 내용이 현저하게 공정성을 결여할 우려가 있다고 인정되는 종목<br>
                &nbsp;
            </li>
            <li>
                신탁계약에 따른 신탁자산 처분사유의 발생으로 인하여 매매거래의 정지가 필요하다고 인정되는 종목<br>
                &nbsp;
            </li>
            <li>
                회사가 사전에 안내한 회사의 규정에 따라 시장관리상 매매거래의 정지가 필요하다고 인정되는 종목<br>
                &nbsp;
            </li>
            <li>
                그 밖에 투자자 보호 등을 위하여 매매거래의 정지가 필요하다고 인정되는 종목<br>
                &nbsp;
            </li>
        </ol>
    </li>
    <li>
        회사는 제2항 또는 제4항의 규정에 의하여 서비스를 제한하거나 정지한 경우 그 제한사유 및 정지사유가 해소된 때에는 지체없이 서비스를 재개합니다.<br>
        &nbsp;
    </li>
    <li>
        회사로부터 합리적인 사유의 고지 없이 10영업일을 초과하여 제5항의 서비스 재개되지 않을 경우 신탁계약의 "플루토스 플랫폼 운영중단"으로 봅니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제5장 청약 서비스 이용</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제19조 (투자정보의 게재)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        TO에 관한 청약 및 투자를 시작하기 전에 투자판단을 위해 필요한 정보가 게재됩니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 제1항에 따라 게재된 정보에 관한 사실을 확인하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        제2항에도 불구하고 회원이 게재된 내용에 의존하여 투자판단을 한 경우 이로부터 발생하는 결과에 대하여 회사는 회사의 고의 또는 과실이 없는 이상 책임을 지지 않습니다.<br>
        &nbsp;
    </li>
    <li>
        제1항에도 불구하고 청약기간 중에도 추가적으로 투자정보가 게재될 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제20조 (청약 주문 전 정보 확인)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원은 본 약관에 따른 청약의 주문 전에 제공되는 정보 외에도 투자설명서, 신탁계약서, 증권신고서 등을 읽고 상품의 내용, 위험 등을 충분히 숙지한 후 청약의 의사표시를 하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원이 본 약관에 따른 청약의 주문을 하기 전에 회사로부터 청약대상이 되는 TO에 대한 투자 위험 등에 대하여 회원이 충분히 확인을 하였는지를 전자서명이나 전자우편 등의 방법으로&nbsp;<br>
        확인한 후에 청약의 주문을 받을 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 청약과 관련된 정보를 정정 게재하는 경우 자본시장법 등 관계법령이 정하는 바에 따라 회원에게 정정 게재 사실을 통지하고 회원의 청약 의사를 재확인합니다. 또한 회사는 회원의 투자&nbsp;<br>
        위험성에 대한 충분한 이해도를 확인하고 청약을 받아야 하며, 회사는 회원이 재청약 의사를 표시하였는지를 확인하기 전에는 그 청약의 주문을 받을 수 없으며, 이 경우 회원의 기존 청약의 주문 신청은 취소됩니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 투자자의 투자성향, 투자상품의 적합성 여부 테스트 등 자본시장법 등 관계법령이 정하는 바에 따라 온라인에서 금융상품 판매시 확인해야 하는 사항을 확인한 후 청약 주문을 접수해야 합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제21조 (청약 신청 방법)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원은 공모 TO의 신규 청약이 가능하며, 전자적 장치를 이용하는 방법으로 청약할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사가 지정하는 계좌관리기관에 회원이 회원 본인 명의의 TO 거래 전용 제휴계좌를 개설하고 회사에 정상적으로 계좌 연결 등록을 완료한 투자회원에 한하여 청약 신청할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        청약금액은 투자회원의 제휴계좌의 인출가능 현금의 범위 내에서 투자회원이 지정한 금액으로 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 청약의 주문을 하려는 회원의 실명인증 및 투자자 본인과의 동일성 확인을 위한 인증절차를 거쳐야 합니다.<br>
        &nbsp;
    </li>
    <li>
        투자회원이 청약을 주문함과 동시에 투자회원의 제휴계좌에서 해당 청약금액이 자동 출금되고, 청약증거금으로 회사가 지정하는 금융기관의 회사 명의로 된 청약증거금 관리용 신탁계좌로 입금 처리됩니다.<br>
        &nbsp;
    </li>
    <li>
        청약증거금은 회사의 고유재산과는 분리되어 관리되며, 청약대금 이외의 목적으로 사용되지 않습니다. 한편, 청약증거금에 대한 이자 또는 예탁금 이용료는 지급되지 않습니다.<br>
        &nbsp;
    </li>
    <li>
        동일 종목에 대한 복수의 청약 주문은 불가하며, 기 주문한 청약을 수정 또는 정정하고자 할 때에는 기 주문한 청약을 철회하고 다시 청약의 주문을 하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        투자회원은 회원의 투자자등급에 맞는 투자한도 내에서 청약 가능합니다.<br>
        &nbsp;
    </li>
    <li>
        투자회원이 일반투자자가 아닌 경우(소득적격투자자, 전문투자자 등), 이에 관한 증빙자료를 회사에 제출한 후에 회사의 확인을 거쳐 투자한도를 높일 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        통신의 장애, 서비스의 장애, 주문의 효력 상실 등으로 인하여 청약의 미처리, 처리지연, 청약 통보 지연이 발생할 수 있으며, 회원은 청약 처리가 완결됐는지를 직접 확인하여야 합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제22조 (청약의 "미달" 시 처리)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        청약기간이 종료된 후, 모집결과가 "미달"인 TO에 대한 청약의 주문은 취소처리 됩니다.<br>
        &nbsp;
    </li>
    <li>
        모집결과가 "미달"인 경우 투자회원이 입금한 청약증거금 원금은 사전에 안내된 반환예정일에 투자회원의 계좌로 반환됩니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 모집결과의 "성공" 및 "미달"의 판단 기준을 TO 판매 정보에 사전 고지합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제23조 (청약의 철회)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원은 청약기간의 종료 전(본 약관에서 정한 사유로 청약기간이 연장된 경우에는 연장된 청약기간의 종료 전)까지 청약의 의사표시를 철회할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        제1항에 따라 회원이 청약을 철회한 경우, 위 청약증거금은 청약 철회 신청이 회사에게 도달한 날로부터 지체 없이 회원의 예수금으로 반환됩니다.<br>
        &nbsp;
    </li>
    <li>
        회원은 청약기간의 종료 후에는 청약의 의사표시를 철회할 수 없습니다. 또한, 청약기간의 종료 전이라도 모집금액이 목표 모집금액에 도달한 이후에는 청약의 의사표시를 철회할 수 없습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제24조 (청약의 배정)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        청약기간이 종료되면 회사는 모집결과를 모바일 앱에 게시하며, 청약의 접수를 완료한 회원에게 모집결과 및 각 청약의 접수에 대한 TO의 배정내역을 전자우편(E-mail)이나, 우편, 전화, 문자, 모바일 메신저, 인터넷 메신저, SNS 등 그 밖에 전자적 장치를 이용한 방법으로 통보합니다.<br>
        &nbsp;
    </li>
    <li>
        회원은 청약결과로 배정받은 TO를 수익증권에 대한 수익자의 지위로서 보유하게 되며, 회사는 회원의 TO를 보관 및 관리합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제25조 (청약금의 납입 및 반환)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        청약 기간 종료 후, 청약증거금관리기관에 예치되어 있던 청약증거금 중 수익증권 발행대금에 해당하는 금액이 신탁회사가 사전 지정한 납입계좌로 납입됩니다.<br>
        &nbsp;
    </li>
    <li>
        청약 주문 중 배정이 되지 않았거나 청약의 주문이 효력을 상실한 경우 회원이 입금한 청약증거금은 사전 고지한 반환일에 회원의 예수금으로 반환됩니다.<br>
        &nbsp;
    </li>
    <li>
        청약금이 모집목표금액을 초과한 경우, TO를 배정하는 기준은 청약을 접수한 순서 기준입니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제6장 TO와 수익증권의 관계</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제26조 (TO와 수익증권의 관계)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        TO는 개인이 신탁자산의 수익증권을 손쉽게 거래할 수 있도록 블록체인 기술을 활용하여 만들어진 것으로, 발행된 수익증권과 그 수량 등이 항상 대응하여 관리됩니다.<br>
        &nbsp;
    </li>
    <li>
        제1항에 대하여 회사는 고객의 거래 요청을 우선 블록체인 네트워크에서 TO 거래로 처리하고, 계좌관리기관이 이를 수익증권의 고객계좌부에 반영함으로써 정보를 일치시킵니다.<br>
        &nbsp;
    </li>
    <li>
        TO와 수익증권은 항상 수익권자와 보유 수량이 대응되어 동일하게 관리되지만, 법적으로 수익권자를 판단하는 기준은 TO가 아닌 수익증권입니다. 따라서, 계좌관리기관의 수익증권 고객계좌부에 기록, 보관되는 수익권자가 수익증권에 대한 권리를 적법하게 보유하는 것으로 추정 받습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제27조 (수익증권의 발행 및 예탁)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        청약금이 납입되면 발행조건(변경된 발행조건 포함)에 따라 수익증권이 발행되어 수익증권의 배정을 받은 회원 명의가 계좌관리기관의 고객계좌부에 등록됨으로써 한국예탁결제원에 수익증권이 예탁되고, 계좌관리기관에 개설된 회원의 고객계좌에 수량이 기재됨으로써 전자등록됩니다.<br>
        &nbsp;
    </li>
    <li>
        제1항을 위해, 회사는 설정대금을 발행인에게 납입하는 즉시 청약한 회원들에게 상응하는 TO를 배정하고, 동시에 계좌관리기관은 한국예탁결제원에 고객계좌부를 등록하고 회원별 고객계좌에 배정 수량을 기록합니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제7장 투자회원 간 거래 서비스 이용</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제28조 (투자회원 간 주문 규정)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        TO를 매매하고자 하는 투자회원은 플루토스 플랫폼이 아닌 다음 각 호의 어느 하나에 해당하는 업체에 해당 업체의 규정에 맞는 방식으로 주문을 제출할 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                플루토스 연계 증권사
            </li>
            <li>
                장외거래중개업자
            </li>
            <li>
                한국거래소(장내시장 상장시)<br>
                &nbsp;
            </li>
        </ol>
    </li>
    <li>
        제1항각호의 회사는 불공정 거래의 소지가 있는 주문의 접수 거부, 기타 위규 및 사고 방지 등을 위하여 매매수량 및 금액, 주문방법, 주문시간 등을 회사가 별도로 정하는 규정에 따라 제한할 수&nbsp;<br>
        있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제29조 (거래내용의 확인)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원은 TO 거래 후 전산 및 회선장애 등으로 인하여 주문 및 매매주문 처리결과 보고가 지연될 수 있으므로 이중주문 등이 발생하지 않도록 주문입력 후 처리완료까지의 결과를 직접 확인해야 합니다.<br>
        &nbsp;
    </li>
    <li>
        투자회원은 제1항에 따라 확인한 결과 거래내용에 의문점이 있거나 이상이 발생한 경우 즉시 회사의 고객만족센터 등을 통해 연락하여 필요한 조치를 받아야 합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제30조 (TO 매매의 법적 효과)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원의 TO 매매 의사표시는 그에 상응하는 수익증권 매매 의사표시와 동일한 법적 효력이 있습니다. TO를 매도하는 경우에는 그에 상응하는 수익증권이 고객계좌부의 매도인 계좌에서 매수인 계좌로 수량이 변경 기록되는 계좌간 대체의 전자등록이 이루어집니다.<br>
        &nbsp;
    </li>
    <li>
        TO와 상응하여 발행되는 수익증권의 고객계좌부는 계좌관리기관에서 관리하며, 투자회원과 계좌관리기관 사이의 고객계좌 관리계약은 해당 TO를 최초로 취득한 투자회원에 대한 해당 수익증권의 발행일과 같은 날에 성립한 것으로 봅니다.<br>
        &nbsp;
    </li>
    <li>
        TO를 보유한 회원이 수익증권의 수익권자로서 의사결정을 하는 경우 의사결정이 필요한 사항 및 의사결정의 방법은 신탁계약에서 정한 바에 따르기로 합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제31조 (주문 접수의 거부)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">다음 각 호에 해당하는 경우 회원의 주문 접수는 거부될 수 있습니다.</span><br>
    &nbsp;
</p>
<ol>
    <li>
        가격제한폭을 초과하여 주문하는 경우
    </li>
    <li>
        회사가 공익과 투자자 보호 또는 거래질서의 안정을 위하여 필요하다고 인정하는 주문의 경우
    </li>
    <li>
        그 밖에 회사가 사전에 안내한 거부 사유에 해당하는 주문을 하는 경우<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제32조 (거래 서비스의 이용제한)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">회사는 투자회원에게 본 약관 이용중지에 해당하는 사유 또는 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우 서비스의 이용을 제한할 수 있습니다. 본 조에 따라 서비스 이용 제한조치가 이루어진 경우 회사는 즉시 해당 내용을 투자회원에게 통지하여야 하고, 필요한 경우 일정한 기간을 정하여 투자회원에게 소명기회를 제공할 수 있으며, 소명이 합리적인 경우에는 제한을 즉시 해소하여야 합니다.</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원의 계좌가 해킹을 당하거나 사기 기타 범죄에 이용된 것으로 확인된 경우
    </li>
    <li>
        투자회원이 탈퇴를 신청한 경우
    </li>
    <li>
        투자금액이 정해진 한도를 초과한 경우
    </li>
    <li>
        일일 매매회전율을 초과한 경우
    </li>
    <li>
        매도자와 매수자가 동일인으로 판단된 경우
    </li>
    <li>
        국가기관 등이 관련 법령 등에 따라 서비스 제한을 요청한 경우
    </li>
    <li>
        투자회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우
    </li>
    <li>
        투자회원으로 가입된 이용자가 대한민국 국적을 갖지 않은 것으로 확인된 경우
    </li>
    <li>
        회사가 사전에 안내한 시장감시규정에 따른 시장감시위원회가 서비스 이용제한을 의결한 경우
    </li>
    <li>
        그 밖에 특정시세 형성을 위한 불공정 거래행위 등 TO 거래 관련 서비스 이용을 제한할 필요가 있는 경우
    </li>
    <li>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제8장 투자 서비스 일반</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제33조 (서비스 이용내역의 보존)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        서비스 이용 내역의 보존기간은 다음 각 호와 같습니다.<br>
        &nbsp;
        <ol>
            <li>
                TO 거래 일시, 거래 내역 등 거래 관련 정보는 각 정보 생성일로부터 10년간 보관
            </li>
            <li>
                다음 각 목의 정보는 각 정보 생성일로부터 5년간 보관하는 것을 원칙으로 합니다.<br>
                &nbsp;
                <ol>
                    <li>
                        회사가 TO 거래의 대가로 받은 수수료 내역 등
                    </li>
                    <li>
                        회원에게 배분된 수익금의 내역
                    </li>
                </ol>
            </li>
            <li>
                다음의 정보는 각 정보 생성일로부터 1년간 보관하는 것을 원칙으로 합니다.
                <ol>
                    <li>
                        회원의 오류정정 요구사실 및 처리결과에 관한 사항
                    </li>
                    <li>
                        서비스의 신청 및 조건의 변경에 관한 사항<br>
                        &nbsp;
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        회사는 회원에게 서비스를 제공하면서 취득한 회원의 인적 사항, 회원의 계좌, 접근매체 및 서비스 이용 내역에 관한 정보를 관련 법령에 따르거나 회원의 동의를 얻어 제3자에게 제공할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제34조 (거래 및 매매내역 등의 통지)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 TO의 매매가 체결된 경우에는 지체없이 매매의 유형/종목/수량/가격/수수료 등 관련 거래내역을 투자회원에게 통지하여야 합니다.
    </li>
    <li>
        회사는 투자회원과 사전 합의에 따라 다음 각 호의 어느 하나에 해당하는 방법으로 관련 거래내역을 통지하여야 합니다. 다만, 투자회원이 통지를 원하지 않는 경우 회사는 그 통지를 하지 않을 수 있습니다.<br>
        &nbsp;
        <ol>
            <li>
                전자우편(E-mail), 모바일 메신저, 인터넷 메신저, SNS 및 그 밖에 전자적 장치를 통한 방법 인터넷 또는 모바일 시스템을 통해 수시로 확인할 수 있는 방법
            </li>
        </ol>
    </li>
    <li>
        회사는 월간 매매내역/손익내역, 월말 잔액/잔량 현황을 다음달 20일까지 투자회원에게 통지하여야 합니다. 다만, 투자회원이 6개월 동안 매매 거래가 없는 경우에는 해당 6개월 종료 후 20일까지 반기말 잔액/잔량현황만 통지할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 플루토스 플랫폼을 통해 투자회원이 매매내역 등을 수시로 확인할 수 있는 방안을 제공하거나 투자회원이 매매내역 등을 수시로 확인할 수 있는 통장(전자통장 포함)을 제공한 경우에는 제1항 및 제3항에 따른 별도의 통지를 생략할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제35조 (수익금의 배분)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        TO를 소유한 투자회원은 수익증권의 수익권자로서 신탁회사가 배분하는 수익분배금(수익증권의 원본 및 운용수익 분배금 포함) 등을 관련 세법에 따라 원천징수액을 제외한 나머지 금액을 지급받을 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 제1항 및 제36조에 따른 원천징수 내역을 회사가 별도로 정하는 절차에 따라 투자회원에게 고지할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제36조 (투자자 등급별 거래 제한)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원의 투자한도는 관련 법령에서 허용된 범위 내에서 회사가 정하는 바에 따르며, 회사가 정한 한도에 관한 사항은 본 약관에서 정한 통지의 방법으로 회원에게 알립니다.
    </li>
    <li>
        <br>
        회사는 한도를 정함에 있어, 회사가 요구하는 추가적인 인증 요건 등 회사가 제시하는 기준을 충족한 회원과 그렇지 않은 회원에 대하여 그 한도 적용을 달리할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제37조 (배당소득세 등의 원천징수)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        투자회원은 관련 법령에 따라 수익증권으로부터 발생한 현금배당 및 TO 매매차익 등에 대하여 원천징수된 금액을 수령합니다. 이때 고객의 계좌에 현금이 부족하여 회사가 고객을 대신하여 소득세를 납부하는 경우 고객은 회사가 대신 납부한 금액에 대하여 기획재정부령으로 정하는 당좌대출이자율에 해당하는 이자를 대납자에게 지급하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        전항에 따라 회사 또는 계좌관리기관이 고객이 부담하여야 할 세금을 대신 납부하는 경우, 회사는 이 사실을 고객에게 지체없이 알려야 합니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제9장 회사의 의무</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제38조 (회사의 권리와 의무)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위한 보안시스템을 갖추며, 개인정보처리방침을 공지하고 이를 준수합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
    </li>
    <li>
        &nbsp;
    </li>
    <li>
        회사는 회사의 책임 있는 사유로 인한 다음 각 호의 사고로 인하여 회원에게 손해가 발생한 경우, 그 손해를 배상할 책임을 부담합니다.
        <ol>
            <li>
                접근매체의 위조나 변조로 발생한 사고
            </li>
            <li>
                계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고
            </li>
            <li>
                전자금융거래를 위한 전자적 장치 또는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고
            </li>
        </ol>
    </li>
    <li>
        전항에도 불구하고, 회사는 다음 각 호의 경우 그 책임의 전부 또는 일부를 회원이 부담하게 할 수 있습니다.
    </li>
    <li>
        &nbsp;
        <ol>
            <li>
                회원이 접근매체를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우
            </li>
            <li>
                제3자가 권한 없이 회원의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 접근매체를 누설하거나 노출 또는 방치한 경우
            </li>
            <li>
                회사가 전자금융거래법 제6조제1항에 따른 확인 외에 보안강화를 위하여 전자금융거래 시 요구하는 추가적인 보안조치를 회원이 정당한 사유 없이 거부하여 제4항 제3호에 해당하는 사고가 발생한 경우<br>
                &nbsp;
            </li>
            <li>
                회원이 위 제3호에 따른 추가적인 보안조치에 사용되는 매체나 수단 또는 정보에 대하여 다음 각 목의 어느 하나에 해당하는 행위를 하여 제4항 제3호에 해당하는 사고가 발생한 경우
                <ol>
                    <li>
                        누설이나 노출 또는 방치한 행위
                    </li>
                    <li>
                        제3자에게 대여하거나 그 사용을 위임한 행위 또는 양도나 담보의 목적으로 제공한 행위
                    </li>
                </ol>
            </li>
            <li>
                법인인 회원에게 손해가 발생하였으나, 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우<br>
                &nbsp;
            </li>
        </ol>
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제10장 개인정보 보호</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제39조 (회원정보 사용에 대한 동의 및 비밀보장 의무)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원의 개인정보(이하 "회원정보"라 합니다)에 대해서는 회사의 개인정보 보호정책이 적용됩니다. 회사는 회원정보의 수집, 이용, 제공 등 회원정보를 처리할 경우 회원으로부터 사전에 동의를 받는 등 개인정보 관련 법령 등에 따른 요건을 준수합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원이 회사의 서비스들을 유용하고 편리하게 이용할 수 있도록 하기 위해 본 약관에 정해진 절차에 따라 회원정보를 이용할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원이 제휴서비스를 유용하고 편리하게 이용할 수 있도록 회사와 제휴한 업체(이하 “제휴업체”라 합니다)에 회원정보를 제공할 수 있습니다. 다만, 관련 법령상 특별한 규정이 있는 경우를 제외하고는 회원에게 제휴업체, 제공 목적, 제공할 회원정보 항목, 제휴업체의 회원정보 보유기간, 동의 거부권의 존재 및 동의거부시의 불이익 등을 사전에 고지하고 동의를 얻은 경우에 한합니다.
    </li>
    <li>
        회사는 수집한 회원정보를 해당 회원이 회원자격을 유지하는 기간 동안 보유, 이용할 수 있습니다. 회원이 탈퇴(이용계약 해지)하거나 회원자격을 상실한 경우 회사는 회원과의 분쟁 발생 등을 대비하여 회원 탈퇴 또는 자격상실 후 5년 또는 회사가 별도로 정한 기간 동안 회원정보를 보관할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 관계법령에서 정한 경우를 제외하고는 전자금융거래를 수행함에 있어 알게 된 다음 각 호의 어느 하나에 해당하는 고객정보를 고객의 동의를 얻지 아니하고 타인에게 제공하거나 업무상 목적 외에 사용하지 않습니다.<br>
        &nbsp;
        <ol>
            <li>
                고객의 인적사항
            </li>
            <li>
                고객의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료
            </li>
        </ol>
    </li>
    <li>
        회사는 개인정보의 수집, 이용, 제공, 보호, 위탁 등에 관한 제반 사항 등을 개인정보처리방침을 통해 규정하며, 개인정보처리방침은 플루토스 플랫폼 웹페이지, 모바일 앱 등을 통하여 게시합니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제11장 손해배상 및 면책조항</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제40조 (사고 신고)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원은 인증수단 등의 분실/도난, 외부 누설, 제3자에 의한 위/변조 등의 사실이 발생하였거나, 발생한 사실을 알게 된 경우 지체없이 회사에 신고하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        제1항의 규정에 따른 신고의 효력 발생시기는 회사가 이를 접수한 때로부터 발생합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제41조 (손해배상 및 면책조항)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 본 약관에서 규정한 TO 거래절차를 따르지 않은 거래로 인해 발생한 사고 또는 법적분쟁에 대해서 책임을 지지 않으며, 매도자 또는 매수자의 고의 또는 과실로 인해 발생한 분쟁에 대해서도 책임을 지지 않습니다.<br>
        &nbsp;
    </li>
    <li>
        제휴업체의 고의 또는 과실로 인해 회원에게 발생한 피해는 해당 제휴업체의 약관에 따릅니다.<br>
        &nbsp;
    </li>
    <li>
        회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 다음 각 호의 어느 하나에 해당하는 사고로 인하여 회원에게 손해가 발생한 경우에는 그 손해를 배상할 책임을 부담합니다. 다만, 회사의 책임 있는 사유에 의하여 발생하지 않거나 회원에게 귀책사유가 있는 경우 그러하지 아니합니다.<br>
        &nbsp;
        <ol>
            <li>
                접근매체의 위조나 변조로 발생한 사고
            </li>
            <li>
                계약체결 또는 거래지시의 전자적 전송이나 처리 과정에서 발생한 사고
            </li>
            <li>
                전자금융거래를 위한 전자적 장치 또는, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제2조 제1항 제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고
            </li>
        </ol>
    </li>
    <li>
        다음 각 호의 어느 하나에 해당하는 사유로 회원에게 손해가 발생하였을 경우, 회사가 회원의 손해발생을 방지하기 위하여 필요한 관리자의 주의를 다한 경우 그 손해에 대하여 책임을 지지 아니합니다.<br>
        &nbsp;
        <ol>
            <li>
                정부기관의 행정지도 또는 법상 행정처분 및 명령 등의 준수로 인한 경우
            </li>
            <li>
                전기통신사업법에 의한 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애로 인한 경우
            </li>
            <li>
                회사가 관리할 수 없는 외주 시스템의 하자 또는 회원의 귀책 사유로 인하여 서비스에 장애가 발생한 경우
            </li>
        </ol>
    </li>
    <li>
        회사가 다음 각 호의 사항을 이행한 경우, 제5항의 회사에게 필요한 관리자의 주의를 다한 것으로 봅니다.<br>
        &nbsp;
        <ol>
            <li>
                정보보호 최고책임자 선임 및 관리
            </li>
            <li>
                정보 보호교육 실시
            </li>
            <li>
                전산실, 전산실이 위치한 건물 내 시설, 정보 보호시스템 및 정보처리시스템에 대한 보호 대책 마련
            </li>
            <li>
                암호키 관리 방안 마련
            </li>
            <li>
                정보보안사고 대응 방안 수립
            </li>
            <li>
                정보보호 대책 및 보안사고 대응방안 등에 대한 정기적인 점검
            </li>
            <li>
                「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따른 정보보호 관리체계 인증
            </li>
            <li>
                기타 정보보호 관련 법률에서 준수하도록 정하는 사항
            </li>
        </ol>
    </li>
    <li>
        회사는 안정적인 서비스 제공 및 보안을 위하여 정기 점검 및 긴급 점검을 할 수 있습니다. 정기 점검의 경우 전자적 장치를 통하여 서비스가 중단되기 24시간 전, 긴급 점검의 경우 회사가 할 수 있는 가장 빠른 시점에 공지를 해야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 전산장애(플루토스 플랫폼의 장애 또는 오류가 발생한 경우 포함), 순간적인 모바일 앱 접속 증가, 일부 종목의 주문 폭주 등으로 서버의 장애가 예상되는 경우 필요 최소한의 범위에서 주문 접수정지, 거래중단, 입출금중단 등 필요한 조치를 할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제42조 (대리 및 보증의 부인)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사는 TO 매매와 관련하여 투자회원을 대리할 권한을 갖고 있지 않습니다. 또한 TO 매매와 관련한 회사의 행위는 투자회원을 위한 대리행위와 무관합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회사가 제공하는 서비스를 통하여 이루어지는 투자회원 간의 매매와 관련하여 매도의사 또는 매수의사의 사실 및 진위, 적법성을 보증하지 않습니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제43조 (서비스 중단 시 피해 방지 방안)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        플루토스 플랫폼은 혁신금융서비스 시험운영 기간의 경과 또는 감독당국의 행정지도에 따라 서비스 제공이 중단될 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        부동산의 가치상승 또는 평가손실에 따른 부동산 매각처분으로 신탁계약의 종료 전에 투자금의 조기상환을 위해 TO의 거래정지 또는 상장폐지가 이루어질 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        서비스 제공이 중단되거나 상장폐지가 이루어질 경우, 즉시 자산 매각을 통하여 TO 투자자들에게 청산 절차를 진행할 계획이며, 투자자의 지분만큼 매각대금을 환급합니다. 다만, 이 경우 투자원금의 일부 또는 전부에 대한 손실의 위험이 존재하며, TO는 「예금자보호법」에 의한 보호를 받지 않는 상품으로 투자원금의 손실이 발생할 수 있으므로 투자에 신중을 기하여 주시기 바랍니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제12장 위험고지 및 분쟁 해결</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제44조 (위험고지)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        본 서비스는 시험 운영중으로, 서비스 시험운영 중에 예상하지 못했던 위험이 발생할 수 있습니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 회원이 플루토스 플랫폼 회원가입 시 본 약관의 확인 및 동의를 함으로써 시험 운영하는 본 서비스를 제공받는 것에 사전 동의한 것으로 봅니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제45조 (민원처리 및 분쟁조정)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원은 서비스의 이용과 관련하여 전자우편, 우편, 전화, 그 밖에 전자적 장치를 통한 방법으로 회사에 민원을 제기할 수 있으며, 회사와 분쟁이 발생한 경우 분쟁조정을 신청할 수 있습니다.
    </li>
    <li>
        회원이 제1항에 따라 신청한 민원 또는 분쟁조정 신청은 회사의 「금융소비자보호기준」 및 「분쟁조정위원회 규정」에서 정한 절차에 따라 처리됩니다.<br>
        &nbsp;
    </li>
    <li>
        회원은 회사의 민원 또는 분쟁조정의 처리 결과에 대하여 이의가 있을 경우 「금융혁신지원 특별법」 제28조 제2항에 따른 금융감독원의 금융분쟁조정위원회나 소비자기본법에 따른 한국소비자원의 소비자분쟁조정위원회에 회사의 전자금융거래서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">제13장 기타</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제46조 (통지 방법 및 효력)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사가 본 약관에 따라 플루토스 플랫폼 서비스와 관련된 사항을 회원에게 통지하는 경우에는 회원이 신고한 연락처(전자우편, 우편, 전화, 팩스, 문자, 모바일 메신저, 인터넷 메신저, SNS 등 그 밖에 전자적 장치)로 통지하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사의 의사표시는 그 통지가 도달되어야 효력이 생깁니다. 다만, 서면으로 통지한 경우 통상적인 우편 송달기간이 지났을 때 도달된 것으로 추정하며, 회원이 위 기간이 경과한 후 상당기간 내에 회사에 이의를 제기하지 아니하는 때에는 도달된 것으로 봅니다.<br>
        &nbsp;
    </li>
    <li>
        회원이 본 약관 제11조 제2항에 따른 통지를 하고자 하는 경우 회사의 전화, 우편, 전자우편, 플루토스 플랫폼에서 제공하는 전자적 장치로 통지하여야 하며, 전항을 준용합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제47조 (서비스에 관한 권리)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">회원은 서비스 이용 권한을 제3자에게 어떤 형태로든 양도 또는 기타의 방법으로 처분할 수 없습니다. 회사가 회원에게 제공하는 서비스(데이터, 소프트웨어, 출판물의 저작권 등)에 관한 모든 권리는 회사에게 귀속됩니다.</span><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제48조 (관계법규 등 준수)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회원과 회사는 「금융혁신지원 특별법」, 같은 법 시행령 및 시행규칙, 혁신금융심사위원회 운영 등에 관한 규정, 기타 혁신금융서비스 제공을 위하여 지켜야 하는 관련 법령 등을 준수하여야 합니다.<br>
        &nbsp;
    </li>
    <li>
        회사는 해당 종목과 관련한 신탁계약을 투자회원이 언제든지 열람할 수 있도록 플루토스 플랫폼에 공시하여야 합니다. 투자회원과 회사는 위 신탁계약의 내용을 인지하고 이를 준수하여야 합니다.<br>
        &nbsp;
    </li>
</ol>
<p>
    <span style="color:black; font-weight:bold">제49조 (관할법원 및 준거법)</span><br>
    &nbsp;
</p>
<ol>
    <li>
        회사와 회원 간에 발생한 분쟁에 관하여 회사와 회원 사이에 소송이 발생한 경우 그 관할법원은 민사소송법에 따르기로 합니다.<br>
        &nbsp;
    </li>
    <li>
        본 약관에 관한 분쟁 및 회사의 서비스 이용과 관련하여서는 대한민국 법률을 적용합니다.<br>
        &nbsp;
    </li>
</ol>
<p style="text-align:center;">
    <h3 style="text-align:center">부칙</h3><br>
    &nbsp;
</p>
<p>
    <span style="color:black; font-weight:bold">제1조 (시행일)</span><br>
    &nbsp;
</p>
<p>
    <span style="background-color:#FDFDFD;color:#666666;">이 약관은 2023년 10월 1일부터 시행합니다.</span><br>
    <br>
    <br>
    <br>
    <br>
    <br>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
`;

export const TERMS_PRIVACY = `
<p>
    <span style="color:#626262;">주식회사 플루토스파트너스(이하 ‘회사’)은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보 보호 및 권익을 보호하고 이와 관련한 정보주체의 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같은 처리방침을 수립•공개합니다.</span><br>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제1조 (개인정보의 처리 목적)</span><br>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">회사는 개인정보를 다음 각 호의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 관련 법령상 허용되는 경우가 아닌 한 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</span><br>
    &nbsp;
</p>
<p>
    <strong>회원 가입 및 관리 목적</strong><br>
    회원가입, 회원제 서비스 이용, 제한적 본인 확인제에 따른 본인확인, 개인식별, 부정이용방지, 비인가 사용방지, 가입의사 확인, 사고조사, 분쟁해결, 민원처리 및 고지사항 전달 목적으로 개인정보를 처리합니다.<br>
    &nbsp;
</p>
<p>
    (상품)거래관계 관련 목적<br>
    (상품)거래관계의 설정ㆍ유지ㆍ이행ㆍ관리, 사고조사, 분쟁해결, 민원처리 및 법령상 의무이행 목적으로 개인정보를 처리합니다.<br>
    *거래라 함은 회사에서 판매하는 상품과 관련된 거래를 의미합니다.<br>
    &nbsp;
</p>
<p>
    온라인 거래 관련 목적<br>
    「전자금융거래법」 제21조, 제22조에 의해 전자금융거래의 내용 추적 및 검색, 보안정책 수립용 통계 자료로 활용 목적으로 개인정보를 처리합니다.
</p>
<p>
    &nbsp;
</p>
<p>
    상품 및 서비스 홍보와 안내 목적<br>
    회원 만족도 조사를 통한 신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고의 게재, 서비스의 유효성 확인, 경품지급, 사은행사 등 회원의 편의 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계 목적으로 개인정보를 처리합니다.<br>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제2조 (개인정보의 수집 항목, 보유 및 이용기간)</span><br>
    &nbsp;
</p>
<p>
    회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
</p>
<p>
    제공의 경우 제공받는 기관이 준수하여야 하는 관련 법령상의 보존기간을 따릅니다.
</p>
<p>
    각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#111111;">* 관련 법령상 의무이행 보유기간</span>
</p>
<figure class="table" style="width:561.55pt;">
    <table>
        <thead>
            <tr>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">보유정보</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">보유기간</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">근거법령</span>
                    </p>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">주문기록, 매매명세 등 거래 관련 정보</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">10년</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">「자본시장과 금융투자업에 관한 법률」 제60조</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.1pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계약서, 거래신청서 등 영업에 관한 중요서류</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.1pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">10년</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.1pt;padding:9.0pt;" rowspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">「상법」 제33조</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">전표 또는 이와 유사한 서류</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">5년</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">전자금융 거래에 관한 기록</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">5년</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">「전자금융거래법」 제22조</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">표시•광고에 관한 기록</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">6개월</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;" rowspan="4">
                    <p style="text-align:center;">
                        <span style="color:#888888;">「전자상거래 등에서의 소비자보호에 관한 법률」 제6조</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.1pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계약 또는 청약철회 등에 관한 기록</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.1pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">5년</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">대금결제 및 재화 등의 공급에 관한 기록</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">5년</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">소비자의 불만 또는 분쟁처리에 관한 기록</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">3년</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">서비스를 이용하기 위해 접속한 기록</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">3개월</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.65pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">「통신비밀보호법」 제15조의2</span>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</figure>
<p>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제3조 (개인정보의 제3자 제공)</span>
</p>
<p>
    <br>
    회사는 원칙적으로 회원의 개인정보를 제1조에서 명시한 목적 범위 내에서 처리하며, 회원의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 다만, 다음의 각 호의 경우에는 회원 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    회원이 사전에 제3자 제공 및 공개에 동의한 경우
</p>
<p>
    &nbsp;
</p>
<p>
    다른 법률에 특별한 규정이 있는 경우
</p>
<p>
    &nbsp;
</p>
<p>
    회원 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 회원 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
</p>
<p>
    &nbsp;
</p>
<p>
    회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어 필요 최소한의 개인정보를 제공하고 있습니다.
</p>
<p>
    <span style="color:#626262;">&nbsp;</span>
</p>

<div style="overflow : auto">
<table >
        <colgroup><col style="width:3.73%;"><col style="width:26.03%;"><col style="width:5.47%;"><col style="width:13.48%;"><col style="width:35.5%;"><col style="width:15.79%;"></colgroup>
        <thead>
            <tr>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.5pt;padding:9.0pt;width:160.45pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#222222;">제공받는자</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.5pt;padding:9.0pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#222222;">제공목적</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.5pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">제공 항목</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:13.5pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">보유 및 이용기간</span>
                    </p>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:26.5pt;padding:9.0pt;width:160.45pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:red;">엔에이치투자증권 주식회사</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:26.5pt;padding:9.0pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계좌 개설</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:26.5pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">연계정보(CI)</span><br>
                        <span style="color:#888888;">계좌번호</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:26.5pt;padding:9.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계좌관리기관의 개인정보 보유 및 이용기간에 따름</span><br>
                        <span style="color:#888888;">* 단, 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:89.7pt;padding:.75pt;width:10.1pt;" rowspan="4">
                    &nbsp;
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:89.7pt;padding:9.0pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">출금 요청</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:89.7pt;padding:9.0pt;width:263.2pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계좌번호</span><br>
                        <span style="color:#888888;">계좌비밀번호</span><br>
                        <span style="color:#888888;">출금가능금액</span><br>
                        <span style="color:#888888;">금융기관코드</span><br>
                        <span style="color:#888888;">이체계좌번호</span><br>
                        <span style="color:#888888;">이체금액</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:89.7pt;padding:9.0pt;width:142.2pt;" rowspan="4">
                    <p style="text-align:center;">
                        <span style="color:#888888;">금융거래 종료일로부터 5년</span><br>
                        <span style="color:#888888;">* 단, 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:7.05pt;padding:9.0pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">수익증권의 배정, 입고, 말소</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:7.05pt;padding:9.0pt;width:263.2pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계좌번호</span><br>
                        <span style="color:#888888;">수익증권 잔고</span><br>
                        <span style="color:#888888;">청약금액</span><br>
                        <span style="color:#888888;">수익증권 수익금</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:7.05pt;padding:9.0pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">매매 체결</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:7.05pt;padding:9.0pt;width:263.2pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계좌번호</span><br>
                        <span style="color:#888888;">수익증권 거래내역</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:7.05pt;padding:9.0pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">소득세에 대한 원천징수 및 신고</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;height:7.05pt;padding:9.0pt;width:263.2pt;" colspan="2">
                    <p style="text-align:center;">
                        <span style="color:#888888;">계좌번호</span><br>
                        <span style="color:#888888;">수익증권 거래내역</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style=";">
                    &nbsp;
                </td>
                <td style=";">
                    &nbsp;
                </td>
                <td style=";">
                    &nbsp;
                </td>
                <td style=";">
                    &nbsp;
                </td>
                <td style=";">
                    &nbsp;
                </td>
                <td style=";">
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>

</div>
<p>
    <span style="color:#111111;">&nbsp;</span>
</p>
<div class="page-break" style="page-break-after:always;">
    <span style="display:none;">&nbsp;</span>
</div>
<p>
    <span style="color:#111111;">&nbsp;</span>
</p>
<p>
    <span style="font-weight:bold; color:black;">제4조 (개인정보처리의 위탁)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">회사는 회원의 동의를 받아 다음 각 호와 같이 개인정보의 처리를 위탁하고 있습니다.</span>
</p>
<figure class="table" style="width:659.4pt;">
    <table>
        <thead>
            <tr>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:160.0pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">수탁업체명</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:266.5pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">위탁업무 내용</span>
                    </p>
                </th>
                <th style="background-color:#F8F8F8;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:232.9pt;">
                    <p style="text-align:center;">
                        <span style="color:#222222;">보유 및 이용기간</span>
                    </p>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:160.0pt;">
                    <p style="text-align:center;">
                        <span style="color:red;">Cafe24</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:266.5pt;">
                    <p style="text-align:center;">
                        <span style="color:red;">클라우드 서버 운영 및 관리</span>
                    </p>
                </td>
                <td style="border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:.75pt;width:232.9pt;" rowspan="4">
                    <span style="color:red;">&nbsp;-회원탈퇴</span><br>
                    <span style="color:red;">-예외: 계좌번호 및 금융거래 관련하여 관련법령에 의거하여 보존할 필요가 있는 정보는 해당 법령에서 요구하는 기한까지 보관</span>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:160.0pt;">
                    <p style="text-align:center;">
                        <span style="color:red;">드림시큐리티</span>
                    </p>
                    <p style="text-align:center;">
                        <span style="color:red;">&nbsp;</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:266.5pt;">
                    <p style="text-align:center;">
                        <span style="color:red;">본인인증서비스</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:160.0pt;">
                    <p style="text-align:center;">
                        <span style="color:red;">엔에이치투자증권</span>
                    </p>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:266.5pt;">
                    <p style="text-align:center;">
                        <span style="background-color:white;color:red;">수익자 명부 관리</span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:160.0pt;">
                    <span style="color:#888888;">&nbsp;</span>
                </td>
                <td style="background-color:white;border-bottom:1.0pt solid #EBEBEB;border-left:1.0pt solid #EBEBEB;border-right-style:none;border-top-style:none;padding:9.0pt;width:266.5pt;">
                    <p style="text-align:center;">
                        <span style="color:#888888;">&nbsp;</span>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</figure>
<p>
    &nbsp;
</p>
<p>
    회사는 위탁계약 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적•관리적 보호조치, 재위탁 제한 등 책임에 관한 사항을 계약서 등 문서에 명확히 규정하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    위탁업무의 내용 또는 수탁자 변경 시 본 개인정보 처리방침을 통해 고지하겠습니다.
</p>
<p style="margin-left:36.0pt;">
    <span style="color:#626262;">&nbsp;</span>
</p>
<p style="margin-left:36.0pt;">
    <span style="color:#626262;">&nbsp;</span>
</p>
<p>
    <span style="font-weight:bold; color:black;">제5조 (개인정보의 파기절차 및 파기방법)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 단, 다음 각 호의 사유가 있을 경우에는 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 일정기간 보존 후 파기합니다.
</p>
<p>
    &nbsp;
</p>
<p>
    법령에 따라 보존하여야 하는 경우<br>
    * 제2조 관련 법령상 의무이행 보유기간 참조
</p>
<p>
    &nbsp;
</p>
<p>
    기타 이와 유사한 정당한 사유가 있는 경우
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보 파기의 절차 및 방법은 다음과 같습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    파기절차<br>
    회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
</p>
<p>
    &nbsp;
</p>
<p>
    파기방법<br>
    개인정보가 기록된 출력물, 서면 등은 파쇄 또는 소각의 방법으로 파기하고, 전자적 파일형태의 개인정보는 복원이 불가능한 방법으로 영구 삭제하는 방법으로 파기합니다.
</p>
<p>
    <span style="color:#111111;">&nbsp;</span>
</p>
<p>
    <span style="color:#111111;">&nbsp;</span>
</p>
<p>
    <span style="font-weight:bold; color:black;">제6조 (정보주체의 권리•의무 및 그 행사방법)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    회원은 회사에 대해 언제든지 개인정보의 열람•정정•삭제•처리정지 요구 등의 권리를 행사할 수 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보의 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    회사는 회원의 권리에 따른 열람의 요구, 정정•삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제7조 (개인정보의 안전성 확보 조치)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">회사는 「개인정보 보호법」 제29조에 따라 다음 각 호와 같이 안전성 확보에 필요한 관리적•기술적•물리적 조치를 하고 있습니다.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    관리적 조치
</p>
<p>
    &nbsp;
</p>
<p>
    내부관리계획 수립 및 시행
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    기술적 조치
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보처리시스템 접근권한 관리: 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근 통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보의 암호화: 회원의 개인정보인 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알 수 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    해킹 등에 대비한 기술적 대책: 회사는 해킹이나 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하여 주기적인 갱신•점검을 하며 외부로부터의 접근이 통제된 구역에 시스템을 설치하는 등 기술적•물리적으로 감시 및 차단하고 있습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    물리적 조치: 전산실, 자료 보관실 등의 접근 통제
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제8조 (개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    전자금융거래법에 의거 전자금융/온라인거래 시 필요 정보를 수집할 수 있습니다. 그에 따라 수집하는 정보의 항목과 이용 목적은 다음 각호와 같습니다.
</p>
<p>
    &nbsp;
</p>
<p>
    전자금융거래의 내용 추적 및 검색 : 회원 ID, 접속일시, IP Address, MAC Address
</p>
<p>
    &nbsp;
</p>
<p>
    보안정책 수립용 통계 자료 : 운영체제 종류
</p>
<p>
    &nbsp;
</p>
<p>
    브라우저 버전, 키보드 타입 등 쿠키 이용에 대한 선택권은 회원께서 가지고 있습니다. 또한, 회원님의 웹브라우저에서 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부하는 등의 옵션을 선택하실 수 있습니다. 단, 쿠키의 저장을 거부하는 옵션을 선택하시는 경우에는 서비스 이용에 불편이 야기될 수 있습니다.<br>
    &nbsp;
</p>
<p>
    * 쿠키설정 거부방법<br>
    - 사용하는 웹 브라우저의 옵션을 선택함으로써 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나 모든 쿠키의 저장을 거부할 수 있습니다.<br>
    - Microsoft Edge 브라우저의 경우 설정 예시 : Edge 브라우저 우측 상단의 설정 및 기타( ••• 모양) &gt; 설정 &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터 관리 및 삭제<br>
    - Chrome 브라우저의 경우 설정 예시 : Chrome 브라우저 우측 상단의 더보기(︙모양) &gt; 설정 &gt; 보안 및 개인정보 보호 &gt; 쿠키 및 기타 사이트 데이터
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제9조 (행태정보의 수집•이용•제공 및 거부 등에 관한 사항)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">회사는 온라인 맞춤형 광고 등을 위한 행태정보를 수집•이용•제공하지 않습니다.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제10조 (개인정보 보호책임자)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">「개인정보 보호법」 제31조 제1항에 따른 회사의 개인정보 보호책임자는 다음과 같습니다.</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;-부서: OOOO</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;-성명 및 직책: OOOO</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;-연락처: 02-000-0000</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;</span>
</p>
<p>
    <span style="font-weight:bold; color:black;">&nbsp;제11조 (개인정보 열람청구)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">회원은 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 회원의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;-부서: OOOO</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;-성명 및 직책: OOOO</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;-연락처: 02-000-0000</span>
</p>
<p>
    <span style="color:#626262;">&nbsp;</span>
</p>
<p>
    <span style="font-weight:bold; color:black;">제12조 (권익침해 구제방법)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">기타 개인정보침해로 인한 신고나 분쟁해결, 상담이 필요하신 경우 아래 기관에 문의하시기 바랍니다.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)
</p>
<p>
    한국인터넷진흥원 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
</p>
<p>
    대검찰청 (www.spo.go.kr / 국번없이 1301)
</p>
<p>
    경찰청 (ecrm.police.go.kr / 국번없이 182)
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="font-weight:bold; color:black;">제13조 (개인정보 처리방침의 변경)</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="color:#626262;">회사가 개인정보 처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용을 지속적으로 공개하며, 변경된 내용은 회원이 쉽게 확인할 수 있도록 변경 전ㆍ후를 비교하여 공개합니다.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    이 개인정보 처리방침은 2023년 10월 1일부터 시행됩니다.
</p>
<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
`;

export const THIRD_PARTY_PRIVACY = `
<p><strong style="color:black; font-weight: bold;">1. 개인정보를 제공 받는자</strong></p>
<p>&nbsp;</p>
    <p>&nbsp;- 엔에이치투자증권 주식회사</p>
    <p>&nbsp;</p>
<p><strong style="color:black; font-weight: bold;">2. 개인정보를 제공 받는자의 개인정보 이용 목적</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;- 상품거래를 위한 계좌계설</p>
    <p>&nbsp;</p>
    <p>&nbsp;- 계좌의 출금 및 입금관리</p>
    <p>&nbsp;</p>
    <p>&nbsp;- 상품거래 TO 매수/매도</p>
    <p>&nbsp;</p>
    <p>&nbsp;- 소득세에 대한 원천징수 및 신고</p>
    <p>&nbsp;</p>
    <p>&nbsp;- TO 배정, 입고, 말소</p>
    <p>&nbsp;</p>
<p><strong style="color:black; font-weight: bold;">3. 제공하는 개인정보 항목</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;- 계좌번호, 계좌비밀번호, 금융기관코드, 이름, 생년월일, 휴대폰전화번호, 이메일</p>
    <p><strong>&nbsp;</strong></p>
<p><strong style="color:black; font-weight: bold;">4. 개인정보를 제공받는자의 개인정보 보유 및 이용기관</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;-회원탈퇴<br>&nbsp;</p>
    <p>&nbsp;-예외: 계좌번호 및 금융거래 관련하여 관련법령에 의거하여 보존할 필요가 있는 정보는 해당 법령에서 요구하는 기한까지 보관</p>
<p>&nbsp;</p>
<p>* 귀하는 위의 개인정보 제3자에 대한 제공 동의를 거부하실 수 있습니다. 위 개인정보는 플랫폼 이용에 필수적인 사항이므로 거부 시 플랫폼 이용에 제약이 있을 수 있습니다.</p>`;

export const PERSONAL_INFO = `
<p>주식회사 플루토스파트너스(이하 “회사”)는 <span style="color:black;">플루토스 플랫폼 가입자 </span>개인정보 보호를 위하여 개인정보 보호지침을 마련하고 이를 준수하고 있습니다. 가입자 정보는 아래 수집목적에 따라 사용되고, 원하시는 경우 이메일이나 문자, 카카오톡 등을 통한 정보알림 또는 기타 서비스의 제공 등에 활용될 수 있습니다.</p><p>&nbsp;</p>
<p style="font-weight:bold; color:black;">1. 수집·이용 목적</strong><br>&nbsp;</p><p>- 서비스 회원가입 및 이용</p><p>- 플랫폼 공지 및 상품정보 제공<br>&nbsp;</p><p style="font-weight:bold; color:black;">2. 수집항목<br>&nbsp;</p><p>- 이름, 휴대전화번호, 이메일, 생년월일, 성별</p><p>&nbsp;</p><p style="font-weight:bold; color:black;">3. 보유/이용기간<br>&nbsp;</p>
<p>- 회원탈퇴<br>(관련법령에 의거하여 보존할 필요가 있는 정보는 해당 법령에서 요구하는 기한까지 보관)</p><p>&nbsp;</p>
<p>* 귀하는 위의 개인정보 수집∙이용에 대한 동의를 거부하실 수 있습니다. 위 개인정보는 플랫폼 이용에 필수적인 사항이므로 거부 시 플랫폼 이용에 제약이 있을 수 있습니다.</p>
`;

export const IDENTITY_VERIFICATION = `
<p><span style="color:black; font-weight: bold;">제1조 (목적)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            &nbsp;이 약관은 본인확인서비스 대행기관인 주식회사 드림시큐리티(이하 '회사'라 합니다)와 본인확인서비스 이용자(이하 '이용자'라 합니다) 간에 본인확인서비스 이용에 관한 회사와 이용자의 권리와 의무, 기타 제반 사항을 정함을 목적으로 합니다.
        </li>
        <li>
            &nbsp;
        </li>
    </ul>
<p><span style="color:black; font-weight: bold;">제2조 (용어의 정리)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            <p>&nbsp;① "본인확인서비스"라 함은 이용자가 유무선 인터넷의 웹사이트 및 스마트폰 Application 등(이하 "사이트"라 합니다.)에서 본인 명의로 개통한 휴대폰을 이용하여, "본인확인정보"를 입력하고 인증 절차를 통하여 본인 여부와 본인이 등록한 정보의 정확성을 확인하여 주는 서비스를 말합니다</p>
            <p>&nbsp;</p>
            <p>&nbsp;② "본인확인정보"라 함은 이용자가 입력한 본인의 생년월일, 성별, 성명, 내/외국인 여부, 이동통신사, 본인명의로 개통된 휴대폰번호, 기타 본인확인기관과 이용자간에 별도로 설정한 번호 등 "이용자"의 본인 여부 확인에 필요한 정보를 말합니다.</p>
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;③ "이용자"라 함은 "사이트"에서 본인확인기관이 제공하는 "본인확인서비스"를 이용하는 자를 말합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;④ "본인확인기관"이라 함은 "본인확인서비스" 관련 법령에 따라 주민등록번호를 수집 • 이용하지 아니하고, "사이트"에서 주민등록번호를 사용하지 아니하고 본인을 확인할 수 있도록 해주는 방법을 개발 • 제공 • 관리하는 업무를 담당하는 사업자를 말합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;⑤ "대행기관"은 본인확인기관을 대신하여 "이용자"가 "사이트"에서 "본인확인서비스"를 제공받을 수 있도록 "사이트"와 본인확인기관간의 "본인확인서비스"를 중계하고 "이용자"에게 이용방법의 안내와 문의 등 지원업무를 담당하여서, "사이트"에서 "이용자"에게 &nbsp;"본인확인서비스"를 대행하여 제공하는 사업자를 말합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;⑥ "사이트"라 함은 유무선 인터넷의 Web사이트, 스마트폰 Application(Apps)을 통하여 "이용자"에게 서비스, Contents, Point 등의 각종 재화와 용역을 유/무료로 제공하는 사업자 및 기관, 단체를 말합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;
        </li>
    </ul>
<p><span style="color:black; font-weight: bold;">제3조 (약관의 명시 및 변경)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            &nbsp;① 회사는 본 약관을 서비스 초기 화면에 게시하여 이용자가 본 약관의 내용을 확인할 수 있도록 합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;② 회사는 필요하다고 인정되는 경우 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 서비스 화면에 적용일자 14일 전부터 공지합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;③ 회사가 전항에 따라 변경 약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용일 까지 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 명확하게 공지 또는 통지하였음에도 이용자가 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면 &nbsp;이용자가 변경 약관에 동의한 것으로 간주합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;④ 이용자 또는 사이트가 변경된 약관에 대한 내용을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;⑤ 회사의 약관은 개인정보보호 등을 규정한 정보통신 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령에서 정한 절차와 범위 내에서만 유효합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;
        </li>
    </ul>
<p><span style="color:black; font-weight: bold;">제4조 (본인확인서비스 제공시간)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            &nbsp;① 본인확인서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 정기 점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 각 사이트의 기술상, 운영상의 사유와 목적에 따라 일시 중지될 수 있습니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;② 회사는 본인확인서비스 중지에 따라 이용자에게 별도의 보상은 하지 않습니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;
        </li>
    </ul>
<p><span style="color:black; font-weight: bold;">제5조 (회사의 권리와 의무)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            &nbsp;① 회사는 본인확인서비스 대행과 관련하여 인지한 이용자의 본인확인정보를 본인의 승낙 없이 제3자에게 누설하거나 배포하지 않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있는 경우 등 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우에는 &nbsp;그러하지 않습니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;② 회사는 이용자에게 안정적인 본인확인서비스 대행을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방점검, 유지보수 등을 이행하며, 본인확인서비스의 장애가 발생하는 경우, 이를 지체 없이 수리 및 복구합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;③ 회사는 서비스의 안전성과 신뢰성, 보안성을 확보하기 위하여 개인정보 처리시스템의 해킹방지시스템 및 보안관리 체계 운영 등 기술적, 관리적 조치를 취합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;④ 회사는 서버 및 통신기기의 정상작동여부 확인을 위하여 정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한 모니터링 체계를 갖춥니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;⑤ 회사는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및 프로그램을 설치하여 운영합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;&nbsp; 1. 침입 차단 및 탐지시스템 설치
        </li>
        <li>
            <p>&nbsp;&nbsp; 2. 그 밖에 필요한 보호장비 또는 암호프로그램 등 정보보호시스템 &nbsp;&nbsp;&nbsp;설치</p>
            <p>&nbsp;</p>
            <p>&nbsp;⑥ 회사는 컴퓨터바이러스 감염을 방지하기 위하여 바이러스 방지를 위한 방어, 탐색, 복구 절차를 자체적으로 운영합니다.</p>
        </li>
    </ul>
<p>&nbsp;</p>
<p><span style="color:black; font-weight: bold;">제6조 (이용자의 권리와 의무)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            &nbsp;① 이용자는 서비스를 이용함에 있어서 다음 각호에 해당하는 행위를 하여서는 안되며, 회사는 위반 행위에 따르는 일체의 법적 책임을 지지 않습니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;&nbsp; 1. 본인이 아닌 타인의 본인확인정보를 부정하게 사용 및 도용하는 행위
        </li>
        <li>
            &nbsp;&nbsp; 2. 회사 및 본인확인기관, 사이트의 저작권, 제3자의 저작권 등 기타 &nbsp;&nbsp;&nbsp;권리를 침해하는 행위
        </li>
        <li>
            &nbsp;&nbsp; 3. 법령에 규정하는 제반 범죄 및 위법 행위
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;② 이용자는 본 약관에서 규정하는 사항과 서비스에 대한 이용안내 또는 주의사항 등을 준수하여야 합니다.&nbsp;
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;③ 이용자는 이용자 본인의 접근매체, 본인확인정보의 분실, 유출, 누설없이 본인 스스로 성실히 관리하여야 합니다.
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;④ 이용자는 회사의 서비스 고객센터를 통하여 관련 문의를 할 수 있습니다.
        </li>
        <li>
            &nbsp;《회사의 서비스 고객센터 연락처 : 1899-4134,&nbsp;<a href="http://www.mobile-ok.com/"><span style="color:blue;">www.mobile-ok.com</span></a>》
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;⑤ 이용자는 본인확인서비스가 자신의 의사에 반하여 특정 사이트에 제공되었음을 안 때에는 본인확인기관 또는 회사를 통하여 자신의 본인확인정보 삭제를 요구할 수 있으며, 본인확인기관 또는 회사는 그 정정요구를 받은 날부터 2주 이내에 처리 결과를 알려 주어야 합니다.
        </li>
        <li>
            &nbsp;《회사의 서비스 고객센터 연락처 : 1899-4134,&nbsp;<a href="http://www.mobile-ok.com/"><span style="color:blue;">www.mobile-ok.com</span></a>》
        </li>
        <li>
            &nbsp;
        </li>
        <li>
            <span style="color:black; font-weight: bold;">제7조 (이용자의 개인정보보호)</span>
            <p>&nbsp;</p>
        </li>
        <li>
            &nbsp;① 회사는 본인확인서비스를 대행함에 있어 취득한 이용자의 정보 또는 자료를 이용자의 동의 없이 제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지 않습니다.
        </li>
        <li>
            &nbsp;② 이용자의 개인정보 보호는 회사가 관련 법령과 회사가 수립하여 운영하는 개인정보 취급방침에 따릅니다. 자세한 회사의 개인정보 제공 범위와 보호 방침, 위탁은 서비스 홈페이지(<a href="http://www.mobile-ok.com/"><span style="color:blue;">www.mobile-ok.com</span></a>)에 제공되는 개인정보 취급방침을 참조하시기 바랍니다.
        </li>
    </ul>
<p>&nbsp;</p>
<p><span style="color:black; font-weight: bold;">제8조 (약관 외 준칙)</span></p>
<p>&nbsp;</p>
    <ul>
        <li>
            &nbsp;본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 등 기타 관련 법령 또는 상관례에 따릅니다.
        </li>
    </ul>
<p>&nbsp;</p>
<p><span style="color:black; font-weight: bold;">부칙</span></p>
<p>&nbsp;</p>
<ul>
    <li>
        (시행일) 이 약관은 공시한 날로부터 시행합니다.
    </li>
</ul>`;

export const MARKETING = `
<p>주식회사 플루토스파트너스(이하 “회사”)는 <span style="color:black; font-weight: bold;">플루토스 플랫폼 가입자 </span>개인정보 보호를 위하여 개인정보 보호지침을 마련하고 이를 준수하고 있습니다. 가입자는 정보는 아래 수집목적에 따라 사용되고, 원하시는 경우 이메일이나 문자, 카카오톡 등을 통한 정보알림 또는 기타 서비스의 제공 등에 활용될 수 있습니다.</p>
    <p>&nbsp;</p>
<p><strong style="color:black; font-weight: bold;">1. 수집·이용 목적</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;- 서비스 공지 및 이용 권유</p>
    <p>&nbsp;- 판촉활동</p>
    <p>&nbsp;</p>
<p><strong style="color:black; font-weight: bold;">2. 수집항목</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>&nbsp;</strong>- 이름, 휴대전화번호, 이메일, 생년월일, 성별</p>
    <p>&nbsp;</p>
<p><strong style="color:black; font-weight: bold;">3. 보유/이용기간</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;-회원탈퇴시 즉시 파기</p>
    <p>&nbsp;(관련법령에 의거하여 보존할 필요가 있는 정보는 해당 법령에서 요구하는 기한까지 보관)</p>
    <p>&nbsp;-고객센터를 통해 철회 신청시 적용&nbsp;<br>&nbsp;(고객센터의 업무량에 따라 즉시 처리되지 않을 수 있습니다)</p>
    <p>&nbsp;</p>
<p>* 귀하는 위의 동의를 거부하실 수 있습니다. 동의거부를 하셔도 서비스 이용에는 불이익이 없습니다. 다만 플랫폼이 제공할 수 있는 각종 이벤트(사은품, 쿠폰발행, 수수료 할인 행사 등) 안내 및 신규상품 안내등의 혜택의 제한이 있을 수 있음을 알려드립니다.</p>`;
