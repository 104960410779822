import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import WebHeader from '@components/header/web/WebHeader';
import WebLowSizeHeader from '@components/header/web/WebLowSizeHeader';
import AllMenu from '@components/header/web/AllMenu';
import WebFooter from '@components/footer/WebFooter';
import ToTopButton from '@components/common/TopButton';
import Loader from '@components/common/Loader';
import { WEB_PATH } from '@constants/path';
// import '@assets/css/web/content.css';

/**
 * 메인 , 상품 상세페이지를 노출시킬 Layout
 * - 구조상 메인 , 상품 상세페이지는 최상단 div의 class 가 다르기때문에 FullAreaLayout에서 노출
 * @returns
 */
const WebFullAreaLayout = () => {
  const path = useLocation().pathname;

  return (
    <>
      <div id="WRAP" className="main">
        <WebHeader />
        {/* <WebLowSizeHeader /> */}
        <AllMenu />

        <Outlet />
      </div>
      {path !== WEB_PATH.main && (
        <>
          <WebFooter />
          {/* <ToTopButton /> */}
        </>
      )}
    </>
  );
};

export default WebFullAreaLayout;
