import React from 'react';
import Modal from 'react-modal';

interface CommonModalProps {
  onClose: () => void;
  title: string;
  content: string;
  isCloseBtn?: boolean;
}
const CommonModal = ({ onClose, title, content }: CommonModalProps) => {
  const handleBackdropClick = (event: any) => {
    // backdrop 클릭 시 모달을 닫는 동작
    if (event.target.className === 'modal-backdrop') {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      overlayClassName={'modal-backdrop'}
      className={'modal inmodal fade in'}
      style={{
        /* backdrop 영역 */
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        /* content 영역 */
        content: {
          overflowX: 'hidden',
          overflowY: 'auto',
          display: 'block',
          top: '30%',
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modal-dialog modal-md" style={{ opacity: 1 }}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" title="닫기" onClick={onClose}>
              <span aria-hidden="true" className="ti-close"></span>
              <span className="sr-only">Close</span>
            </button>
            <h4 className="modal-title">{title}</h4>
          </div>

          <div className="modal-body">
            <div style={{ padding: '50px 30px' }}>
              <div className="noticeOpenWrap">
                {/* <div className="appDownWrap"> */}
                <div className="appDownCon">
                  <ul>
                    {/* <li>
                      <a href="#" className="appStoreDown" onClick={() => alert('서비스 준비 중입니다.')}>
                        APP 스토어
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=kr.co.plutos.PlutospartnersAPP&hl=ko-KR"
                        className="googlePlayDown"
                        target="__blank"
                        // onClick={() => alert('서비스 준비 중입니다.')}
                      >
                        구글플레이
                      </a>
                    </li>
                  </ul>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-primary" onClick={onClose}>
              창닫기
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default CommonModal;
