import TweenMax, { Linear, Power4 } from 'gsap';
import $ from 'jquery';

export function mainContent() {
  let isMobile = false;

  $('.sec_two .sales_list_wrap .sales').each(function (index) {
    if (window.innerWidth <= 640) {
      isMobile = true;
      TweenMax.to($('.sec_two .sales_bg .bg_default'), 0.5, {
        opacity: 0,
        ease: Linear.easeNone,
      });
      // TweenMax.to($(this).find('.txt_area').find('.before'), 1, {
      //   opacity: 1,
      //   ease: Power4.easeOut,
      // });
      TweenMax.to($(this).find('.txt_area').find('.bts'), 1, {
        opacity: 1,
        ease: Power4.easeOut,
      });
    } else {
      TweenMax.to($(this).find('.txt_area').parents('.sales').find('.dimd_bg'), 1, {
        opacity: 0,
        ease: Power4.easeOut,
      });
      TweenMax.to($(this).find('.txt_area').find('.before'), 1, {
        opacity: 1,
        ease: Power4.easeOut,
      });
      //   TweenMax.to($(this).find('.txt_area').find('.after'), 1, {
      //     opacity: 0,
      //     ease: Power4.easeOut,
      //   });
      TweenMax.to($(this).find('.txt_area').find('.bts'), 1, {
        opacity: 0,
        ease: Power4.easeOut,
      });
    }

    if (!isMobile) {
      $(this)
        .find('.txt_area')
        .hover(
          function () {
            TweenMax.to($(this).parents('.sales').find('.dimd_bg'), 1, {
              opacity: 0.8,
              ease: Power4.easeOut,
            });
            TweenMax.to($('.sec_two .sales_bg .bg_default'), 0.5, {
              opacity: 0,
              ease: Linear.easeNone,
            });
            TweenMax.to($('.sec_two .sales_bg .bg'), 0.5, {
              opacity: 0,
              ease: Linear.easeNone,
            });
            TweenMax.to($('.sec_two .sales_bg .bg').eq(index), 0.5, {
              opacity: 1,
              ease: Linear.easeNone,
            });
            // TweenMax.to($(this).find('.after'), 1, {
            //   opacity: 1,
            //   ease: Power4.easeOut,
            // });
            TweenMax.to($(this).find('.bts'), 1, {
              opacity: 1,
              ease: Power4.easeOut,
            });
          },
          function () {
            TweenMax.to($('.sec_two .sales_bg .bg_default'), 0.5, {
              opacity: 1,
              ease: Linear.easeNone,
            });
            TweenMax.to($('.sec_two .sales_bg .bg'), 0.5, {
              opacity: 0,
              ease: Linear.easeNone,
            });
            TweenMax.to($(this).parents('.sales').find('.dimd_bg'), 1, {
              opacity: 0,
              ease: Power4.easeOut,
            });
            // TweenMax.to($(this).find('.after'), 1, {
            //   opacity: 0,
            //   ease: Power4.easeOut,
            // });
            TweenMax.to($(this).find('.bts'), 1, {
              opacity: 0,
              ease: Power4.easeOut,
            });
          },
        );
    }
  });
}
export function mainFullpage() {}
// export function mainFullpage() {
//   const jQuery: any = $;
//   let mainFullpageNum = 1;
//   jQuery('#fullpageArea').fullpage({
//     lockAnchors: true,
//     slidesToSections: true,
//     verticalCentered: true,
//     scrollOverflow: false,
//     scrollOverflowOptions: {
//       Click: true,
//     },
//     //navigation: false,
//     //navigationPosition: "right",
//     //autoScrolling : true,
//     responsiveWidth: 993,
//     // scrollBar : true,
//     // fitToSection : false,

//     //callback
//     onLeave: function (origin: any, destination: any, direction: any) {
//       console.log('onLeave');

//       if (destination == 1) {
//         $('#header').removeClass('color_type').addClass('white_type');
//       } else if (destination == 2) {
//         $('#header').removeClass('color_type').addClass('white_type');
//         TweenMax.fromTo($('.sec_two .sales_bg .bg_default'), 6, { scale: 1.1 }, { scale: 1, ease: Power4.easeOut });
//         $('.sec_two .sales_list_wrap .sales').each(function (q) {
//           var sec2moveImg = $(this).find('.txt_area');
//           setTimeout(function () {
//             TweenMax.to(sec2moveImg, 2, { top: 0, opacity: 1, delay: q * 0.3, ease: Power4.easeOut });
//           }, 500);
//         });
//       } else if (destination == 3) {
//         $('#header').removeClass('white_type').addClass('color_type');
//       }

//       if (direction == 'down') {
//         TweenMax.to('#header', 0.3, { top: -$('#header').innerWidth()!, ease: Power4.easeOut });
//       } else {
//         TweenMax.to('#header', 0.3, { top: 0, ease: Power4.easeOut });
//       }
//       console.log(destination, '<destination');
//       mainFullpageNum = destination;
//     },
//     afterLoad: function (origin: any, destination: any, direction: any) {},
//     afterSlideLoad: function (section: any, origin: any, destination: any, direction: any) {},
//     onSlideLeave: function (section: any, origin: any, destination: any, direction: any) {},
//     afterResize: function () {},
//     afterReBuild: function () {},
//   });
// }

// import $ from 'jquery';
// import Swiper from 'swiper';
// import TweenMax, { Power3, Power4, Linear } from 'gsap';
// import '@assets/js/fullPage';

// export function mainMenuToJquery() {
//   let _isLeel = false;
//   let mainSec1Timer = 10000;
//   let visNum = 0;
//   let visInterval;
//   let mainSecOne;
//   // let mainSecOneSwiper;

//   let curVisNewNum = 0;

//   function visAutoRoll() {
//     TweenMax.to($('.sec_one .s_new_area .controller .bar span'), 0, { width: 0 });
//     TweenMax.to($('.sec_one .s_new_area .rolling_area .roll_div').eq(curVisNewNum), 0.8, {
//       top: '-100%',
//       ease: Power4.easeOut,
//     });
//     curVisNewNum++;
//     if (curVisNewNum > $('.sec_one .s_new_area .rolling_area .roll_div').length - 1) {
//       curVisNewNum = 0;
//     }

//     TweenMax.to($('.sec_one .s_new_area .rolling_area .roll_div').eq(curVisNewNum), 0, {
//       top: '100%',
//       ease: Power4.easeOut,
//     });
//     TweenMax.to($('.sec_one .s_new_area .rolling_area .roll_div').eq(curVisNewNum), 0.8, {
//       top: 0,
//       ease: Power4.easeOut,
//       onComplete: function () {
//         TweenMax.to($('.sec_one .s_new_area .controller .bar span'), 5, { width: '100%' });
//       },
//     });
//   }

//   let mainFullpageNum = 1;
//   function mainFullpage() {
//     $('#fullpageArea')?.fullpage({
//       lockAnchors: true,
//       slidesToSections: true,
//       verticalCentered: true,
//       scrollOverflow: false,
//       scrollOverflowOptions: {
//         Click: true,
//       },
//       //navigation: false,
//       //navigationPosition: "right",
//       //autoScrolling : true,
//       responsiveWidth: 993,
//       // scrollBar : true,
//       // fitToSection : false,

//       //callback
//       onLeave: function (origin, destination, direction) {
//         if (destination == 1) {
//           $('#header').removeClass('color_type').addClass('white_type');
//         } else if (destination == 2) {
//           $('#header').removeClass('color_type').addClass('white_type');
//           TweenMax.fromTo($('.sec_two .sales_bg .bg_default'), 6, { scale: 1.1 }, { scale: 1, ease: Power4.easeOut });
//           $('.sec_two .sales_list_wrap .sales').each(function (q) {
//             var sec2moveImg = $(this).find('.txt_area');
//             setTimeout(function () {
//               TweenMax.to(sec2moveImg, 2, { top: 0, opacity: 1, delay: q * 0.3, ease: Power4.easeOut });
//             }, 500);
//           });
//         } else if (destination == 3) {
//           $('#header').removeClass('white_type').addClass('color_type');
//         }

//         if (direction == 'down') {
//           TweenMax.to('#header', 0.3, { top: -$('#header').innerWidth(), ease: Power4.easeOut });
//         } else {
//           TweenMax.to('#header', 0.3, { top: 0, ease: Power4.easeOut });
//         }
//         console.log(destination, '<destination');
//         mainFullpageNum = destination;
//       },
//       afterLoad: function (origin, destination, direction) {},
//       afterSlideLoad: function (section, origin, destination, direction) {},
//       onSlideLeave: function (section, origin, destination, direction) {},
//       afterResize: function () {},
//       afterReBuild: function () {},
//     });
//   }

//   function mainSecOneSwiper() {
//     let mainSecOne = new Swiper('.sec_one_left.swiper-container', {
//       speed: 1000,
//       autoplay: {
//         delay: 9000,
//         disableOnInteraction: false,
//       },

//       on: {
//         init: function () {
//           TweenMax.to($('.sec_one').eq(this.activeIndex).find('.txt_wrap .slogan'), 2, {
//             transform: 'translate(0, 0)',
//             opacity: 1,
//             delay: 0.2,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one').eq(this.activeIndex).find('.txt_wrap .txt_img'), 2, {
//             transform: 'translate(0, 0)',
//             opacity: 1,
//             delay: 0.4,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one').eq(this.activeIndex).find('.txt_wrap .txt_down'), 2, {
//             transform: 'translate(0, 0)',
//             opacity: 1,
//             delay: 0.6,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one').eq(this.activeIndex).find('.txt_wrap .a_img'), 2, {
//             transform: 'translate(0, 0)',
//             opacity: 1,
//             delay: 0.8,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one .visual_wrap .visual').eq(this.activeIndex).find('.bg_area'), 6, {
//             scale: 1.1,
//             delay: 0.8,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one .visual_wrap .visual').eq(this.activeIndex).find('.m_bgArea'), 6, {
//             scale: 1.1,
//             delay: 0.8,
//             ease: Power3.easeOut,
//           });
//         },
//         slideChangeTransitionStart: function () {
//           TweenMax.to($('.sec_one .visual_wrap .visual').find('.bg_area'), 0, {
//             scale: 1,
//             delay: 0,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one .visual_wrap .visual').find('.m_bgArea'), 0, {
//             scale: 1,
//             delay: 0,
//             ease: Power3.easeOut,
//           });
//         },
//         slideChangeTransitionEnd: function () {
//           TweenMax.to($('.sec_one .visual_wrap .visual').eq(this.activeIndex).find('.bg_area'), 6, {
//             scale: 1.1,
//             delay: 0.2,
//             ease: Power3.easeOut,
//           });
//           TweenMax.to($('.sec_one .visual_wrap .visual').eq(this.activeIndex).find('.m_bgArea'), 6, {
//             scale: 1.1,
//             delay: 0.2,
//             ease: Power3.easeOut,
//           });
//         },
//       },
//     });

//     return mainSecOne;
//   }
//   if ($('.sec_one_left').length > 0) {
//     $.fn.fullpage.setAllowScrolling(false);
//     $.fn.fullpage.setKeyboardScrolling(false);
//   }
//   if ($('.main #fullpageArea').length !== 0) {
//     mainFullpage();

//     /* 메인비쥬얼 */

//     if ($('.sec_one .sec_one_left').length > 0) {
//       if ($('.sec_one_left .swiper-slide').length > 1) {
//         mainSecOneSwiper();
//       } else {
//         TweenMax.to($('.sec_one').eq(0).find('.txt_wrap .slogan'), 2, {
//           transform: 'translate(0, 0)',
//           opacity: 1,
//           delay: 1,
//           ease: Power3.easeOut,
//         });
//         TweenMax.to($('.sec_one').eq(0).find('.txt_wrap .txt_img'), 2, {
//           transform: 'translate(0, 0)',
//           opacity: 1,
//           delay: 1.2,
//           ease: Power3.easeOut,
//         });
//         TweenMax.to($('.sec_one').eq(0).find('.txt_wrap .a_img'), 2, {
//           transform: 'translate(0, 0)',
//           opacity: 1,
//           delay: 1.4,
//           ease: Power3.easeOut,
//         });
//         TweenMax.to($('.sec_one .visual_wrap .visual').eq(0).find('.bg_area'), 5, {
//           scale: 1.1,
//           delay: 1.2,
//           ease: Power3.easeOut,
//         });
//       }
//     }

//     TweenMax.to($('.sec_one .s_new_area .controller .bar span'), 0, { width: 0 });
//     TweenMax.to($('.sec_one .s_new_area .controller .bar span'), 5, { width: '100%' });
//     visInterval = setInterval(visAutoRoll, mainSec1Timer);
//     var isMainNori = true;
//     $('.sec_one .s_new_area .controller a').on('click', function () {
//       if (isMainNori == true) {
//         $('.sec_one .s_new_area .controller a').addClass('on');
//         clearInterval(visInterval);
//         TweenMax.to($('.sec_one .s_new_area .controller .bar span'), 0, {
//           width: '100%',
//           onComplete: function () {
//             $('.sec_one .s_new_area .controller .bar span').css('width', '100%');
//           },
//         });
//         isMainNori = false;
//       } else {
//         $('.sec_one .s_new_area .controller a').removeClass('on');
//         clearInterval(visInterval);
//         visInterval = setInterval(visAutoRoll, mainSec1Timer);
//         isMainNori = true;
//       }
//     });

//     /* 투자상품 */
//     var mainSecTwoSwiper = new Swiper('.sec_two .sales_list_wrap.swiper-container', {
//       slidesPerView: 4,
//       spaceBetween: 0,
//       navigation: {
//         nextEl: '.sec_two .swiper-button-next',
//         prevEl: '.sec_two .swiper-button-prev',
//       },
//       pagination: {
//         el: '.sec_two .swiper-pagination',
//         type: 'fraction',
//       },
//       breakpoints: {
//         500: {
//           speed: 450,
//           slidesPerView: 1,
//           spaceBetween: 0,
//         },
//         768: {
//           speed: 450,
//           slidesPerView: 1,
//           spaceBetween: 0,
//         },
//         1024: {
//           speed: 450,
//           slidesPerView: 2,
//           spaceBetween: 0,
//         },
//         1800: {
//           speed: 900,
//           slidesPerView: 3,
//           spaceBetween: 0,
//         },
//       },
//       on: {
//         init: function () {
//           if (window.innerWidth <= 1024) {
//             $('.sec_two .sales_bg .bg').each(function (q) {
//               $('.sec_two .sales_bg .bg').eq(0).css('opacity', '1');
//             });
//           }
//         },

//         slideChangeTransitionStart: function () {
//           $('.sec_two .sales_bg .bg').each(function () {
//             TweenMax.to($('.sec_two .sales_bg .bg'), 0.5, { opacity: 0 });
//             TweenMax.to($('.sec_two .sales_bg .bg').eq(mainSecTwoSwiper.realIndex), 0.5, { opacity: 1 });
//           });
//         },
//       },
//     });

//     var isMobile = false;
//     $('.sec_two .sales_list_wrap .sales').each(function (index) {
//       if (window.innerWidth <= 1024) {
//         isMobile = true;
//         TweenMax.to($('.sec_two .sales_bg .bg_default'), 0.5, { opacity: 0, ease: Linear.easeNone });
//         TweenMax.to($(this).find('.txt_area').find('.after'), 1, { opacity: 1, ease: Power4.easeOut });
//         TweenMax.to($(this).find('.txt_area').find('.bts'), 1, { opacity: 1, ease: Power4.easeOut });
//         console.log(index + 1);
//       } else {
//         TweenMax.to($(this).find('.txt_area').parents('.sales').find('.dimd_bg'), 1, {
//           opacity: 0,
//           ease: Power4.easeOut,
//         });
//         TweenMax.to($(this).find('.txt_area').find('.before'), 1, { opacity: 1, ease: Power4.easeOut });
//         TweenMax.to($(this).find('.txt_area').find('.after'), 1, { opacity: 0, ease: Power4.easeOut });
//         TweenMax.to($(this).find('.txt_area').find('.bts'), 1, { opacity: 0, ease: Power4.easeOut });
//       }

//       if (!isMobile) {
//         $(this)
//           .find('.txt_area')
//           .hover(
//             function () {
//               TweenMax.to($(this).parents('.sales').find('.dimd_bg'), 1, { opacity: 0.8, ease: Power4.easeOut });
//               TweenMax.to($('.sec_two .sales_bg .bg_default'), 0.5, { opacity: 0, ease: Linear.easeNone });
//               TweenMax.to($('.sec_two .sales_bg .bg'), 0.5, { opacity: 0, ease: Linear.easeNone });
//               TweenMax.to($('.sec_two .sales_bg .bg').eq(index), 0.5, { opacity: 1, ease: Linear.easeNone });
//               TweenMax.to($(this).find('.after'), 1, { opacity: 1, ease: Power4.easeOut });
//               TweenMax.to($(this).find('.bts'), 1, { opacity: 1, ease: Power4.easeOut });
//             },
//             function () {
//               TweenMax.to($('.sec_two .sales_bg .bg_default'), 0.5, { opacity: 1, ease: Linear.easeNone });
//               TweenMax.to($('.sec_two .sales_bg .bg'), 0.5, { opacity: 0, ease: Linear.easeNone });
//               TweenMax.to($(this).parents('.sales').find('.dimd_bg'), 1, { opacity: 0, ease: Power4.easeOut });
//               TweenMax.to($(this).find('.after'), 1, { opacity: 0, ease: Power4.easeOut });
//               TweenMax.to($(this).find('.bts'), 1, { opacity: 0, ease: Power4.easeOut });
//             },
//           );
//       }
//     });
//     var swiper1 = new Swiper('#serviceWrap', {
//       loop: false,
//       autoplay: {
//         delay: 10000,
//         disableOnInteraction: false,
//       },
//       slidesPerView: 1,
//       spaceBetween: 0,
//       pagination: {
//         el: '.swiper-pagination',
//         clickable: true,
//       },
//     });

//     /* 미디어가 전하는 PLUTOS */
//     var swiper2 = new Swiper('#pressCon', {
//       slidesPerView: 3,
//       spaceBetween: 10,
//       scrollbar: {
//         el: '.swiper-scrollbar',
//         hide: true,
//       },
//       breakpoints: {
//         768: {
//           slidesPerView: 2,
//         },
//         425: {
//           slidesPerView: 1,
//         },
//       },
//     });

//     // leel();
//   }
// }
