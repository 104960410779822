import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '@constants/path';
import Pagination from '@components/list/Pagination';
import SubscriptionProduct from '@components/list/goods/SubscriptionProduct';
import ListingProduct from '@components/list/goods/ListingProduct';
import SellProduct from '@components/list/goods/SellProduct';
import { Axios } from '@service/axios';
import { API } from '@service/api';
import { Navigation, Pagination as SwiperPagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';

const GoodsList = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const goodsStatusArr = [
    { title: '청약상품', value: 'C', className: 'subscription' },
    { title: '상장상품', value: 'S', className: 'listed' },
    { title: '매각완료', value: 'M', className: 'sell' },
  ];

  const [goodsOption, setGoodsOption] = useState({
    searchType1: 'C',
    searchKeyword: '',
    page: 1,
    pageSize: 3,
  });

  const [goodsInfo, setGoodsInfo] = useState<{
    C: Array<any>;
    S: Array<any>;
    M: Array<any>;
    totalCount: any;
    [props: string]: any;
  }>({
    C: [],
    S: [],
    M: [],
    totalCount: undefined,
  });

  const [isList, setIsList] = useState(false);

  /**
   * 선택된 status 값에 className 추가
   * @param params
   * @returns
   */
  function activeStatus(params: string) {
    let status = '';
    if (params === goodsOption.searchType1) status = 'active';
    return status;
  }

  function ProductCompoent({ data, type }: { data: any; type: string }) {
    let component = null;

    switch (type) {
      case 'S':
        component = <ListingProduct data={data} path={pathname} />;
        break;
      case 'M':
        component = <SellProduct data={data} path={pathname} />;
        break;
      default:
        component = <SubscriptionProduct data={data} path={pathname} />;
        break;
    }

    return component;
  }

  function handleGoodsOption(name: string, value: any, activeId?: number) {
    let copyGoodsOpt = { ...goodsOption };
    if (name === 'searchType1') {
      setIsList(false);

      copyGoodsOpt.page = 1;
    }
    setGoodsOption({
      ...copyGoodsOpt,
      [name]: value,
    });
  }

  function slideMoveSwiper(value: string) {
    if (swiperRef) {
      switch (value) {
        case 'S':
          swiperRef!.slideTo(1, 0);
          break;
        case 'M':
          swiperRef!.slideTo(2, 0);
          break;
        default:
          swiperRef!.slideTo(0, 0);
          break;
      }
    }
  }

  function getTotalCount(keyNm: string) {
    let res = 0;
    if (goodsInfo.totalCount) {
      if (goodsInfo.totalCount[keyNm]) {
        res = goodsInfo.totalCount[keyNm];
      }
    }
    return res;
  }

  async function fetchGoodsList() {
    try {
      const { data } = await Axios.get(API.goods, { params: goodsOption });
      if (data.code === '200') {
        setGoodsInfo({
          ...goodsInfo,
          [goodsOption.searchType1]: data.data,
          totalCount: data.totalCnt,
        });
        slideMoveSwiper(goodsOption.searchType1);
      }
      setIsList(true);
    } catch (error) {
      console.log(error);
      setIsList(true);
    }
  }

  useEffect(() => {
    fetchGoodsList();
  }, [goodsOption]);

  useEffect(() => {
    if (state?.appGoodsListType) handleGoodsOption('searchType1', state?.appGoodsListType);
  }, [state]);

  function onSlideChanges(swiper: any) {
    switch (swiper.activeIndex) {
      case 0:
        handleGoodsOption('searchType1', 'C', 0);
        break;
      case 1:
        handleGoodsOption('searchType1', 'S', 1);
        break;
      case 2:
        handleGoodsOption('searchType1', 'M', 2);
        break;
    }
  }

  return (
    <div className="sectionCon">
      <div className="selectTab">
        <ul className="selectTabArea">
          {goodsStatusArr.map((stts, index) => (
            <li className={`${stts.className} ${activeStatus(stts.value)}`} key={stts.value}>
              <dl style={{ cursor: 'pointer' }} onClick={() => handleGoodsOption('searchType1', stts.value)}>
                <dt>{stts.title}</dt>
                <dd>{getTotalCount(stts.value)}</dd>
              </dl>
            </li>
          ))}
        </ul>
      </div>
      <div className="product_investment">
        <Swiper
          style={{ overflow: 'hidden' }}
          onSwiper={setSwiperRef}
          onSlideChange={onSlideChanges}
          spaceBetween={30}
          modules={[SwiperPagination]}
          autoHeight
          observer
          observeParents
        >
          <SwiperSlide>
            {Boolean(goodsInfo.C.length) ? (
              goodsInfo.C.map((data) => (
                <ProductCompoent data={data} type={goodsOption.searchType1} key={data.goodsSn} />
              ))
            ) : (
              <div className="noticeOpen">
                <div className="noticeOpenWrap">
                  <h3>상품 준비중입니다.</h3>
                </div>
              </div>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {Boolean(goodsInfo.S.length) ? (
              goodsInfo.S.map((data) => (
                <ProductCompoent data={data} type={goodsOption.searchType1} key={data.goodsSn} />
              ))
            ) : (
              <div className="noticeOpen">
                <div className="noticeOpenWrap">
                  <h3>상품 준비중입니다.</h3>
                </div>
              </div>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {Boolean(goodsInfo.M.length) ? (
              goodsInfo.M.map((data) => (
                <ProductCompoent data={data} type={goodsOption.searchType1} key={data.goodsSn} />
              ))
            ) : (
              <div className="noticeOpen">
                <div className="noticeOpenWrap">
                  <h3>상품 준비중입니다.</h3>
                </div>
              </div>
            )}
          </SwiperSlide>
        </Swiper>
        {Boolean(goodsInfo[goodsOption.searchType1].length) && (
          <Pagination
            page={goodsOption.page}
            totalCount={goodsInfo.totalCount[goodsOption.searchType1]}
            onClick={handleGoodsOption}
            pageSize={goodsOption.pageSize}
          />
        )}
      </div>
    </div>
  );
};
export default GoodsList;
