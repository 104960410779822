import { formatBytes } from '@utils/util';
import { BoardAttachFiles } from 'interface/commonInterface';
import React from 'react';

interface AttachFilesFormProps {
  formTitle: string;
  fileList: Array<BoardAttachFiles>;
}

/**
 * 게시글 상세 첨부파일 컴포넌트
 * @param param0
 * @returns
 */
const AttachFilesForm = ({ formTitle, fileList }: AttachFilesFormProps) => {
  return (
    <div className="attachArea">
      <div className="panel-group">
        <div className="panel panel-default attachFileArea">
          <div className="panel-heading">
            <h5 className="panel-title">{formTitle}</h5>
          </div>
          <div className="panel-collapse">
            <div className="panel-body">
              <ul className="dropdown-alerts">
                {fileList.map((file, index) => (
                  <li key={index}>
                    <div>
                      <i className="ri-download-2-line"></i>{' '}
                      <a href={file.flpth} download target="_blank" rel="noreferrer">
                        {file.filenm}
                      </a>
                      <span className="pull-right text-muted small">
                        {formatBytes(file.filecpcty)}
                        {/*
                        휴지통 아이콘 필요 x 
                        <a href="#">
                          <i className="ri-delete-bin-line"></i>
                        </a> */}
                      </span>
                    </div>
                  </li>
                ))}
                <li className="divider"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachFilesForm;
