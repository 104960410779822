import { API } from '@service/api';
import { Axios } from '@service/axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Pagination from '@components/list/Pagination';
import { confirmAlert, decodeJwtToUserProfileObj } from '@utils/util';

interface QueryItemInfo {
  sntncSn: any;
  sntncSj: string;
  sntncCn: string;
  answerCn: string;
  answChk: 'Y' | 'N';
  frstCreatDt: number;
}

const Query = () => {
  const inputWrapStyle = {
    display: 'block',
    marginTop: '10px',
    backgroundColor: '#ffffff',
    border: '1px solid #c6c6c6',
    borderRadius: '5px',
    overflow: 'hidden',
    marginBottom: '0.5rem',
  };

  // const userProfile = decodeJwtToUserProfileObj(localStorage.getItem('token') ? localStorage.getItem('token')! : '');
  // console.log(userProfile);

  /* 문의글 남기기 info */
  const [queryData, setQueryData] = useState({
    applcntNm: '',
    sntncPassword: '',
    sntncSj: '',
    sntncCn: '',
  });

  /* 나의 문의글 option */
  const [queryListOption, setQueryListOption] = useState({
    page: 1,
    pageSize: 5,
    userName: '',
  });

  /* 나의 문의글 info */
  const [queryInfo, setQueryInfo] = useState<{ list: Array<QueryItemInfo>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  const [activeMenuIndex, setActiveMenuIndex] = useState<undefined | number>(undefined);

  const qnaTitleStyle = (id: number) => {
    let style: any = { overflow: 'hidden', wordBreak: 'break-all', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

    if (activeMenuIndex === id) {
      style = {};
    }
    return style;
  };

  function handleQueryData(name: string, params: string) {
    setQueryData({
      ...queryData,
      [name]: params,
    });
  }

  /**
   * 메뉴활성화 및 비활성화
   * @param index
   */
  function handleActiveMenuIndex(index: number) {
    let state: undefined | number = index;
    if (index === activeMenuIndex) {
      state = undefined;
    }

    setActiveMenuIndex(state);
  }

  /**
   * 문의리스트 옵션
   * @param name
   * @param params
   */
  function handleQueryListOption(name: string, params: any) {
    setQueryListOption({
      ...queryListOption,
      [name]: params,
    });
  }

  /**
   * 문의글 등록시 ( 페이지 + 글내용,제목 + 메뉴 활성화 ) 초기화
   */
  function resetData() {
    setActiveMenuIndex(undefined);
    setQueryData({
      applcntNm: '',
      sntncPassword: '',
      sntncSj: '',
      sntncCn: '',
    });

    if (queryListOption.page === 1) {
      fetchQuery();
    } else {
      handleQueryListOption('page', 1);
    }
  }

  /**
   * 나의 문의글 get
   */
  async function fetchQuery() {
    try {
      const { data } = await Axios.get(API.query, { params: queryListOption });
      if (data.code === '200') {
        setQueryInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 문의글 남기기 post
   * @returns
   */
  async function postQuery() {
    if (!queryData.sntncCn || !queryData.sntncSj) return alert('제목 혹은 내용을 입력해주세요.');

    try {
      const { data } = await Axios.post(API.add_query, queryData);
      if (data.code === '200') {
        alert('문의글을 등록하였습니다.');
        resetData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 문의글 남기기 post
   * @returns
   */
  async function deleteQuery(sntncSn: any) {
    let bodyData = {
      sntncSn: sntncSn,
    };
    try {
      const { data } = await Axios.post(API.delete_query, bodyData);
      if (data.code === '200') {
        alert('문의글을 삭제하였습니다.');
        fetchQuery();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchQuery();
    setActiveMenuIndex(undefined);
  }, [queryListOption]);

  return (
    <>
      <div className="sectionCon">
        <h4 className="subTitle_type01">나의 문의글</h4>

        <ul className="accordion">
          {Boolean(queryInfo.list.length) &&
            queryInfo.list.map((query, index) => (
              <li className={index === activeMenuIndex ? 'active' : ''}>
                <div className="accordionView">
                  <div className="faqArea" style={{ display: 'flex' }}>
                    <span className="faqIndex" style={{ flex: 0.5 }} onClick={() => handleActiveMenuIndex(index)}>
                      Q
                    </span>
                    {/* 줄바뀜시 ... 처리 */}
                    <span
                      className="faqTitle01"
                      onClick={() => handleActiveMenuIndex(index)}
                      style={{
                        fontWeight: 'bold',
                        flex: 7,
                        ...qnaTitleStyle(index),
                      }}
                    >
                      {query.sntncSj}
                    </span>
                    {index !== activeMenuIndex && (
                      <span
                        className="faqTitle01"
                        onClick={() => handleActiveMenuIndex(index)}
                        style={{ flex: 1, fontSize: '1.4rem', color: query.answChk === 'Y' ? '#d6001f' : '#000000' }}
                      >
                        {query.answChk === 'Y' ? '답변완료' : '답변대기'}
                      </span>
                    )}
                    <button
                      onClick={() =>
                        confirmAlert(
                          '문의글을 삭제하시겠습니까?',
                          () => deleteQuery(query.sntncSn),
                          () => {},
                        )
                      }
                      style={{
                        flex: 0.5,
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <img
                        src={require('@assets/img_app/web/sitemapClose.png')}
                        style={{ width: '1rem' }}
                        alt="closeBtn"
                      />
                    </button>
                  </div>
                  {/* <p className="accordionOpen">
                    <span className="ico ico_open">열린 메뉴</span>
                  </p> */}
                </div>
                <div className="accordionDetail">
                  <div className="faqDetail">
                    <span className="faqIndex">Q</span>
                    <span className="faqTitle01">
                      {/* <div className="ck-content"> */}
                      <p>{query.sntncCn}</p>
                      {/* </div> */}
                    </span>
                    <span className="faqIndex">A</span>
                    <span className="faqTitle01">
                      <div className="ck-content">
                        {query.answerCn ? parse(query.answerCn) : '문의에 대한 답변이 등록되지 않았습니다.'}
                      </div>
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <Pagination
          page={queryListOption.page}
          pageSize={queryListOption.pageSize}
          totalCount={queryInfo.totalCount}
          onClick={handleQueryListOption}
        />
      </div>

      <div className="sectionCon">
        <h4 className="subTitle_type01">
          문의하기 <span>(200자 이내)</span>
        </h4>

        <div className="writeForm_wrap">
          <div className="checkBodyDetail" style={inputWrapStyle}>
            <div className="checkBodyWrite">
              <input
                type="text"
                className="form-control"
                value={queryData.sntncSj}
                onChange={(e) => handleQueryData('sntncSj', e.target.value)}
                placeholder="제목을 입력해주세요."
              />
            </div>
          </div>
          <div className="checkBodyDetail" style={inputWrapStyle}>
            <div className="checkBodyWrite">
              <textarea
                className="form-control"
                rows={8}
                value={queryData.sntncCn}
                maxLength={200}
                onChange={(e) => handleQueryData('sntncCn', e.target.value)}
                placeholder="내용을 입력해주세요."
              />
              <p className="txtCheckArea" id="counter">
                {queryData.sntncCn.length}/200
              </p>
            </div>
          </div>
        </div>

        <div className="btnArea text-center">
          <button
            onClick={() =>
              confirmAlert(
                '문의글을 등록하시겠습니까?',
                () => postQuery(),
                () => {},
              )
            }
            className="btn btn-primary btn-lg"
          >
            문의글 남기기
          </button>
        </div>
      </div>
    </>
  );
};
export default Query;
