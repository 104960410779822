import { WEB_PATH } from '@constants/path';
import { replacePath } from '@utils/util';
import React from 'react';
interface LocationProps {
  path: string;
}

/**
 * 페이지 경로
 * @param param0
 * @returns
 */
const WebLocation = ({ path }: LocationProps) => {
  const routePath = replacePath(path);

  function depthByTitle() {
    let depthOne = '';
    let deptTwo = '';
    switch (routePath) {
      case WEB_PATH.anno:
        depthOne = '공시정보';
        deptTwo = '공시';
        break;
      case WEB_PATH.anno_detail:
        depthOne = '공시정보';
        deptTwo = '공시';
        break;
      case WEB_PATH.notice:
        depthOne = 'PLUTOS';
        deptTwo = '공지사항';
        break;
      case WEB_PATH.notice_detail:
        depthOne = 'PLUTOS';
        deptTwo = '공지사항';
        break;
      case WEB_PATH.news:
        depthOne = 'PLUTOS';
        deptTwo = '보도자료';
        break;
      case WEB_PATH.news_detail:
        depthOne = 'PLUTOS';
        deptTwo = '보도자료';
        break;
      case WEB_PATH.event:
        depthOne = '이벤트';
        deptTwo = '';
        break;
      case WEB_PATH.event_detail:
        depthOne = '이벤트';
        deptTwo = '';
        break;
      case WEB_PATH.goods:
        depthOne = '투자상품';
        deptTwo = '';
        break;
      case WEB_PATH.goods_detail:
        depthOne = '공시정보';
        deptTwo = '상품';
        break;
      case WEB_PATH.corporation_investment:
        depthOne = '고객지원';
        deptTwo = '법인투자 안내';
        break;
      case WEB_PATH.sto_guide:
        depthOne = 'PLUTOS';
        deptTwo = '토큰증권(ST)';
        break;
      case WEB_PATH.invest_guide:
        depthOne = '고객지원';
        deptTwo = '투자가이드';
        break;
      case WEB_PATH.faq:
        depthOne = 'PLUTOS';
        deptTwo = 'FAQ';
        break;
      case WEB_PATH.npl_guide:
        depthOne = 'PLUTOS';
        deptTwo = 'NPL투자란';
        break;
      case WEB_PATH.npl_investment:
        depthOne = '고객지원';
        deptTwo = 'NPL 상장 신청';
        break;
      case WEB_PATH.plutos_introduction:
        depthOne = 'PLUTOS';
        deptTwo = 'PLUTOS 소개';
        break;
      default:
        depthOne = '';
        deptTwo = '';
        break;
    }
    return [depthOne, deptTwo];
  }
  return (
    <div id="location">
      <ul>
        <li>Home</li>
        {depthByTitle()[1] ? (
          <>
            <li>{depthByTitle()[0]}</li>
            <li className="home_out">{depthByTitle()[1]}</li>
          </>
        ) : (
          <li className="home_out">{depthByTitle()[0]}</li>
        )}
      </ul>
    </div>
  );
};

export default WebLocation;
