import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { menuList, replacePath } from '@utils/util';
import { PATH, WEB_PATH } from '@constants/path';

/**
 * 모바일 사이즈 헤더
 * @returns
 */

interface WebLowSizeHeaderProps {
  currentScrollY: number;
}

const WebLowSizeHeader = ({ currentScrollY }: WebLowSizeHeaderProps) => {
  /* 현재페이지 || 선택된 카테고리 제목 */
  const [activeTitle, setActiveTitle] = useState('');
  /* 현재페이지 || 선택된 카테고리 부제목 */
  const [activeSubTitle, setActiveSubTitle] = useState('');

  const location = useLocation();
  const path = replacePath(location.pathname);

  function checkActiveTitle() {
    let depthOne = '';
    let deptTwo = '';

    switch (path) {
      case WEB_PATH.anno:
        depthOne = '공시정보';
        deptTwo = '공시';
        break;
      case WEB_PATH.anno_detail:
        depthOne = '공시정보';
        deptTwo = '';
        break;
      case WEB_PATH.notice:
        depthOne = 'PLUTOS';
        deptTwo = '공지사항';
        break;
      case WEB_PATH.notice_detail:
        depthOne = 'PLUTOS';
        deptTwo = '';
        break;
      case WEB_PATH.news:
        depthOne = 'PLUTOS';
        deptTwo = '보도자료';
        break;
      case WEB_PATH.event:
        depthOne = '이벤트';
        deptTwo = '이벤트';
        break;
      case WEB_PATH.event_detail:
        depthOne = '이벤트';
        deptTwo = '이벤트';
        break;
      case WEB_PATH.goods:
        depthOne = '투자상품';
        let st = '청약 상품';
        if (location.state) {
          switch (location.state) {
            case 'S':
              st = '상장 상품';
              break;
            case 'M':
              st = '매각 완료';
              break;
            default:
              st = '청약 상품';
              break;
          }
        }
        deptTwo = st;
        break;
      case WEB_PATH.goods_detail:
        depthOne = '투자상품';
        deptTwo = '';
        break;
      case WEB_PATH.corporation_investment:
        depthOne = '고객지원';
        deptTwo = '법인투자 안내';
        break;
      case WEB_PATH.sto_guide:
        depthOne = 'PLUTOS';
        deptTwo = '토큰증권(ST)';
        break;
      case WEB_PATH.invest_guide:
        depthOne = '고객지원';
        deptTwo = '투자가이드';
        break;
      case WEB_PATH.faq:
        depthOne = '고객지원';
        deptTwo = 'FAQ';
        break;
      case WEB_PATH.npl_guide:
        depthOne = 'PLUTOS';
        deptTwo = 'NPL 투자란?';
        break;
      case WEB_PATH.npl_investment:
        depthOne = '고객지원';
        deptTwo = 'NPL 상장신청(준비중)';
        break;
      case WEB_PATH.plutos_introduction:
        depthOne = 'PLUTOS';
        deptTwo = 'PLUTOS 소개';
        break;
      default:
        depthOne = '';
        deptTwo = '';
        break;
    }
    setActiveTitle(depthOne);
    setActiveSubTitle(deptTwo);
  }

  /**
   * 메뉴 펼치기 & 접기
   * @param value
   */
  function handleActiveTitle(value: string) {
    if (activeTitle === value) {
      setActiveTitle('');
    } else {
      setActiveTitle(value);
    }
  }

  /**
   * 모바일사이즈 헤더 X 버튼 클릭이벤트
   * @param event
   */
  function handleToggleBtn() {
    let mobileNavClass = 'mobile-nav-opened';
    let mobileFixedNavClass = 'header-fixed-at-mobile-nav-opened';

    if (document.body.classList.contains(mobileNavClass)) {
      document.body.classList.remove(mobileNavClass);
    } else {
      document.body.classList.add(mobileNavClass);
    }
    /* header-fixed-at-mobile-nav-opened class 제거 */
    if (document.body.classList.contains(mobileFixedNavClass)) {
      document.body.classList.remove(mobileFixedNavClass);
    }

    /* 스크롤 위치 초기화 방지 */
    window.scroll({ top: currentScrollY });
  }

  useEffect(() => {
    /* 바로 렌더링시 메뉴가 바뀌는게 보이기때문에 timeout으로 딜레이를 줌 */
    setTimeout(() => {
      checkActiveTitle();
    }, 500);
  }, [location]);

  return (
    <aside id="mobileNav">
      <div className="mobile-nav">
        <div className="mobile-nav-header">
          <h1 className="brand">
            <a href={'/'} />
          </h1>
          <button className="btn-toggle-menu active" title="" onClick={handleToggleBtn}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
              <path
                fillRule="evenodd"
                fill="#333333"
                d="M22.008,20.949 L20.960,21.996 L11.004,12.050 L1.048,21.996 L0.082,21.031 L10.038,11.085 L-0.000,1.058 L1.048,0.011 L11.086,10.038 L21.042,0.093 L22.008,1.058 L12.052,11.003 L22.008,20.949 Z"
              />
            </svg>
          </button>
        </div>
        <div className="mobile-nav-body">
          <ul className="gnb">
            {menuList.map((menu, index) => (
              <li
                className={`${activeTitle === menu.main.title ? 'active' : ''}`}
                key={`${menu.main.title}_${index}`}
                onClick={() => handleActiveTitle(menu.main.title)}
              >
                <div className="first-menu " style={{ cursor: 'pointer' }}>
                  <a>
                    <span>{menu.main.title}</span>
                  </a>
                </div>
                <ul className="sub-menu" style={{ display: activeTitle === menu.main.title ? 'block' : 'none' }}>
                  {menu.sub.map((item, index) => (
                    <li key={`${item.path}_${index}`}>
                      {location.pathname === WEB_PATH.main ? (
                        <a href={item.path} style={{ fontWeight: item.title === activeSubTitle ? 'bold' : '500' }}>
                          {item.title}
                        </a>
                      ) : (
                        <Link
                          to={item.path}
                          state={item?.state ? item.state : undefined}
                          style={{ fontWeight: item.title === activeSubTitle ? 'bold' : '500' }}
                          onClick={() => {
                            /* goods 는 한페이지에서 state만 변경되기때문에 직접 메뉴를 닫아줌 */
                            if (path === WEB_PATH.goods) {
                              handleToggleBtn();
                            }
                          }}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default WebLowSizeHeader;
