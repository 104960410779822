import React, { useState } from 'react';
import Player from 'react-player';

interface ReactPlayerProps {
  //   playerRef: any;
  // url: string;
  isControl?: boolean;
  // isBorder: boolean;
  height?: string | number | undefined;
  isMobile?: boolean;
}

const ReactPlayer = ({ isControl, height, isMobile }: ReactPlayerProps) => {
  const mobileStyle = isMobile
    ? {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '20px',
      }
    : {};
  return (
    <div>
      <Player
        url={require('@assets/video/plutos_video.mp4')}
        playing={true}
        controls={isControl}
        // muted
        playsinline={true}
        loop={false}
        width={'100%'}
        height={height}
        light={<img width={'100%'} height={'100%'} src={require('@assets/img_app/plutos_intro.png')} alt="thumbnail" />}
        style={{
          backgroundColor: '#FFFFFF',
          ...mobileStyle,
        }}
      />
    </div>
  );
};

export default ReactPlayer;
