import PrepareService from '@components/common/PrepareService';

const InvestGuide = () => {
  return (
    <>
      <PrepareService />
    </>
  );
};
export default InvestGuide;
