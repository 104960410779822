import MainContentSwiper from '@components/swiper/MainContentSwiper';
import { PATH } from '@constants/path';
import { useUserProfile } from '@contexts/GlobalProvider';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { MESSAGESTATE, sendRnMessage } from '@service/rnService';
import { decodeJwtToUserProfileObj } from '@utils/util';
import { UserInfoIF } from 'interface/UserInterface';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

interface SplashProps {
  onClick: (name: string, value: string) => void;
  userInfo: UserInfoIF;
  setUserInfo: any;
  type: string;
}

const AppSplashPwd = ({ onClick, userInfo, setUserInfo, type }: SplashProps) => {
  const navigate = useNavigate();
  const [splashUserPwd, setSplashUserPwd] = useState({
    pwd: '',
    repwd: '',
  });
  const [apiOption, setApiOption] = useState({
    apiType: type,
  });
  const [apiFindOption, setApiFindOption] = useState({
    apiType: 'findid',
  });
  const [userProfile, setUserProfile] = useUserProfile();

  function chkUserPwd() {
    var regExp = /^\d{6}$/;

    if (type !== 'login') {
      if (splashUserPwd.pwd !== splashUserPwd.repwd) {
        alert('입력한 두 비밀번호가 일치하지 않습니다.');
        return;
      }
    }
    if (!regExp.test(splashUserPwd.pwd)) {
      alert('입력한 비밀번호를 다시 확인 해주세요.');
    } else {
      apiFindId();
    }
  }

  function resetInfo() {
    setUserInfo({
      userTelno: '',
      userPassword: '',
      email: '',
      nm: '',
      brthdy: '',
      indvdlinfoColctagreAt: 'N',
      serviceTermsAt: 'N',
      privacyPolicyAt: 'N',
      personalInfoUsageAt: 'N',
      thirdPartyDisclosureAt: 'N',
      identityVerificationAt: 'N',
      marketingConsentAt: 'N',
      smsRecptnAt: 'N',
      emailRecptnAt: 'N',
    });
    setSplashUserPwd({ pwd: '', repwd: '' });
  }

  async function apiFindId() {
    try {
      const res = await Axios.post(API.splash, userInfo, { params: apiFindOption });

      if (res.data.code === '200') {
        if (type === 'signin') {
          if (res.data.logincode === 1) {
            alert('이미 존재하는 아이디 입니다. 메인 화면으로 이동합니다.');
            resetInfo();
            onClick('splashIndex', 'login');
          } else {
            apiPwd();
          }
        } else if (type === 'find') {
          if (res.data.logincode === 1) {
            apiPwd();
          } else {
            alert('존재하지 않는 아이디 입니다. 메인 화면으로 이동합니다.');
            resetInfo();
            onClick('splashIndex', 'login');
          }
        } else if (type === 'login') {
          apiPwd();
        }
      }
    } catch (error) {
      alert('존재하지 않는 아이디 입니다.');
      console.log(error);
    }
  }

  async function apiPwd() {
    try {
      // const res = await Axios.post(API.splash, userInfo, { params: apiOption });
      const res = await Axios.post(API.splash, { ...userInfo, userPassword: splashUserPwd.pwd }, { params: apiOption });
      if (res.data.code === '200') {
        if (type === 'login') {
          if (res.data.logincode === 1) {
            const jwt = res.data.jwtToken;
            setUserProfile(decodeJwtToUserProfileObj(jwt));
            localStorage.setItem('token', jwt);
            sendRnMessage(MESSAGESTATE.LOGIN, jwt);
            alert('로그인에 성공했습니다. 메인 화면으로 이동합니다.');
            resetInfo();
            window.location.replace(PATH.app_main);
            // navigate(PATH.app_main, { replace: true });
          } else {
            alert('입력한 비밀번호를 다시 확인 해주세요.');
          }
        } else if (type === 'signin') {
          alert('회원가입에 성공했습니다. 로그인 화면으로 이동합니다.');
          resetInfo();
          onClick('splashIndex', 'login');
        } else if (type === 'find') {
          alert('비밀번호 변경에 성공했습니다. 로그인 화면으로 이동합니다.');
          resetInfo();
          onClick('splashIndex', 'login');
        }
      }
    } catch (error) {
      if (type === 'login') {
        alert('로그인에 실패했습니다. 처음 화면으로 이동합니다.');
        resetInfo();
        onClick('splashIndex', 'login');
      } else if (type === 'signin') {
        alert('회원가입에 실패했습니다. 처음 화면으로 이동합니다.');
        resetInfo();
        onClick('splashIndex', 'login');
      } else if (type === 'find') {
        alert('비밀번호 변경에 실패했습니다. 처음 화면으로 이동합니다.');
        resetInfo();
        onClick('splashIndex', 'login');
      }
      console.log(error);
    }
  }

  function GetBtnBottom() {
    let res = <></>;

    if (type === 'login') {
      res = (
        <button className="buttonType01" onClick={() => chkUserPwd()}>
          로그인
        </button>
      );
    } else if (type === 'signin') {
      res = (
        <button className="buttonType01" onClick={() => chkUserPwd()}>
          회원가입 완료
        </button>
      );
    } else if (type === 'find') {
      res = (
        <button className="buttonType01" onClick={() => chkUserPwd()}>
          비밀번호 변경
        </button>
      );
    }
    return res;
  }

  function getPwdClassName(type: string) {
    let res = 'form-control input-radius';
    var regExp = /^\d{6}$/;

    if (type === 'pwd') {
      if (splashUserPwd.pwd === '') {
        res = 'form-control input-radius';
      } else if (!regExp.test(splashUserPwd.pwd)) {
        res = 'form-control input-radius-red';
      } else {
        res = 'form-control input-radius-green';
      }
    } else if (type === 'repwd') {
      if (splashUserPwd.repwd === '') {
        res = 'form-control input-radius';
      } else if (!regExp.test(splashUserPwd.pwd) || splashUserPwd.pwd !== splashUserPwd.repwd) {
        res = 'form-control input-radius-red';
      } else {
        res = 'form-control input-radius-green';
      }
    }

    return res;
  }

  return (
    <>
      <div className="startWrap">
        <h3 className="systemLogo">PLUTOS</h3>

        <div className="loginInputWrap">
          <div className="loginInputCon">
            <div className="loginInputConArea">
              <div className="form-group">
                <dl>
                  <div className="startTxt01">
                    <h4 style={{ fontSize: 20 }}>간편 비밀번호 (회원가입 필수)</h4>
                  </div>
                  <dt style={{ marginTop: 20 }}>
                    <label className="required">숫자 6자리 간편 비밀번호를 입력해 주세요.</label>
                  </dt>
                  <dd>
                    <input
                      type="password"
                      // className="form-control input-radius"
                      className={getPwdClassName('pwd')}
                      id="password01"
                      name="password01"
                      maxLength={6}
                      onChange={(e) => setSplashUserPwd({ ...splashUserPwd, pwd: e.target.value })}
                      placeholder="비밀번호를 입력해 주세요."
                    />
                  </dd>
                </dl>
              </div>
              {type !== 'login' ? (
                <div className="form-group">
                  <dl>
                    <dt>
                      <label className="required">비밀번호를 한번 더 입력해 주세요.</label>
                    </dt>
                    <dd>
                      <input
                        type="password"
                        // className="form-control input-radius"
                        className={getPwdClassName('repwd')}
                        id="password01"
                        name="password01"
                        maxLength={6}
                        onChange={(e) => setSplashUserPwd({ ...splashUserPwd, repwd: e.target.value })}
                        placeholder="비밀번호를 입력해 주세요."
                      />
                    </dd>
                  </dl>
                </div>
              ) : (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                  <button
                    className="btn btn_type01"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      onClick('splashIndex', 'login');
                    }}
                  >
                    처음으로
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="btnBottom">
        <GetBtnBottom />
      </div>
    </>
  );
};
export default AppSplashPwd;
