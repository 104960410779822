import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PLImage from '@components/common/PLImage';
import GoodsItem from '@components/detail/goods/GoodsItem';
import GoodsTitle from '@components/detail/goods/GoodsTitle';
import AnnoList from '@pages/anno/AnnoList';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { GoodsDetailIF } from 'interface/goodsInterface';

/**
 * 상품 상세보기
 * @returns
 */
const AppGoodsDetail = () => {
  const { id } = useParams();

  const [goodsInfo, setGoodsInfo] = useState<GoodsDetailIF>({
    info: undefined,
    attachFiles: [],
    appThumbnail: [],
    appTopImages: [],
  });

  /* 'info' - 정보 ,'call'- 호가 ,'chart' - 차트 ,'anno' - 공시 */
  const [goodsMenu, setGoodsMenu] = useState<'info' | 'call' | 'chart' | 'anno'>('info');
  const goodsMenuArr = [
    { name: '정보', value: 'info' },
    { name: '호가', value: 'call' },
    { name: '차트', value: 'chart' },
    { name: '공시', value: 'anno' },
  ];

  /**
   * menu 별 노출시킬 컴포넌트 제어
   * @returns
   */
  function MenuByContent() {
    /* default - 서비스 준비중  */
    let res = (
      <div className="noticeOpen noticeOpen_product">
        <div className="noticeOpenWrap">
          <h3>해당 서비스는 현재 준비중으로 곧 오픈 예정입니다.</h3>
        </div>
      </div>
    );
    switch (goodsMenu) {
      /* 정보 */
      case 'info':
        res = goodsInfo.info ? <GoodsItem data={goodsInfo} /> : <div></div>;
        break;
      /* 공시 리스트 */
      case 'anno':
        res = <AnnoList goodsSn={goodsInfo.info ? goodsInfo.info.goodsSn : 0} />;
        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 상장 / 청약 인 상태일때만 하단부 버튼 노출 제어
   * @returns
   */
  function GoodsBottomButton() {
    let res = null;
    if (goodsMenu === 'info') {
      // console.log(goodsInfo.info?.sttusSe);

      switch (goodsInfo.info?.sttusSe) {
        case '상장':
          res = (
            <div className="btnBottom productBtn" style={{ bottom: 0 }}>
              <a onClick={() => alert('서비스 준비 중입니다.')} className="buttonType01">
                거래하기
              </a>
            </div>
          );
          break;
        case '청약':
          res = (
            <div className="btnBottom productBtn" style={{ bottom: 0 }}>
              <a onClick={() => alert('서비스 준비 중입니다.')} className="buttonType01">
                청약신청
              </a>
            </div>
          );
          break;
      }
    }

    return res;
  }

  /**
   * api 호출 , 에러처리 및 공통화 처리필요
   */
  async function fetchDetailApi() {
    try {
      const { data } = await Axios.get(`${API.goods_detail}?detailKey=${id}`);
      if (data.code === '200' && data.data) {
        setGoodsInfo(data.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchDetailApi();
    return () => {};
  }, [id]);

  return (
    <>
      <div className="topViewCont">
        <div className="img">
          <PLImage imgSrc={goodsInfo.appTopImages?.length ? goodsInfo.appTopImages[0].imageUrl : ''} alt="" />
        </div>
        {goodsInfo.info && <GoodsTitle data={goodsInfo.info} />}
      </div>
      <div className="mainTab productTab">
        <ul className="noticeTabArea">
          {goodsMenuArr.map((menu: any) => (
            <>
              <li className={menu.value === goodsMenu ? 'active' : ''} key={menu.value} style={{ flex: 1 }}>
                <button onClick={() => setGoodsMenu(menu.value)}>{menu.name}</button>
              </li>
              {/* <li className={menu.value === goodsMenu ? 'active' : ''} key={menu.value}></li> */}
            </>
          ))}
        </ul>
      </div>
      <div className="mobileSection mobileProductSection">
        <div className="sectionCon">
          {/* 'info' - 정보 ,'call'- 호가 ,'chart' - 차트 ,'anno' - 공시 */}
          <MenuByContent />
        </div>
        <GoodsBottomButton />
      </div>
    </>
  );
};

export default AppGoodsDetail;
