export const PATH = {
  splash: '/app/splash',
  guide: '/app/guide',
  npl_investment: '/app/npl_investment',
  app_main_splash: '/app/main/splash',

  main: '/app',

  app_main: '/app',

  my_invest: '/app/myinvest',

  goods: '/app/goods',
  goods_detail: '/app/goods/detail',
  anno_detail: '/app/goods/anno_detail',

  story: '/app/story',
  event: '/app/story/event',
  event_detail: '/app/story/event/detail',
  invest_guide: '/app/story/invest_guide',
  news: '/app/story/news',
  npl_guide: '/app/story/npl_guide',
  state: '/app/story/state',
  state_detail: '/app/story/state/detail',
  sto_guide: '/app/story/sto_guide',
  plutos_introduction: '/app/story/plutos_introduction',

  user_info: '/app/info',
  notice: '/app/info/notice',
  notice_detail: '/app/info/notice/detail',
  faq: '/app/info/faq',
  query: '/app/info/query',
  service_info: '/app/info/service',
  terms: '/app/info/terms',
};

/* 페이지 경로 */
export const WEB_PATH = {
  main: '/',
  anno: '/anno',
  anno_detail: '/anno/detail',
  notice: '/notice',
  notice_detail: '/notice/detail',
  news: '/news',
  news_detail: '/news/detail',
  event: '/event',
  event_detail: '/event/detail',
  goods: '/goods',
  goods_detail: '/goods/detail',
  corporation_investment: '/corporation_investment',
  sto_guide: '/sto_guide',
  invest_guide: '/invest_guide',
  faq: '/faq',
  npl_guide: '/npl_guide',
  npl_investment: '/npl_investment',
  plutos_introduction: '/plutos_introduction',
  clause: '/clause',
  privacy: '/privacy',
};

/* 페이지 제목 및 부제목  */
export const WEB_PATH_NAME: any = {
  '/': { title: '', subTitle: '' },
  '/anno': { title: '공시', subTitle: '상장된 투자상품에 대한 공시 및 기준가 정보를 안내해 드립니다.' },
  '/anno/detail': { title: '공시 상세', subTitle: '상장된 투자상품에 대한 공시 및 기준가 정보를 안내해 드립니다.' },
  '/notice': { title: '공지사항', subTitle: '플루토스에서 알려드립니다.' },
  '/notice/detail': { title: '공지사항 상세', subTitle: '플루토스에서 알려드립니다.' },
  '/news': { title: '뉴스', subTitle: '미디어에서 만나는 플루토스 최신 소식을 알려드립니다.' },
  '/news/detail': { title: '뉴스 상세', subTitle: '' },
  '/event': { title: '이벤트', subTitle: '놓칠수 없는 다양한 혜택 플루토스 이벤트를 안내해 드립니다.' },
  '/event/detail': { title: '이벤트 상세', subTitle: '놓칠수 없는 다양한 혜택 플루토스 이벤트를 안내해 드립니다.' },
  '/goods': { title: '투자상품', subTitle: 'NPL 거래를 위한 상장을 준비중인 투자 상품을 안내해 드립니다.' },
  '/goods/detail': { title: '투자상품 상세', subTitle: '' },
  '/corporation_investment': {
    title: '법인투자 안내',
    subTitle: '플루토스에서 전해 드리는 법인 투자의 혜택을 만나 보세요.',
  },
  '/sto_guide': {
    title: '토큰증권(ST)이란?',
    subTitle: '분산원장 기술을 활용해 자본시장법상 증권을 디지털화 한 것 을 의미합니다.',
  },
  '/invest_guide': { title: '투자가이드', subTitle: '투자가이드를 안내해 드립니다.' },
  '/faq': { title: 'FAQ', subTitle: '고객님들의 궁금증을 해결해 드립니다.' },
  '/npl_guide': {
    title: 'NPL투자란?',
    subTitle:
      'NPL(Non Performing Loan)은 금융기관이 빌려준 돈을 회수할 가능성이 어렵게 된 부실채권으로, 기능을 하지 못하는 여신을 의미합니다.',
  },
  '/npl_investment': { title: 'NPL 상장 신청', subTitle: 'NPL 상장 신청를 안내해 드립니다.' },
  '/plutos_introduction': { title: 'PLUTOS 소개', subTitle: 'NPL부동산 투자의 대중화' },
  '/clause': { title: '서비스이용약관', subTitle: '' },
  '/privacy': { title: '개인정보처리약관', subTitle: '' },
};
