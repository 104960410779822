import PLImage from '@components/common/PLImage';
import PageInfo from '@components/list/PageInfo';
import Pagination from '@components/list/Pagination';
import SearchForm from '@components/list/SearchForm';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getConnectStatus, getDateFormat } from '@utils/util';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const StateList = () => {
  const path = useLocation().pathname;
  const { routePath, status } = getConnectStatus(path);

  const [stateOption, setStateOption] = useState({
    searchKeyword: '',
    page: 1,
  });

  const [stateInfo, setStateInfo] = useState<{ list: Array<any>; totalCount: number }>({
    list: [],
    totalCount: 0,
  });

  function handleStateOption(name: string, value: string) {
    let copyStateOpt = { ...stateOption };
    if (name === 'searchKeyword') {
      copyStateOpt.page = 1;
    }

    setStateOption({
      ...copyStateOpt,
      [name]: value,
    });
  }

  async function fetchList() {
    try {
      const { data } = await Axios.get(API.state, { params: stateOption });

      if (data.code === '200') {
        setStateInfo({
          list: data.data,
          totalCount: data.totalCnt,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchList();
  }, [stateOption]);

  return (
    <>
      <div className="sectionCon">
        <SearchForm placeholder="제목을 검색해 주세요" onClick={handleStateOption} />
        <PageInfo totalCount={stateInfo.totalCount} />

        <div className="board_list gallery_list">
          <ul className="list_ul">
            {Boolean(stateInfo.list.length) &&
              stateInfo.list.map((info, index) => (
                <li className="list_li" key={`${info.stncnSn}_${index}`}>
                  <Link className="item" to={`${routePath.state_detail}/${info.sntncSn}`}>
                    <span className="thumb">
                      <PLImage imgSrc={status === 'web' ? info.webThumb : info.appThumb} alt={'시장현황 이미지'} />
                    </span>
                    <div className="txt">
                      <strong className="title">{info.sntncSj}</strong>
                      <div className="etc_info">
                        <span className="date">
                          <em className="sr-only">등록일</em>
                          <span className="num">{getDateFormat(info.frstCreatDt, 'YYYY-MM-DD')}</span>
                        </span>

                        <span className="hit">
                          <em className="sr-only">작성자</em>
                          <i className="ri-edit-line" aria-hidden="true"></i>
                          <span>관리자</span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Pagination page={stateOption.page} totalCount={stateInfo.totalCount} onClick={handleStateOption} pageSize={10} />
    </>
  );
};
export default StateList;
