import { WEB_PATH } from '@constants/path';
import React from 'react';
import { Link } from 'react-router-dom';
import PrepareService from './PrepareService';
import PLImage from './PLImage';
import { replacePath } from '@utils/util';
import WaypointToAnimate from './WaypointToAnimate';
import ReactPlayer from './ReactPlayer';

interface SubTitleProps {
  path: string;
}
/**
 * 법인투자안내 ,  1:1 문의 , 토큰증권(ST) , NPL 투자란 ? 플루토스 소개
 * - 구조상 <Layout /> subConView -> realContents -> 내부에도 내용이 들어가고 subConView 외부에도 내용이 들어가는 케이스가 있기때문에 추가
 * @param param0
 * @returns
 */
const WebSubTitle = ({ path }: SubTitleProps) => {
  let component = <div></div>;
  const routePath = replacePath(path);

  switch (routePath) {
    case WEB_PATH.corporation_investment:
      component = (
        <div className="sectionCon">
          <div className="cont_box_wrap">
            <h3 className="sec_tit">
              한도 제한없이 투자 가능한 법인투자! <span>법인 투자의 혜택을 만나 보세요.</span>
            </h3>

            <WaypointToAnimate id={'title_animate1'}>
              <div className="cont_box">
                <ul>
                  <li className="investIco01" id="title_animate1">
                    <dl>
                      <dt>투자 한도 무제한</dt>
                      <dd>
                        법인대상 무제한 투자 가능!
                        <p>단, 공모 청약은 단일 공모 물량의 20% 한도 청약 가능</p>
                      </dd>
                    </dl>
                  </li>
                  <li className="investIco02" id="title_animate1">
                    <dl>
                      <dt>법인 특화 서비스</dt>
                      <dd>법인의 자금 상황과 투자 조건 1:1 맞춤형 상담 제공!</dd>
                    </dl>
                  </li>
                  <li className="investIco03" id="title_animate1">
                    <dl>
                      <dt>세금 절감 효과</dt>
                      <dd>
                        법인세율 적용으로 세금 절감 가능!
                        <p>법인투자자는 원천징수 면제</p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </WaypointToAnimate>

            <div className="btnArea text-center">
              <Link to={'/'} className="btn btn-primary btn-lg" data-toggle="modal" data-target="#inquiryPop">
                1:1 문의하기 <p className="smallTxt mT5">1:1 문의는 모바일 앱을 통해 회원가입 후 진행가능합니다.</p>
              </Link>
            </div>
          </div>
        </div>
      );
      break;
    case WEB_PATH.sto_guide:
      component = (
        <div className="productCon01">
          <h4 className="subTxt01">
            블록체인 기술을 활용해 토큰 형태로 발행되는 증권으로 디지털 자산의 장점을 함께 가지며{' '}
            <span>부동산, 미술품, 저작권 등 다양한 자산에 대한 소액 투자를 가능하게 하는 기본 개념</span>
          </h4>
          <WaypointToAnimate id={'title_animate1'}>
            <ul className="serviceList" id="title_animate1">
              <li>
                <span className="icon_09">블록체인 및 스마트 계약 기술을 활용하여 탈중앙화, 분산화</span>
              </li>
              <li>
                <span className="icon_10">거래내역이 투명하며 실물 증서가 없더라도 신뢰, 안정성 보장</span>
              </li>
              <li>
                <span className="icon_11">언제든지 거래 가능하며 비용 감소</span>
              </li>
              <li>
                <span className="icon_12">주식, 채권, 수익증권, 투자계약증권 등 모든 형태의 증권 토큰화 가능</span>
              </li>
              <li>
                <span className="icon_13">
                  실물자산을 기초자산으로 발행, 해당 기초 자산에 대해 운영수익, 처분수익 등 수취 가능
                </span>
              </li>
              <li>
                <span className="icon_14">
                  금융당국의 규제하에 있으며, 증권으로써 자본시장법과 각종 법률 등 투자자의 법적 권리 보호 가능
                </span>
              </li>
            </ul>
          </WaypointToAnimate>
        </div>
      );
      break;
    case WEB_PATH.npl_guide:
      component = (
        <div className="productCon01">
          {/* <h4 className="subTxt01">
            NPL(Non Performing Loan)은 금융기관이 빌려준 돈을 회수할 가능성이 어렵게 된 부실채권으로, 기능을 하지 못하는
            여신을 의미합니다.{' '}
          </h4> */}
          {/* <div className="serviceImgWrap"> */}
          <ReactPlayer isControl={true} height={'100%'} />
          {/* </div> */}
          <div className="serviceImgWrap">
            <PLImage imgSrc={require('@assets/img_app/web/serviceImg04.png')} alt="" />
          </div>
          <WaypointToAnimate id={'title_animate1'}>
            <ul className="serviceList add-animation" id="title_animate1">
              <li>
                <span className="icon_18">
                  통상 3개월 이상 연체로 인하여 '고정' 등급 이하로 분류된 채권으로써 금융기관은 당국의 BIS 및 부실채권
                  비율을 맞추기 위하여 Loan sale 진행하며, 여기에서 NPL투자 기회가 발생함
                </span>
              </li>
              <li>
                <span className="icon_19">
                  투자자는 NPL매입 후 경,공매 절차에 따라 채권액을 배당받거나, 직접 낙찰받아 소유권 취득 후 재매각으로
                  수익 실현함
                </span>
              </li>
              <li>
                <span className="icon_20">
                  부정적인 느낌과는 달리, 시장에서 부실 자산을 순화시키는 정(+)의 효과를 발생시키며 투자수익률 또한
                  우수하여 전문투자가들의 선호 상품임
                </span>
              </li>
            </ul>
          </WaypointToAnimate>
        </div>
      );
      break;
    case WEB_PATH.plutos_introduction:
      component = (
        <div className="sectionCon">
          <div className="cont_box_wrap">
            <h3 className="sec_tit">NPL부동산 투자의 대중화</h3>

            <div className="cont_box">
              <ul>
                <li className="serviceIco01">
                  <dl>
                    <dt>Easy NPL</dt>
                    <dd>
                      높은 진입장벽으로 전문투자가들의 영역이었던 NPL을 누구나, 전문지식이 없어도, 투자금이 적어도 NPL
                      자산에 투자 기회 제공!
                    </dd>
                  </dl>
                </li>
                <li className="serviceIco02">
                  <dl>
                    <dt>Easy Investment</dt>
                    <dd>
                      복잡한 절차 없이 쇼핑하듯 원하는 상품에 투자하고, 주식처럼 편리한 거래!{' '}
                      <p>전문업체가 운영, 관리하여 수익 창출</p>
                    </dd>
                  </dl>
                </li>
                <li className="serviceIco03">
                  <dl>
                    <dt>Safe & Smart</dt>
                    <dd>
                      블록체인 분산원장 기술을 활용하여 보다 더 안전하고 투명하게 운영!{' '}
                      <p>시간과 장소에 구애 받지 않고 스마트한 투자 라이프</p>
                    </dd>
                  </dl>
                </li>
                <li className="serviceIco04">
                  <dl>
                    <dt>Good Together</dt>
                    <dd>
                      플루토스는 투자상품 조달자, 운영자, 투자자가 모두 함께 만족할 수 있는 투자구조를 통해 굳건하면서
                      지속적인 성장이 가능
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <br></br>
        </div>
      );
      break;
    default:
      break;
  }
  return component;
};

export default WebSubTitle;
