import WaypointToAnimate from '@components/common/WaypointToAnimate';
import React from 'react';

/**
 * 토큰증권(ST)이란?
 * @returns
 */
const StoGuide = () => {
  return (
    <div className="serviceWrap serviceBg">
      <div className="subConView">
        <h3 className="title_sub">
          <span>STO(Security Token Offering)</span>
        </h3>

        <div className="sectionCon">
          <WaypointToAnimate id={'animate1'}>
            <div className="serviceTableWrap add-animation" id="animate1">
              <div className="table_type2">
                <table className="">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>IPO</th>
                      <th className="pointBg">STO</th>
                      <th>ICO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>투자대상</td>
                      <td>실제 비즈니스 모델</td>
                      <td className="pointBg">실제 비즈니스 모델 또는 실물자산</td>
                      <td>블록체인 기반 프로젝트 및 아이디어</td>
                    </tr>
                    <tr>
                      <td>투자자금 형태</td>
                      <td>법정통화</td>
                      <td className="pointBg">법정통화 및 가상화폐</td>
                      <td>가상화폐</td>
                    </tr>
                    <tr>
                      <td>런칭 프로세스</td>
                      <td>IPO전 많은 의무 이행, 금융당국 승인필요</td>
                      <td className="pointBg">증권법 및 관련법 준수, 금융당국 승인필요</td>
                      <td>금융당국 승인 및 규정 부재</td>
                    </tr>
                    <tr>
                      <td>발행인</td>
                      <td>명확</td>
                      <td className="pointBg">명확</td>
                      <td>불명확</td>
                    </tr>
                    <tr>
                      <td>비용</td>
                      <td>높음</td>
                      <td className="pointBg">낮음</td>
                      <td>매우 낮음</td>
                    </tr>
                    <tr>
                      <td>법적보호</td>
                      <td>적용</td>
                      <td className="pointBg">적용</td>
                      <td>미적용</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </WaypointToAnimate>

          <WaypointToAnimate id={'animate2'}>
            <ul className="serviceList add-animation" id="animate2">
              <li>
                <span className="icon_15">
                  주식의 IPO(Initial Public Offering), 가상화폐의 ICO(Initial Coin Offering)와 상응하는 개념
                </span>
              </li>
              <li>
                <span className="icon_16">IPO보다 자금 모집이 수월한 동시에 ICO와 달리 법적 보호 가능함</span>
              </li>
              <li>
                <span className="icon_17">
                  플루토스는 기초자산인 NPL 수익증권의 확보를 위하여 STO 진행함. 투자자는 청약을 통하여 해당 STO에
                  참여할 수 있으며, 이후 증권사의 중개로써 거래 가능함
                </span>
              </li>
            </ul>
          </WaypointToAnimate>
        </div>
      </div>
    </div>
  );
};

export default StoGuide;
