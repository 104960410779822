const GuideModal = () => {
  return (
    <div id="mobileWrap" className="mobileWrap">
      <div className="startWrap">
        <h3 className="systemLogo">플루토스</h3>

        <div className="splashContent swiper-container" id="splashSwiper">
          <div className="swiper-wrapper">
            <div className="splashList swiper-slide">
              <div className="splashTitle">
                <h4>NPL투자의 대중화를 위한</h4>
                <h5>Easy NPL</h5>
              </div>
              <div className="splashImg">
                <img src="../resources/img_app/web/serviceIco01.png" />
              </div>
              <div className="splashTxt">
                높은 진입 장벽으로 인하여 전문투자가들의 영역이었던 NPL을 누구나, 전문지식이 없어도, 투자금이 많지
                않아도 NPL 자산에 투자 기회를 제공해 드립니다.
              </div>
            </div>

            <div className="splashList swiper-slide">
              <div className="splashTitle">
                <h4>쇼핑하듯 간편하고</h4>
                <h5>Easy Investment</h5>
              </div>
              <div className="splashImg">
                <img src="../resources/img_app/web/serviceIco02.png" />
              </div>
              <div className="splashTxt">
                복잡한 절차없이 쇼핑하듯 원하는 상품에 투자하고, 보유한 상품은 주식처럼 편리하게 거래할 수 있으며
                투자자의 부동산은 전문업체가 운영, 관리하여 수익을 창출합니다.
              </div>
            </div>

            <div className="splashList swiper-slide">
              <div className="splashTitle">
                <h4>안전하고 투명한</h4>
                <h5>Safe & Smart</h5>
              </div>
              <div className="splashImg">
                <img src="../resources/img_app/web/serviceIco03.png" />
              </div>
              <div className="splashTxt">
                블록체인 분산원장 기술을 활용하여 보다 더 안전하고 투명하게 운영됩니다. <br />
                투자자는 시간과 장소에 구애 받지 않고 스마트한 투자 라이프를 즐길 수 있습니다.
              </div>
            </div>

            <div className="splashList swiper-slide">
              <div className="splashTitle">
                <h4>
                  국내 유일의 NLP 토큰 증권<span>투자 플랫폼 "PLUTOS"</span>
                </h4>
                <h5>Good Together</h5>
              </div>
              <div className="splashImg">
                <img src="../resources/img_app/web/serviceIco04.png" />
              </div>
              <div className="splashTxt">
                플루토스는 특정 집단의 이익을 추구하는 것이 아니라 함께 성장하는 것을 철학으로 합니다. <br />
                투자상품 조달자, 운영자, 투자자가 모두 함께 만족할 수 있는 투자구조를 만들었기에 굳건하면서 지속적인
                성장이 가능합니다.
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>

      <div className="btnBottom">
        <a href="#" className="buttonType01">
          시작하기
        </a>
      </div>
    </div>
  );
};
export default GuideModal;
