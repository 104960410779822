import { PATH } from '@constants/path';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * 법인투자 컴포넌트 (사용 x , Subtitle 컴포넌트 내부에서 처리)
 * @returns
 */
const CorporateInvestment = () => {
  return (
    <div className="sectionCon">
      {/* <div className="cont_box_wrap">
        <h3 className="sec_tit">
          한도 제한없이 투자 가능한 법인투자! <span>법인 투자의 혜택을 만나 보세요.</span>
        </h3>

        <div className="cont_box">
          <ul>
            <li className="investIco01">
              <dl>
                <dt>투자 한도 무제한</dt>
                <dd>
                  법인대상 무제한 투자 가능!
                  <p>단, 공모 청약은 단일 공모 물량의 20% 한도 청약 가능</p>
                </dd>
              </dl>
            </li>
            <li className="investIco02">
              <dl>
                <dt>법인 특화 서비스</dt>
                <dd>법인의 자금 상황과 투자 조건 1:1 맞춤형 상담 제공!</dd>
              </dl>
            </li>
            <li className="investIco03">
              <dl>
                <dt>세금 절감 효과</dt>
                <dd>
                  법인세율 적용으로 세금 절감 가능!
                  <p>법인투자자는 원천징수 면제</p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>

        <div className="btnArea text-center">
          <Link to={'/'} className="btn btn-primary btn-lg" data-toggle="modal" data-target="#inquiryPop">
            1:1 문의하기 <p className="smallTxt mT5">1:1 문의는 모바일 앱을 통해 회원가입 후 진행가능합니다.</p>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default CorporateInvestment;
