import React, { useEffect, useState } from 'react';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getConnectStatus, getDateFormat, getPathName } from '@utils/util';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { BoardAttachFiles } from 'interface/commonInterface';
import AttachFilesForm from '@components/detail/AttachFilesForm';
import NavigatorBtnForm from '@components/detail/NavigatorBtnForm';
import { PATH } from '@constants/path';
import GoListButton from '@components/common/GoListButton';
import BoardEditor from '@components/detail/BoardEditor';

interface AnnoItem {
  sntncSn: number;
  goodsSn: number;
  imprtncat: number;
  goodsNm: string;
  sntncSj: string;
  sntncCn: string;
  frstCreatDt: number;
  lastUpdtDt: number;
  previousSn: number;
  previousSj: string;
  nextSn: number;
  nextSj: string;
  mngrNm: string;
}

interface AnnoInfo {
  info: AnnoItem;
  attachFiles: Array<BoardAttachFiles>;
}

const AnnoDetail = () => {
  const { id } = useParams();
  const { pathname, state } = useLocation();
  const { routePath, status } = getConnectStatus(pathname);

  const navigate = useNavigate();

  const [annoInfo, setAnnoInfo] = useState<AnnoInfo>({
    info: {
      sntncSn: 0,
      goodsSn: 0,
      imprtncat: 0,
      goodsNm: '',
      sntncSj: '',
      sntncCn: '',
      frstCreatDt: 0,
      lastUpdtDt: 0,
      previousSn: 0,
      previousSj: '',
      nextSn: 0,
      nextSj: '',
      mngrNm: '',
    },
    attachFiles: [],
  });

  const listPath = status === 'web' ? routePath.anno : `${routePath.goods_detail}/${annoInfo.info.goodsSn}`;

  //웹 , 모바일 이전버튼 숨김 스타일
  const hidePrevBtnStyle: any =
    status === 'web'
      ? { visibility: !Boolean(annoInfo.info.previousSn) ? 'hidden' : 'visible' }
      : {
          display: !Boolean(annoInfo.info.previousSn) ? 'none' : 'block',
        };

  //웹 , 모바일 다음버튼 숨김 스타일
  const hideNextBtnStyle: any =
    status === 'web'
      ? { visibility: !Boolean(annoInfo.info.nextSn) ? 'hidden' : 'visible' }
      : {
          display: !Boolean(annoInfo.info.nextSn) ? 'none' : 'block',
        };

  function checkDisableEvent(e: any, sn: number) {
    if (!sn) e.preventDefault();
  }

  async function fetchDetail() {
    try {
      let addUrl = '';
      if (state) {
        addUrl = `&searchType1=${state.sttus}`;
      }
      const { data } = await Axios.get(`${API.anno_detail}?detailKey=${id}${addUrl}`);

      if (data.code === '200' && data.data.info) {
        setAnnoInfo({
          info: data.data.info,
          attachFiles: data.data.attachFiles,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchDetail();
  }, [id]);

  return (
    <>
      <div className="sectionCon">
        <div className="noticeDetailView">
          <div className="noticeTitle">
            <h2 className="detailTitle01">{annoInfo.info.goodsNm}</h2>
            <h3 className="detailTitle">{annoInfo.info.sntncSj}</h3>
            <div className="actions">
              <span>
                작성자:<em>관리자</em>
              </span>
              <span>
                등록일:<em>{getDateFormat(annoInfo.info.frstCreatDt, 'YYYY.MM.DD')}</em>
              </span>
            </div>
          </div>

          <BoardEditor editorData={annoInfo.info.sntncCn}>
            {annoInfo.attachFiles.length ? (
              <AttachFilesForm formTitle="첨부파일" fileList={annoInfo.attachFiles} />
            ) : (
              <></>
            )}
          </BoardEditor>

          <GoListButton path={listPath} />

          <div className="nextPrevious">
            <ul>
              <li style={hidePrevBtnStyle}>
                <Link
                  to={`${routePath.anno_detail}/${annoInfo.info.previousSn}`}
                  onClick={(e) => checkDisableEvent(e, annoInfo.info.previousSn)}
                  state={state}
                >
                  <dl>
                    <dt>Previous</dt>
                    <dd>{annoInfo.info.previousSj} </dd>
                  </dl>
                </Link>
              </li>
              <li style={hideNextBtnStyle}>
                <Link
                  to={`${routePath.anno_detail}/${annoInfo.info.nextSn}`}
                  onClick={(e) => checkDisableEvent(e, annoInfo.info.nextSn)}
                  state={state}
                >
                  <dl>
                    <dt>Next</dt>
                    <dd>{annoInfo.info.nextSj} </dd>
                  </dl>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnoDetail;
