import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { menuList } from '@utils/util';
import { PATH, WEB_PATH } from '@constants/path';
import WebLowSizeHeader from './WebLowSizeHeader';

/**
 * 웹사이즈 헤더
 * @returns
 */
const WebHeader = () => {
  const path = useLocation().pathname;
  const headerContainerRef = useRef<any>(null);

  const [openMenu, setOpenMenu] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState('inactive');
  const [currentScrollY, setCurrentScrollY] = useState(0);
  /**
   * 헤더 - mouseEnter 이벤트
   * @param activeMenu
   */
  function onMouseEnterHeader(activeMenu: string) {
    setOpenMenu(activeMenu);
    setOpenSubMenu('active');
  }

  /**
   * 헤더 - mouseLeave 이벤트
   * @param event
   */
  function onMouseLeaveHeader(event: any) {
    setOpenMenu('');
    setOpenSubMenu('inactive');
  }

  /**
   * 헤더 타이틀 active 상태처리 함수
   * 헤더 활성화 - active , inactive
   * 헤더 비활성화 - ''
   * @param menuTitle
   * @returns
   */
  function menuTitleClassName(menuTitle: string) {
    let res = 'inactive';
    if (openMenu === '') {
      res = '';
    } else if (openMenu === menuTitle) {
      res = 'active';
    }

    return res;
  }

  /**
   * 모바일 헤더 open 함수
   * @param event
   */
  function handleMobileNavBtn(event: any) {
    let mobileNavClass = 'mobile-nav-opened';
    let mobileFixedNavClass = 'header-fixed-at-mobile-nav-opened';
    /* 현재 스크롤 위치 */
    let windowScrollY = window.scrollY;

    /* 헤더를 감싸는 컨테이너의 height*/
    let continerHeight = headerContainerRef.current ? headerContainerRef.current.clientHeight : 0;

    /* 현재 스크롤 위치와 컨테이너의 height 비교 */
    let containCurrentScroll = windowScrollY > window.innerHeight - continerHeight;

    /*  */
    document.body.classList.add(mobileNavClass);

    /* body 에 현재 스크롤 위치 저장 */
    document.body.style.top = `-${windowScrollY}px`;

    if (containCurrentScroll) {
      document.body.classList.remove(mobileFixedNavClass);
    } else {
      document.body.classList.add(mobileFixedNavClass);
    }

    /* 현재 스크롤위치를 state로 관리 -> 헤더를 닫을때 scroll 이 최상단으로 올라가는것을 방지 */
    setCurrentScrollY(windowScrollY);
  }

  // useEffect(() => {
  //   console.log(openMenu);
  // }, [openMenu]);

  return (
    <>
      <div id="header" className="opaque fixed sub_header" onMouseLeave={onMouseLeaveHeader}>
        <div className="header-anim">
          <div className="header-container" ref={headerContainerRef}>
            <div className="header-inner">
              <h1 className="brand">
                <a href="/" />
              </h1>
              <div className="utils">
                <ul className="menu">
                  <li className="toggle-menu">
                    <button
                      className="btn-toggle-menu"
                      style={{ backgroundColor: 'transparent' }}
                      onClick={handleMobileNavBtn}
                    >
                      <span></span>
                    </button>
                  </li>
                </ul>
              </div>
              <ul className="topRightBtn">
                {/* <li>
                <a href="#" className="appDownBtn" onClick={() => alert('앱 다운로드 링크')}>
                  <span>앱 다운로드</span>
                </a>
              </li> */}
                <li>
                  <button
                    className="sitemapTopBtn"
                    onClick={() =>
                      document.getElementsByClassName('allMenu')[0].setAttribute('class', 'allMenu openMenu')
                    }
                  >
                    사이트맵
                  </button>
                </li>
              </ul>
            </div>
            <nav id="nav">
              <ul className="gnb">
                {menuList.map((menu, index) => (
                  <li
                    key={`${menu.main.title}_${index}`}
                    className={menuTitleClassName(menu.main.title)}
                    onMouseEnter={() => onMouseEnterHeader(menu.main.title)}
                  >
                    <a href="#" onClick={(event) => event.preventDefault()}>
                      <span>{menu.main.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>

        {/* <div className={openSubMenu === 'active' ? 'gnb-sub-menus active' : 'gnb-sub-menus'} id="gnbSubMenus"> */}
        <div className={openSubMenu === 'active' ? 'gnb-sub-menus active' : 'gnb-sub-menus'}>
          <div className="backdrop" onMouseEnter={onMouseLeaveHeader}></div>
          <div className="gnb-sub-menus-container">
            <div className="menus">
              <ul>
                {menuList.map((menu, index) => (
                  <li key={`${menu.main.title}_${index}`} onMouseEnter={() => setOpenMenu(menu.main.title)}>
                    <ul className="sub-menu">
                      {menu.sub.map((item, index) => (
                        <li key={`${item.path}_${index}`}>
                          {path === WEB_PATH.main ? (
                            <a href={item.path}>
                              <span>{item.title}</span>
                            </a>
                          ) : (
                            <Link to={item.path} state={item?.state ? item.state : undefined}>
                              <span>{item.title}</span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <WebLowSizeHeader currentScrollY={currentScrollY} />
    </>
  );
};

export default WebHeader;
