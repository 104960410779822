import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '@constants/path';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getDateFormat, hyphenToComma, regPrice } from '@utils/util';
import MainContentSwiper from '@components/swiper/MainContentSwiper';
import { SwiperSlide } from 'swiper/react';
import ReactPlayer from '@components/common/ReactPlayer';
interface MainInfo {
  event: Array<any>;
  goods: Array<any>;
  news: Array<any>;
  notice: Array<any>;
}

/**
 * 메인화면 컴포넌트
 * @returns
 */
const AppMain = () => {
  const [mainInfo, setMainInfo] = useState<MainInfo>({
    event: [],
    goods: [],
    news: [],
    notice: [],
  });

  async function fetchMain() {
    try {
      const { data } = await Axios.get(API.app_main);
      if (data.code === '200') {
        setMainInfo({
          event: data.data.event,
          goods: data.data.goods,
          news: data.data.news,
          notice: data.data.notice,
        });
      }

      // console.log(data.data.goods);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchMain();

    /* loadExternalJS 에서 main.js 로드시 적용안되는 이슈때문에 effect 에 추가 */
    const scriptJquery = document.createElement('script');
    scriptJquery.src = '/js/mobile/main.js';
    scriptJquery.async = true;
    document.body.appendChild(scriptJquery);
    return () => {
      document.body.removeChild(scriptJquery);
    };
  }, []);

  return (
    <div className="mobileMainSection">
      <div className="section sec_one">
        <div className="sec_one_left swiper-container" id="visualSwiper">
          <div className="txt_wrap">
            <p className="slogan motion">
              국내 유일의 <span>NPL 전문 토큰 증권</span> <span>투자 플랫폼 PLUTOS</span>
            </p>
            <div className="txt_img motion">
              누구나, 소액으로 국내 다양한 부동산에 투자하고 <span>나만의 투자 포트폴리오를 만들어 보세요!</span>
            </div>
          </div>
          <div className="visual_wrap swiper-wrapper">
            <div className="visual swiper-slide">
              <div
                className="bg_area"
                style={{
                  // backgroundImage: `url(${require('@assets/img_app/web/mainVisual01.png')})`,
                  background: `url(${require('@assets/img_app/web/mainVisual01.png')}) 0 center`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              {/* <div className="m_bgArea" style="background:url('../resources/img_app/web/mainVisual01_m.png') center center no-repeat;"
              backgroundSize : 'cover'></div> */}
              <div
                className="m_bgArea"
                style={{
                  // backgroundImage: `url(${require('@assets/img_app/web/mainVisual01_m.png')})`,
                  background: `url(${require('@assets/img_app/web/mainVisual01_m.png')}) center center`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            </div>
            <div className="visual swiper-slide">
              {/* <div className="bg_area" style="background:url('../resources/img_app/web/mainVisual02.png') 0 center no-repeat;"
              backgroundSize : 'cover'></div> */}
              <div
                className="bg_area"
                style={{
                  // backgroundImage: `url(${require('@assets/img_app/web/mainVisual02.png')})`,
                  background: `url(${require('@assets/img_app/web/mainVisual02.png')}) 0 center`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              {/* <div className="m_bgArea" style="background:url('../resources/img_app/web/mainVisual02_m.png') center center no-repeat;"
              backgroundSize : 'cover'></div> */}
              <div
                className="m_bgArea"
                style={{
                  // backgroundImage: `url(${require('@assets/img_app/web/mainVisual02_m.png')})`,
                  background: `url(${require('@assets/img_app/web/mainVisual02_m.png')}) center center`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="mainSectionWrap">
          <div className="sectioninner">
            <h3 className="mainSectionTitle">What is PLUTOS?</h3>
            <div className="sectionDetail">
              <h4 className="mainSectionTitle02">NPL부동산 투자의 대중화</h4>
              <div className="sectionSlide01">
                <MainContentSwiper swiperClassName="pointContent">
                  <SwiperSlide className="pointList swiper-slide">
                    <div className="pointImg">
                      <img src={require('@assets/img_app/web/serviceIco01.png')} />
                    </div>
                    <div className="pointTitle">
                      <h5>Easy NPL</h5>
                    </div>
                    <div className="pointTxt">
                      높은 진입 장벽으로 인하여 전문투자가들의 영역이었던 NPL을 누구나, 전문지식이 없어도, 투자금이 많지
                      않아도 NPL 자산에 투자 기회를 제공해 드립니다.
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="pointList swiper-slide">
                    <div className="pointImg">
                      <img src={require('@assets/img_app/web/serviceIco02.png')} />
                    </div>
                    <div className="pointTitle">
                      <h5>Easy Investment</h5>
                    </div>
                    <div className="pointTxt">
                      복잡한 절차없이 쇼핑하듯 원하는 상품에 투자하고, 보유한 상품은 주식처럼 편리하게 거래할 수 있으며
                      투자자의 부동산은 전문업체가 운영, 관리하여 수익을 창출합니다.
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="pointList swiper-slide">
                    <div className="pointImg">
                      <img src={require('@assets/img_app/web/serviceIco03.png')} />
                    </div>
                    <div className="pointTitle">
                      <h5>Safe & Smart</h5>
                    </div>
                    <div className="pointTxt">
                      블록체인 분산원장 기술을 활용하여 보다 더 안전하고 투명하게 운영됩니다. <br />
                      투자자는 시간과 장소에 구애 받지 않고 스마트한 투자 라이프를 즐길 수 있습니다.
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="pointList swiper-slide">
                    <div className="pointImg">
                      <img src={require('@assets/img_app/web/serviceIco04.png')} />
                    </div>
                    <div className="pointTitle">
                      <h5>Good Together</h5>
                    </div>
                    <div className="pointTxt">
                      플루토스는 특정 집단의 이익을 추구하는 것이 아니라 함께 성장하는 것을 철학으로 합니다. <br />
                      투자상품 조달자, 운영자, 투자자가 모두 함께 만족할 수 있는 투자구조를 만들었기에 굳건하면서
                      지속적인 성장이 가능합니다.
                    </div>
                  </SwiperSlide>
                </MainContentSwiper>
              </div>
            </div>
          </div>

          <div className="sectioninner">
            <h3 className="mainSectionTitle">플루토스 소개 영상</h3>
            <div className="sectionDetail">
              <ReactPlayer isControl={true} height={'100%'} />
            </div>
          </div>

          <div className="sectioninner">
            <h3 className="mainSectionTitle">이벤트</h3>
            <div className="sectionDetail">
              <div className="sectionSlide02">
                {Boolean(mainInfo.event.length) && (
                  <MainContentSwiper swiperClassName="eventContent">
                    {mainInfo.event.map((info) => (
                      <SwiperSlide className="eventList swiper-slide" key={info.sntncSn}>
                        <Link to={`${PATH.event_detail}/${info.sntncSn}`} state={{ isMain: true }}>
                          <dl>
                            <dt>{info.sntncSj}</dt>
                            <dd>
                              {getDateFormat(info.eventBgnde, 'YYYY-MM-DD')} ~{' '}
                              {getDateFormat(info.eventEndde, 'YYYY-MM-DD')}
                            </dd>
                          </dl>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </MainContentSwiper>
                )}
              </div>
            </div>
          </div>

          <div className="sectioninner">
            <h3 className="mainSectionTitle">투자상품</h3>
            <div className="sectionDetail">
              <div className="sectionSlide03">
                <div className="mainProductWrap">
                  {Boolean(mainInfo.goods.length) && (
                    <MainContentSwiper swiperClassName="mainProductContent">
                      {mainInfo.goods.map((info) => (
                        <SwiperSlide className="mainProductList swiper-slide" key={info.sntncSn}>
                          <div
                            className="bg"
                            style={{
                              // backgroundImage: `url(${info.appThumb})`,
                              background: `url(${info.appThumb}) center center`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                            }}
                          ></div>
                          <div className="mainProductDetail">
                            <p className="mainProductTxt01">{info.sttusSe}</p>
                            <p className="mainProductTxt02">{info.goodsNm}</p>
                            <p className="mainProductTxt03">{info.goodsAdres}</p>

                            <div className="mainProductInfo">
                              <ul>
                                <li>
                                  <dl>
                                    <dt>상장일</dt>
                                    <dd>{info.lston}</dd>
                                  </dl>
                                </li>
                                <li>
                                  <dl>
                                    <dt>공모가액</dt>
                                    <dd>{regPrice(info.pssrpc)}</dd>
                                  </dl>
                                </li>
                                <li>
                                  <dl>
                                    <dt>총 DAS 수량</dt>
                                    <dd>{regPrice(info.pssrpqy)} DAS</dd>
                                  </dl>
                                </li>
                              </ul>
                            </div>

                            <Link
                              to={`${PATH.goods_detail}/${info.goodsSn}`}
                              state={{ isMain: true }}
                              className="mainProductBtn"
                            >
                              <span>자세히 보기</span>
                            </Link>
                          </div>
                        </SwiperSlide>
                      ))}
                    </MainContentSwiper>
                  )}
                  {/* <div className="mainProductContent swiper-container" id="mainProductSwiper">
                    <div className="swiper-wrapper">
                      {Boolean(mainInfo.goods.length) &&
                        mainInfo.goods.map((info) => (
                          <div className="mainProductList swiper-slide" key={info.goodsSn}>
                            <div
                              className="bg"
                              style={{
                                // backgroundImage: `url(${info.appThumb})`,
                                background: `url(${info.appThumb}) center center`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            ></div>
                            <div className="mainProductDetail">
                              <p className="mainProductTxt01">{info.sttusSe}</p>
                              <p className="mainProductTxt02">{info.goodsNm}</p>
                              <p className="mainProductTxt03">{info.goodsAdres}</p>

                              <div className="mainProductInfo">
                                <ul>
                                  <li>
                                    <dl>
                                      <dt>상장일</dt>
                                      <dd>{info.lston}</dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>공모가액</dt>
                                      <dd>{regPrice(info.pssrpc)}</dd>
                                    </dl>
                                  </li>
                                  <li>
                                    <dl>
                                      <dt>총 DAS 수량</dt>
                                      <dd>{regPrice(info.pssrpqy)} DAS</dd>
                                    </dl>
                                  </li>
                                </ul>
                              </div>

                              <Link to={`${PATH.goods_detail}/${info.goodsSn}`} className="mainProductBtn">
                                <span>자세히 보기</span>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="swiper-pagination"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="sectioninner">
            <h3 className="mainSectionTitle">스토리</h3>
            <div className="sectionDetail mainStory">
              <div className="sectionSlide04">
                <div className="storyCon">
                  <h4>
                    <Link to={PATH.invest_guide}>투자가이드</Link>
                  </h4>
                </div>
                <div className="storyCon">
                  <h4>
                    <Link to={PATH.npl_guide}>NPL 소개</Link>
                  </h4>
                </div>
                <div className="storyCon">
                  <h4>
                    <Link to={PATH.state} state={{ isMain: true }}>
                      부동산 시황
                    </Link>
                  </h4>
                </div>
                <div className="storyCon">
                  <h4>
                    <Link to={PATH.sto_guide}>토큰증권(ST)이란?</Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="sectioninner">
            <h3 className="mainSectionTitle">공지사항</h3>
            <div className="sectionDetail mainNotice">
              <div className="sectionSlide05">
                <div className="noticeContent swiper-container" id="noticeSwiper">
                  <div className="swiper-wrapper">
                    <div className="pressList swiper-slide">
                      <a href="#">
                        <div className="pressListCon">
                          <h3>막 오른 토큰 증권(STO), 조각투자 플랫폼 파이 키운다</h3>
                          <p className="pressTxt">
                            스탤리온은 종마라는 뜻을 가졌다. 2021년 8월에 설립된 스탤리온파트너스(대표 최영재)는 좋은
                            기업에 씨를 뿌리고 기업과 같이 성장하며 고객의 자산을 잘 운용하겠다는 의미를 담아 사명을
                            지었다. 사역은 계속 채용 중이다. BNK투자증권과 함께 바이셀스탠다드의 브릿지 라운드 투자에
                            참여한 스탤리온파트너스의 김윤석 수석 심사역을 만나 포부를 들어봤다.
                          </p>
                          <p className="pressDate">2023-05-10</p>
                        </div>
                      </a>
                    </div>
                    <div className="pressList swiper-slide">
                      <a href="#">
                        <div className="pressListCon">
                          <h3>막 오른 토큰 증권(STO), 조각투자 플랫폼 파이 키운다</h3>
                          <p className="pressTxt">
                            스탤리온은 종마라는 뜻을 가졌다. 2021년 8월에 설립된 스탤리온파트너스(대표 최영재)는 좋은
                            기업에 씨를 뿌리고 기업과 같이 성장하며 고객의 자산을 잘 운용하겠다는 의미를 담아 사명을
                            지었다. 사역은 계속 채용 중이다. BNK투자증권과 함께 바이셀스탠다드의 브릿지 라운드 투자에
                            참여한 스탤리온파트너스의 김윤석 수석 심사역을 만나 포부를 들어봤다.
                          </p>
                          <p className="pressDate">2023-05-10</p>
                        </div>
                      </a>
                    </div>
                    <div className="pressList swiper-slide">
                      <a href="#">
                        <div className="pressListCon">
                          <h3>막 오른 토큰 증권(STO), 조각투자 플랫폼 파이 키운다</h3>
                          <p className="pressTxt">
                            스탤리온은 종마라는 뜻을 가졌다. 2021년 8월에 설립된 스탤리온파트너스(대표 최영재)는 좋은
                            기업에 씨를 뿌리고 기업과 같이 성장하며 고객의 자산을 잘 운용하겠다는 의미를 담아 사명을
                            지었다. 사역은 계속 채용 중이다. BNK투자증권과 함께 바이셀스탠다드의 브릿지 라운드 투자에
                            참여한 스탤리온파트너스의 김윤석 수석 심사역을 만나 포부를 들어봤다.
                          </p>
                          <p className="pressDate">2023-05-10</p>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="sectioninner">
            <h3 className="mainSectionTitle">공지사항</h3>
            <div className="sectionDetail mainMedia">
              <div className="sectionSlide06">
                <div className="mainMediaBoard">
                  <ul>
                    {Boolean(mainInfo.notice.length) &&
                      mainInfo.notice.map((info) => (
                        <li key={info.sntncSn}>
                          <Link to={`${PATH.notice_detail}/${info.sntncSn}`} state={{ isMain: true }}>
                            <dl>
                              <dt>{getDateFormat(info.frstCreatDt, 'YYYY-MM-DD')}</dt>
                              <dd>{info.sntncSj}</dd>
                            </dl>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sectioninner">
            <h3 className="mainSectionTitle">미디어가 전하는 PLUTOS</h3>
            <div className="sectionDetail mainMedia">
              <div className="sectionSlide06">
                <div className="mainMediaBoard">
                  <ul>
                    {Boolean(mainInfo.news.length) &&
                      mainInfo.news.map((info) => (
                        <li key={info.sntncSn}>
                          <Link to={info.linkUrl} target="_blank">
                            <dl>
                              <dt>{getDateFormat(info.frstCreatDt, 'YYYY-MM-DD')}</dt>
                              <dd>{info.sntncSj}</dd>
                            </dl>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppMain;
