import GoListButton from '@components/common/GoListButton';
import AttachFilesForm from '@components/detail/AttachFilesForm';
import BoardEditor from '@components/detail/BoardEditor';
import BoardTitle from '@components/detail/BoardTitle';
import { API } from '@service/api';
import { Axios } from '@service/axios';
import { getConnectStatus } from '@utils/util';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface detailData {
  info: EventInfo;
}

interface EventInfo {
  sntncSn: string;
  eventBgnde: string;
  eventEndde: string;
  mngrId: string;
  frstCreatDt: string;
  rdcnt: number;
  sntncSj: string;
  sntncCn: string;
}

const EventDetail = () => {
  const { id } = useParams();
  const path = useLocation().pathname;
  const { routePath } = getConnectStatus(path);

  const [eventData, setEventData] = useState<detailData>({
    info: {
      sntncSn: '',
      eventBgnde: '',
      eventEndde: '',
      mngrId: '',
      frstCreatDt: '',
      rdcnt: 0,
      sntncSj: '',
      sntncCn: '',
    },
  });
  async function fetchDetail() {
    try {
      const { data } = await Axios.get(API.event_detail, { params: { detailKey: id } });

      if (data.code === '200') {
        setEventData({
          info: data.data.info,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchDetail();
  }, []);

  return (
    <>
      <div className="sectionCon" style={{ paddingBottom: 20 }}>
        <div className="noticeDetailView">
          <BoardTitle
            title={eventData.info.sntncSj}
            beginDate={eventData.info.eventBgnde}
            endDate={eventData.info.eventEndde}
            rdcnt={eventData.info.rdcnt}
          />

          <BoardEditor editorData={eventData.info.sntncCn} />

          <GoListButton path={routePath.event} />
        </div>
      </div>
    </>
  );
};
export default EventDetail;
